import { FC } from "react";

import "./error-message.scss";

const ErrorMessage: FC<{ error: string }> = ({ error }) => (
  <p className="error-message">
    <span className="error-icon">&#10006;</span>
    {error}
  </p>
);

export default ErrorMessage;
