import { useEffect, useMemo } from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import Select from "react-select";
import { Tags } from "common/types";
import {
  selectIsLoading,
  selectTagsList,
  getTagsList,
  attachPage,
} from "pages/admin/tagsPage/slice";

type Falsey = false | undefined;

type PropTypes<Multi extends Falsey | true> = {
  onChange: (
    tag: Multi extends Falsey ? Tags.TagSimple | undefined : Tags.TagSimple[]
  ) => void;
  isMulti: Multi;
  value?: number | Tags.TagSimple[];
};

const PAGE_SIZE = 10;

const SelectTag = <T extends Falsey | true>({
  onChange,
  value,
  isMulti = false,
}: PropTypes<T>) => {
  const tagsList = useAdminSelector(selectTagsList);
  const isLoading = useAdminSelector(selectIsLoading);
  const dispatch = useAdminDispatch();

  useEffect(() => {
    dispatch(getTagsList({ page: 0, size: 100 }));
  }, [dispatch]);

  const options = useMemo(
    () => tagsList.map(({ id, name }) => ({ value: id, label: name })),
    [tagsList]
  );

  const selectorValue = useMemo(
    () =>
      Array.isArray(value)
        ? value.map(({ id }) => options.find((opt) => opt.value === id))
        : options.find((opt) => opt.value === value),
    [options, value]
  );

  return (
    <Select
      className="select"
      classNamePrefix="custom"
      options={options}
      value={selectorValue}
      isLoading={isLoading}
      isMulti={isMulti}
      onMenuScrollToBottom={() => dispatch(attachPage({ size: PAGE_SIZE }))}
      onChange={(data) => {
        if (data) {
          let result: Tags.TagSimple | Tags.TagSimple[];
          if (Array.isArray(data)) {
            result = data
              .map(({ value }) => {
                const tag = tagsList.find(({ id }) => id === value)!;
                return {
                  id: tag.id,
                  name: tag.name,
                  tagType: tag.tagType.name,
                };
              })
              .filter(Boolean);
          } else {
            const tag = tagsList.find(
              ({ id }) => id === (data as typeof options[number]).value
            )!;
            result = { id: tag.id, name: tag.name, tagType: tag.tagType.name };
          }
          onChange(result as any);
        }
      }}
    />
  );
};

export default SelectTag;
