import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { Tags } from "common/types";
import { selectToken, setUserData } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";

type TagTypeState = SliceBase<Tags.TagType>;

const initialState: TagTypeState = {
  data: [],
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getTagTypeList = createAsyncThunk(
  "tagTypes/getList",
  async (_, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    return api.getTagTypes({ token });
  }
);

const tagTypeSlice = createSlice({
  name: "tagTypesReducer",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getTagTypeList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTagTypeList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
      })
      .addCase(getTagTypeList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      });
  },
});

const sliceSelector = (state: AdminRootState) => state.tagTypes;

export const selectTagTypeList = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export default tagTypeSlice.reducer;
