import Field from "./Field";
import ImageField from "./ImageField";
import Connector from "./Connector";
import Editor from "./Editor";
import Select from "./Select";
import * as hooks from "./hooks";
import * as slice from "./slice";
import * as helpers from "./helpers";
export * as Types from "./types";

const AssetsField = {
  Field,
  ImageField,
  Connector,
  Editor,
  Select,
  hooks,
  slice,
  helpers,
};

export default AssetsField;
