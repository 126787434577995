import {
  TypedUseSelectorHook,
  createSelectorHook,
  createDispatchHook,
} from "react-redux";
import type { AdminRootState, AdminDispatch } from ".";
import { AdminContext } from ".";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAdminDispatch: () => AdminDispatch =
  createDispatchHook<AdminRootState>(AdminContext);
export const useAdminSelector: TypedUseSelectorHook<AdminRootState> =
  createSelectorHook(AdminContext);
