import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import type { AdminRootState } from "admin-store";
import type { UserState } from "pages/web-client/AccountModals/loginSlice";

const initialState: Omit<UserState, "isLoading" | "currentStatus"> = {
  token: "",
  user: null,
};

const adminUserSlice = createSlice({
  name: "adminUserReducer",
  initialState,
  reducers: {
    setUserData(state, { payload }: PayloadAction<UserState>) {
      state.token = payload.token;
      state.user = payload.user;
    },
  },
});

export const { setUserData } = adminUserSlice.actions;

const sliceSelector = (state: AdminRootState) => state.adminUser;

export const selectToken = createSelector(sliceSelector, ({ token }) => token);

export const selectIsLoggedIn = createSelector(
  selectToken,
  (token) => token !== ""
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export const selectCurrentUser = createSelector(
  sliceSelector,
  ({ user }) => user
);

export default adminUserSlice.reducer;
