import Field from "./Field";
import Connector from "./Connector";
import * as slice from "./slice";
export * as Types from "./types";

const RemoteTranscodeVideosField = {
  Field,
  Connector,
  slice,
};

export default RemoteTranscodeVideosField;
