import { ReactNode, useEffect } from "react";
import { useParams } from "react-router";
import { Route, useRouteMatch } from "react-router-dom";
import cn from "classnames";

import { OnToggleType } from "./types";

type childrenType = (id: string) => ReactNode;

type EditorSwitcherPropTypes = {
  children: childrenType;
};

const EditorSwitcher = ({ children }: EditorSwitcherPropTypes) => {
  const { resourceId } = useParams<{ resourceId: string }>();

  return <>{children(resourceId)}</>;
};

type ConnectorPropTypes = {
  className?: string;
  path: string;
  onToggle: OnToggleType;
  children: childrenType;
};

const Connector = ({
  className,
  path,
  onToggle,
  children,
}: ConnectorPropTypes) => {
  const match = useRouteMatch();

  useEffect(() => {
    onToggle(match.isExact);
  }, [match.isExact, onToggle]);

  return (
    <Route path={match.url + `/${path}/:resourceId?`}>
      <div className={cn("resource-field", "resource-field-page", className)}>
        <EditorSwitcher children={children} />
      </div>
    </Route>
  );
};

export default Connector;
