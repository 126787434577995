import Field from "./Field";
import Connector from "./Connector";
import * as slice from "./slice";
export * as Types from "./types";

const RemoteTranscodeAudiosField = {
  Field,
  Connector,
  slice,
};

export default RemoteTranscodeAudiosField;
