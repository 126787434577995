import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Assets, Settings } from "common/types";
import * as api from "common/api";
import { AdminRootState } from "admin-store";
import { SettingsData } from "./SettingsPage";
import { selectCurrentOrgUnit, setCurrentOrgUnit } from "./orgUnitSlice";
import { selectToken, setUserData } from "../login/slice";

type SettingsState = {
  error?: string;
  isLoading: boolean;
  settings: null | Settings.Settings;
  imgAssets: Array<Assets.Asset>;
  imgAssetsPageCount: number;
};

const initialState: SettingsState = {
  settings: null,
  imgAssets: [],
  error: undefined,
  isLoading: false,
  imgAssetsPageCount: 0,
};

export const fetchSettings = createAsyncThunk(
  "settingsSlice/fetch",
  async (_, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    )!;
    return api.getOrgUnitSettings({ token, orgUnitId });
  }
);

export const saveSettings = createAsyncThunk(
  "settingsSlice/save",
  async (settingsData: SettingsData, thunkApi) => {
    let settings: Settings.SettingsRequest = {
      orgUnitId: settingsData.orgUnitId,
      accentColor: settingsData.accentColor,
      backgroundColor: settingsData.backgroundColor,
      mainFont: settingsData.mainFont,
      mainFontColor: settingsData.mainFontColor,
      secondaryFontColor: settingsData.secondaryFontColor,
      secondaryAccentColor: settingsData.secondaryAccentColor,
      secondaryBackgroundColor: settingsData.secondaryBackgroundColor,
      accentBackgroundColor: settingsData.accentBackgroundColor,
      companyName: settingsData.companyName,
      termsUrl: settingsData.termsUrl,
      privacyUrl: settingsData.privacyUrl,
      facebookUrl: settingsData.facebookUrl,
      twitterUrl: settingsData.twitterUrl,
      youtubeUrl: settingsData.youtubeUrl,
      rokuUrl: settingsData.rokuUrl,
      appleUrl: settingsData.appleUrl,
      googleUrl: settingsData.googleUrl,
      amazonUrl: settingsData.amazonUrl,
    };
    if (typeof settingsData.backgroundMobileImageAsset === "number") {
      settings.backgroundMobileImageAssetId =
        settingsData.backgroundMobileImageAsset;
    }
    if (typeof settingsData.backgroundTabletImageAsset === "number") {
      settings.backgroundTabletImageAssetId =
        settingsData.backgroundTabletImageAsset;
    }
    if (typeof settingsData.backgroundTvImageAsset === "number") {
      settings.backgroundTvImageAssetId = settingsData.backgroundTvImageAsset;
    }
    if (typeof settingsData.logoAsset === "number") {
      settings.logoAssetId = settingsData.logoAsset;
    }
    if (typeof settingsData.splashImageAsset === "number") {
      settings.splashImageAssetId = settingsData.splashImageAsset;
    }
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    return api.putOrgUnitSettings({ token, settings });
  }
);

export const fetchImgAssets = createAsyncThunk(
  "settingsSlice/getAssets",
  async (_, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    )!;
    return api.getAssets({
      token,
      orgUnitId,
      contentType: "image",
    });
  }
);

export const attachImgAssetsPage = createAsyncThunk(
  "settingsSlice/attachImgAssetsPage",
  async (_, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(getState() as AdminRootState)!;
    const imgAssetsList = selectImgAssets(getState() as AdminRootState);
    const pageCount = selectImgAssetsPageCount(getState() as AdminRootState);
    const size = 20;
    const page = Math.ceil(imgAssetsList.length / size);
    if (page < pageCount) {
      return api.getAssets({
        token,
        orgUnitId,
        page,
        size,
        contentType: "image",
      });
    }
    return { data: [], pageCount };
  }
);

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.settings = payload;
      })
      .addCase(fetchSettings.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(fetchImgAssets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchImgAssets.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.imgAssets = payload.data;
        state.imgAssetsPageCount = payload.pageCount;
      })
      .addCase(fetchImgAssets.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(attachImgAssetsPage.fulfilled, (state, { payload }) => {
        state.imgAssetsPageCount = payload.pageCount;
        payload.data.forEach((item) => {
          state.imgAssets.push(item);
        });
      })
      .addCase(saveSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.settings = payload;
      })
      .addCase(saveSettings.rejected, (state, { error }) => {
        state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(setCurrentOrgUnit, (state) => {
        Object.assign(state, initialState);
      });
  },
});

const sliceSelector = (state: AdminRootState) => state.settings;

export const selectSettings = createSelector(
  sliceSelector,
  (state) => state.settings
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);

export const selectImgAssets = createSelector(
  sliceSelector,
  (state) => state.imgAssets
);

export const selectImgAssetsPageCount = createSelector(
  sliceSelector,
  (state) => state.imgAssetsPageCount
);

export const selectError = createSelector(
  sliceSelector,
  (state) => state.error
);

export default settingsSlice.reducer;
