import { Settings } from "common/types";
import { FC, useMemo, Fragment } from "react";
import { withTheme } from "styled-components";

import "./footer.scss";

const Icons = {
  roku: (
    <svg width="1000px" height="311.2px" viewBox="0 0 1000 311.2">
      <path
        className="fill"
        d="M157.8,102.5c0-28.4-22.8-51.2-50.8-51.2H81.9v102h25.2C135,153.3,157.8,130.4,157.8,102.5
       M272.3,305.9h-92.4l-73.3-101.8H81.9v101.6H0.5V0.5h116.6c67.2,0,122.1,45.7,122.1,102c0,34.3-21,65-53.1,83.7L272.3,305.9
       M364.2,120.8c-21.5,0-39.8,29.2-39.8,65.4s18.3,65.4,39.8,65.4c22,0,40.3-29.3,40.3-65.4S386.2,120.8,364.2,120.8 M489.5,186.2
      c0,69.1-56.2,124.5-125.3,124.5c-69.1,0-124.9-55.3-124.9-124.5c0-69.1,55.8-124.4,124.9-124.4C433.3,61.8,489.5,117.1,489.5,186.2
       M680.5,66.8l-93.8,93.8V66.5h-81.4v239.1h81.4v-97l97.9,97h102.5L662.7,181.2l103.1-103v141.9c0,47.1,28.3,90.6,99.7,90.6
      c33.9,0,65-19.2,80.1-36.6l36.6,31.6h17.4V66.8h-81.4v124.4v30.2c-9.2,16-21.9,26.1-41.7,26.1c-20,0-29.2-11.9-29.2-49.9v-10
      l0-120.8H680.5z"
      />
    </svg>
  ),
  appleTv: (
    <svg width="7050.8px" height="3346.4px" viewBox="0 0 7050.8 3346.4">
      <path
        className="fill"
        d="M4079.8,272.2V819h686v196h-686v1614.4c0,165.1,26.6,291.4,80,379.1c53.3,87.7,145.2,131.5,276,131.5
    c61.9,0,115.2-3.4,159.9-10.3c44.7-6.8,82.5-15.5,113.5-25.8l25.8,185.7c-82.5,37.8-192.6,56.8-330.1,56.8
    c-113.5,0-207.2-19-281.1-56.8c-74-37.8-132.4-90.2-175.4-157.3s-73.1-147.8-90.3-242.4c-17.3-94.5-25.8-196.8-25.8-306.9V1015
    h-407.5V819h407.5V365L4079.8,272.2L4079.8,272.2z M5163,818.9L5730.4,2356c48.1,120.4,91.1,233.9,128.9,340.4
    c37.8,106.6,73.9,211.5,108.3,314.6h10.3c34.3-99.7,71.3-203.8,110.9-312.1c39.5-108.3,83.4-222.6,131.5-343l572.5-1537.1h257.9
    l-985.2,2475.8H5854L4905.1,818.9H5163z"
      />

      <path
        className="fill"
        d="M1505.2,291.2C1725.1,1.4,2030.8,0,2030.8,0s45.5,272.5-173,534.9c-233.2,280.3-498.3,234.4-498.3,234.4
      S1309.7,548.9,1505.2,291.2L1505.2,291.2z M1387.4,960.2c113.1,0,323-155.5,596.3-155.5c470.4,0,655.4,334.7,655.4,334.7
      s-361.9,185-361.9,634c0,506.5,450.8,681,450.8,681s-315.1,887-740.8,887c-195.5,0-347.5-131.8-553.5-131.8
      c-209.9,0-418.3,136.7-553.9,136.7C491.1,3346.4,0,2504.9,0,1828.6C0,1163.1,415.7,814,805.5,814
      C1059,814,1255.7,960.2,1387.4,960.2z"
      />
    </svg>
  ),
  androidTv: (
    <svg width="495px" height="84.5px" viewBox="0 0 495 84.5">
      <g
        id="g33668"
        transform="matrix(8.2780765,0,0,-8.2780765,-4845.7031,2087.972)"
      >
        <g id="g33674" transform="translate(623.8155,205.7036)">
          <path
            id="path33676"
            className="fill"
            d="M-34.6,38.1c-1.1,0-2,0.9-2,2.1c0,1.1,0.9,2.1,2,2.1c0.7,0,1.5-0.3,1.8-1c0,0,0.1-0.1,0.1-0.2
        v-1.9c0,0,0-0.1,0-0.1C-33.1,38.5-33.8,38.1-34.6,38.1 M-30.9,36.5v7.4h-1.8v-0.6c-0.5,0.5-1.2,0.8-2.1,0.8
        c-2.1,0-3.7-1.7-3.7-3.8c0-2.1,1.6-3.8,3.7-3.8c0.9,0,1.5,0.3,2.1,0.8v-0.6C-32.7,36.5-30.9,36.5-30.9,36.5z M-23,36.5v4
        c0,2-1.5,3.5-3.5,3.5c-2,0-3.5-1.6-3.5-3.5v-4h1.8v4c0,1,0.7,1.7,1.7,1.7c1,0,1.7-0.7,1.7-1.7v-4C-24.8,36.5-23,36.5-23,36.5z
         M-18.4,38.1c-1.1,0-2,0.9-2,2.1c0,1.1,0.9,2.1,2,2.1c0.7,0,1.5-0.4,1.8-1c0,0,0.1-0.1,0.1-0.2v-1.9c0,0,0-0.1,0-0.1
        C-16.9,38.5-17.6,38.1-18.4,38.1 M-14.7,36.5v9.6h-1.8v-2.8c-0.5,0.5-1.2,0.8-2.1,0.8c-2.1,0-3.7-1.7-3.7-3.8
        c0-2.1,1.6-3.8,3.7-3.8c0.9,0,1.5,0.3,2.1,0.8v-0.6C-16.5,36.5-14.7,36.5-14.7,36.5z M-11.9,36.5v3.9c0,1.2,0.6,1.8,1.8,1.8V44
        c-2.3,0-3.6-1.2-3.6-3.6v-3.9C-13.7,36.5-11.9,36.5-11.9,36.5z M-6.1,38.2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2
        C-4.1,39.1-5,38.2-6.1,38.2 M-6.1,36.3c2.1,0,3.9,1.7,3.9,3.8c0,2.1-1.7,3.8-3.9,3.8c-2.1,0-3.8-1.7-3.8-3.8
        C-10,38-8.3,36.3-6.1,36.3 M-0.5,44.4c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1
        C-1.6,44.9-1.1,44.4-0.5,44.4 M0.4,43.8h-1.8v-7.4h1.8V43.8z M5.1,38.1c-1.1,0-2,0.9-2,2.1c0,1.1,0.9,2.1,2,2.1
        c0.7,0,1.5-0.4,1.8-1c0,0,0.1-0.1,0.1-0.2v-1.9c0,0,0-0.1,0-0.1C6.5,38.5,5.8,38.1,5.1,38.1 M8.8,36.5v9.6H6.9v-2.8
        C6.4,43.7,5.7,44,4.9,44c-2.1,0-3.7-1.7-3.7-3.8c0-2.1,1.6-3.9,3.7-3.9c0.9,0,1.5,0.3,2.1,0.8v-0.6H8.8L8.8,36.5z"
          />
        </g>
        <g id="g33678" transform="translate(676.7471,204.1033)">
          <path
            id="path33680"
            className="fill"
            d="M-34.6,38.1l3,7.4h-1.5l-2.4-6.4h0.5l-2.5,6.4H-39l3-7.4H-34.6z M-39.4,38l0,1.3h-0.2
        c-1.1,0-1.8,0.6-1.8,1.7v3.2h1.9v1.2h-1.9v2h-1.3V41c0-1.9,1.2-3,3.2-3C-39.5,38-39.4,38-39.4,38z"
          />
        </g>
      </g>
    </svg>
  ),
  amazonFireTv: (
    <svg width="98.9px" height="53.2px" viewBox="0 0 98.9 53.2">
      <path
        className="fill"
        d="M80,43.6c-8.5,6.3-20.8,9.6-31.4,9.6c-14.8,0-28.2-5.5-38.3-14.6c-0.8-0.7-0.1-1.7,0.9-1.1
    c10.9,6.3,24.4,10.2,38.3,10.2c9.4,0,19.7-2,29.2-6C80.2,41,81.4,42.5,80,43.6L80,43.6z"
      />
      <path
        className="fill"
        d="M83.5,39.5c-1.1-1.4-7.2-0.7-9.9-0.3c-0.8,0.1-1-0.6-0.2-1.1c4.9-3.4,12.8-2.4,13.7-1.3
    c0.9,1.2-0.2,9.1-4.8,12.9c-0.7,0.6-1.4,0.3-1.1-0.5C82.3,46.7,84.6,40.9,83.5,39.5L83.5,39.5z"
      />
      <path
        className="fill"
        d="M49,12.5c4.8,0,4.7,4.2,4.6,6H43.5C43.7,15.4,45.2,12.5,49,12.5L49,12.5z M56.6,21.3c0.6,0,0.7-2,0.7-2.8
    c0.2-6.1-3-9-8.2-9c-2.9,0-5.5,1-7.2,3c-1.5,1.8-2.4,4.4-2.4,7.9c0,3.4,0.8,6,2.3,7.8c1.5,1.8,3.9,3.1,7.9,3.1
    c1.5,0,4.5-0.3,6.4-1.3c0.7-0.3,0.7-0.6,0.7-1.2v-1.1c0-0.6-0.3-0.9-0.9-0.6c-2.2,0.7-4.3,1-5.6,1c-2.3,0-4.2-0.7-5.3-1.9
    c-1.1-1.4-1.4-3-1.5-4.9C47.8,21.3,52.2,21.3,56.6,21.3L56.6,21.3z"
      />
      <path
        className="fill"
        d="M7.9,10V6c0-3.2,3.2-2.8,5.3-2.5c0.9,0.2,1.3,0,1.3-0.7V1.4c0-0.7-0.3-0.9-1.2-1.1c-5-0.9-9.4,0.1-9.4,6.5v3.4
    l-3,0.3C0.2,10.5,0,10.7,0,11.4v1.1c0,0.6,0.2,0.8,0.8,0.8h3v16.7c0,0.6,0.2,0.8,0.8,0.8h2.5c0.6,0,0.8-0.2,0.8-0.8V13.2h5.5
    c0.6,0,0.8-0.2,0.8-0.8v-1.6c0-0.6-0.3-0.8-0.8-0.8H7.9L7.9,10z"
      />
      <path
        className="fill"
        d="M20.9,10h-2.4c-0.6,0-0.8,0.2-0.8,0.8v19c0,0.6,0.2,0.8,0.8,0.8h2.4c0.6,0,0.8-0.2,0.8-0.8v-19
    C21.7,10.4,21.4,10,20.9,10L20.9,10z M19.6,1.5c-1.4,0-2.5,1-2.5,2.5c0,1.5,1,2.5,2.5,2.5c1.5,0,2.5-1,2.5-2.5
    C22.2,2.3,21.2,1.5,19.6,1.5L19.6,1.5z"
      />
      <path
        className="fill"
        d="M90.3,30.7h-2.6c-0.6,0-0.9-0.2-1.1-0.8L79.1,11C78.9,10.4,79,10,79.7,10h2.8c0.6,0,0.8,0.3,1,0.8L89,26.9
    l5.6-16.1c0.3-0.6,0.5-0.8,1.1-0.8h2.5c0.7,0,0.8,0.4,0.6,0.9l-7.5,18.9C91.1,30.3,90.9,30.7,90.3,30.7L90.3,30.7z"
      />
      <path
        className="fill"
        d="M66.7,25.4c0,4.8,3.3,5.6,5.7,5.6c1.3,0,2.3-0.1,3.4-0.3C77,30.3,77,30,77,29.5c0-0.5,0-1,0-1.5
    c0-0.6-0.3-0.8-0.8-0.6c-1,0.2-1.6,0.3-2.6,0.3c-2.7-0.2-2.9-1-2.9-3.6V13.2H76c0.6,0,0.8-0.2,0.8-0.8v-1.6c0-0.6-0.3-0.8-0.8-0.8
    h-5.3V5c0-0.5-0.2-0.8-0.8-0.8h-1.6c-0.6,0-0.9,0.2-1,0.9l-0.5,5l-3,0.4c-0.6,0.1-0.9,0.4-0.9,1v0.9c0,0.6,0.3,0.8,0.8,0.8h3
    C66.7,17.3,66.7,21.3,66.7,25.4L66.7,25.4z"
      />
      <path
        className="fill"
        d="M30.8,13l-0.3-2.3c-0.1-0.6-0.5-0.6-0.9-0.6l-1.8,0c-0.6,0-0.8,0.2-0.8,0.8v19c0,0.6,0.2,0.8,0.8,0.8h2.4
    c0.6,0,0.8-0.2,0.8-0.8V15.5c1.6-1.2,2.9-1.9,6-1.9c0.8,0,1.8,0.3,1.8-0.5v-2.4c0-0.5-0.3-0.8-1.3-0.8C35,9.8,33.3,10.5,30.8,13
    L30.8,13z"
      />
    </svg>
  ),
  apple: (
    <svg width="46.594px" height="57.26px" viewBox="0 0 46.594 57.26">
      <path
        className="fill"
        d="M935.278,2970.29a12.886,12.886,0,0,0,2.96-9.18,12.793,12.793,0,0,0-8.456,4.38c-1.832,2.12-3.454,5.57-3.031,8.82,3.243,0.28,6.483-1.62,8.527-4.02m2.923,4.66c-4.71-.28-8.713,2.67-10.963,2.67s-5.7-2.53-9.42-2.46a13.884,13.884,0,0,0-11.808,7.17c-5.061,8.73-1.335,21.68,3.586,28.79,2.39,3.52,5.27,7.39,9.065,7.25,3.586-.14,4.991-2.32,9.35-2.32s5.622,2.32,9.417,2.25c3.936-.07,6.4-3.52,8.787-7.04a31.323,31.323,0,0,0,3.935-8.09,12.786,12.786,0,0,1-7.66-11.62c-0.072-7.24,5.9-10.69,6.185-10.91a13.454,13.454,0,0,0-10.474-5.69"
        transform="translate(-903.562 -2961.12)"
      />
    </svg>
  ),
  google: (
    <svg width="553px" height="553px" viewBox="0 0 553 553">
      <path
        className="fill"
        d="M76.8,179.1c-9.5,0-17.6,3.3-24.3,10s-10,14.6-10,23.9V356c0,9.5,3.3,17.6,10,24.3c6.6,6.6,14.7,10,24.3,10
    c9.5,0,17.6-3.3,24.1-10c6.5-6.6,9.8-14.7,9.8-24.3V213c0-9.3-3.3-17.3-10-23.9S86.1,179.1,76.8,179.1z"
      />
      <path
        className="fill"
        d="M352,50.8l23.6-43.5c1.5-2.9,1-5.1-1.7-6.6c-2.9-1.3-5.1-0.7-6.6,2l-23.9,43.9c-21.1-9.3-43.3-14-66.8-14
    c-23.5,0-45.8,4.7-66.8,14L185.8,2.7c-1.6-2.7-3.8-3.3-6.6-2c-2.7,1.6-3.2,3.8-1.7,6.6l23.6,43.5c-23.9,12.2-43,29.2-57.2,51
    c-14.2,21.8-21.3,45.7-21.3,71.6h307.4c0-25.9-7.1-49.8-21.3-71.6C394.6,80,375.7,63,352,50.8z M215.5,114.2
    c-2.6,2.6-5.6,3.8-9.1,3.8c-3.6,0-6.5-1.3-9-3.8c-2.4-2.5-3.7-5.6-3.7-9.1c0-3.5,1.2-6.6,3.7-9.1c2.4-2.5,5.4-3.8,9-3.8
    s6.6,1.3,9.1,3.8c2.5,2.6,3.8,5.6,3.8,9.1C219.4,108.6,218.1,111.6,215.5,114.2z M355.6,114.2c-2.4,2.6-5.4,3.8-9,3.8
    c-3.6,0-6.6-1.3-9.1-3.8c-2.6-2.5-3.8-5.6-3.8-9.1c0-3.5,1.3-6.6,3.8-9.1c2.5-2.5,5.6-3.8,9.1-3.8c3.5,0,6.5,1.3,9,3.8
    c2.4,2.6,3.7,5.6,3.7,9.1C359.3,108.6,358.1,111.6,355.6,114.2z"
      />
      <path
        className="fill"
        d="M124,406.8c0,10.2,3.5,18.8,10.6,25.9c7.1,7.1,15.7,10.6,25.9,10.6h24.6l0.3,75.5c0,9.5,3.3,17.6,10,24.3
    s14.6,10,23.9,10c9.5,0,17.6-3.3,24.3-10s10-14.7,10-24.3v-75.4h45.9v75.4c0,9.5,3.3,17.6,10,24.3s14.7,10,24.3,10
    c9.5,0,17.6-3.3,24.3-10s10-14.7,10-24.3v-75.4h24.9c10,0,18.5-3.5,25.6-10.6c7.1-7.1,10.6-15.7,10.6-25.9V185.4H124V406.8z"
      />
      <path
        className="fill"
        d="M476.3,179.1c-9.3,0-17.3,3.3-23.9,9.8c-6.6,6.5-10,14.6-10,24.1V356c0,9.5,3.3,17.6,10,24.3s14.6,10,23.9,10
    c9.5,0,17.6-3.3,24.3-10s10-14.7,10-24.3V213c0-9.5-3.3-17.6-10-24.1C493.9,182.4,485.8,179.1,476.3,179.1z"
      />
    </svg>
  ),
  facebook: (
    <svg
      className="fb-logo"
      width="56.7px"
      height="56.7px"
      viewBox="0 0 56.7 56.7"
    >
      <path
        className="fill"
        d="M28.3,0.5C12.9,0.5,0.4,13,0.4,28.4c0,13.2,9.2,24.2,21.5,27.1c0-3.3,0-14,0-23.1h-4.8v-8.5h4.8v-5.5
          c0-4,1.9-10.1,10.1-10.1l7.4,0v8.3c0,0-4.5,0-5.4,0c-0.9,0-2.1,0.4-2.1,2.3v5h7.6l-0.9,8.5h-6.8c0,9.6,0,21.2,0,23.7
          c13.7-1.8,24.3-13.5,24.3-27.7C56.2,13,43.7,0.5,28.3,0.5z"
      />
    </svg>
  ),
  twitter: (
    <svg className="tw-logo" width="32px" height="32px" viewBox="0 0 32 32">
      <path
        className="fill"
        d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3
          c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5
          c-1.1,0-2.1-0.3-3-0.8v0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1c0.8,2.6,3.3,4.5,6.1,4.6
          c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c3,1.8,6.5,2.9,10.2,2.9c12.1,0,18.7-10,18.7-18.7c0-0.3,0-0.6,0-0.8
          C30,8.5,31.1,7.4,32,6.1z"
      />
    </svg>
  ),
  youtube: (
    <svg
      className="youtube-logo"
      width="49.91px"
      height="34.93px"
      viewBox="0 0 49.91 34.93"
    >
      <path
        className="fill"
        d="M1810.55,3096.5a7.124,7.124,0,0,0-5-2.1c-6.99-.51-17.46-0.51-17.46-0.51h-0.03s-10.47,0-17.46.51a7.109,7.109,0,0,0-5,2.1c-1.5,1.51-1.99,4.94-1.99,4.94a74.654,74.654,0,0,0-.5,8.04v3.77a74.409,74.409,0,0,0,.5,8.04s0.49,3.43,1.99,4.94c1.9,1.98,4.39,1.92,5.5,2.12,4,0.39,16.97.5,16.97,0.5s10.49-.01,17.48-0.51a7.193,7.193,0,0,0,5-2.11c1.5-1.51,1.99-4.94,1.99-4.94a76.154,76.154,0,0,0,.49-8.04v-3.77a76.154,76.154,0,0,0-.49-8.04S1812.05,3098.01,1810.55,3096.5Zm-27.63,21.32v-13.96l13.49,7Z"
        transform="translate(-1763.12 -3093.91)"
      />
    </svg>
  ),
};

const Footer: FC<{ theme: Settings.Settings }> = ({ theme }) => {
  const {
    companyName,
    termsUrl,
    privacyUrl,
    facebookUrl,
    twitterUrl,
    youtubeUrl,
    rokuUrl,
    appleUrl,
    googleUrl,
    amazonUrl,
  } = theme;

  const devicesConfig = useMemo(
    () => [
      {
        url: rokuUrl,
        className: "roku-logo",
        icon: Icons.roku,
      },
      {
        url: appleUrl,
        className: "apple-tv-logo",
        icon: Icons.appleTv,
      },
      {
        url: googleUrl,
        className: "android-tv-logo",
        icon: Icons.androidTv,
      },
      {
        url: amazonUrl,
        className: "amazon-fire-tv-logo",
        icon: Icons.amazonFireTv,
      },
      {
        url: appleUrl,
        className: "apple-logo",
        icon: Icons.apple,
      },
      {
        url: googleUrl,
        className: "android-logo",
        icon: Icons.google,
      },
    ],
    [rokuUrl, appleUrl, googleUrl, amazonUrl]
  );

  const legalLinksConfig = useMemo(
    () => [
      { url: termsUrl, title: "Terms of Use" },
      { url: privacyUrl, title: "Privacy Policy" },
    ],
    [termsUrl, privacyUrl]
  );

  const socialLinksConfig = useMemo(
    () => [
      { url: facebookUrl, icon: Icons.facebook },
      { url: twitterUrl, icon: Icons.twitter },
      { url: youtubeUrl, icon: Icons.youtube },
    ],
    [facebookUrl, twitterUrl, youtubeUrl]
  );

  const devicesRow = useMemo(() => {
    return devicesConfig.some((c) => c.url) ? (
      <>
        <h3>Available on</h3>
        <div className="apps-logos">
          {devicesConfig
            .filter((c) => c.url)
            .map(({ url, className, icon }, idx) => {
              return (
                <a
                  key={`${url}-${idx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                  className={className}
                >
                  {icon}
                </a>
              );
            })}
        </div>
      </>
    ) : null;
  }, [devicesConfig]);

  const legalLinks = useMemo(() => {
    return (
      legalLinksConfig
        .filter((c) => c.url)
        .map(({ url, title }, idx) => {
          return (
            <Fragment key={`${url}-${idx}`}>
              {" | "}
              <a target="_blank" rel="noopener noreferrer" href={url}>
                {title}
              </a>
            </Fragment>
          );
        }) || null
    );
  }, [legalLinksConfig]);

  const copyRow = useMemo(() => {
    return (
      <small>
        {`© ${new Date().getFullYear()}${
          companyName ? `, ${companyName}` : ""
        }`}
        {legalLinks}
      </small>
    );
  }, [companyName, legalLinks]);

  const socialRow = useMemo(() => {
    return socialLinksConfig.some((c) => c.url) ? (
      <div className="social-logos">
        {socialLinksConfig
          .filter((c) => c.url)
          .map(({ url, icon }, idx) => {
            return (
              <a
                key={`${url}-${idx}`}
                target="_blank"
                rel="noopener noreferrer"
                href={url}
              >
                {icon}
              </a>
            );
          })}
      </div>
    ) : null;
  }, [socialLinksConfig]);

  return (
    <footer className="footer">
      {devicesRow}
      <div className="footer-wrapper">
        {copyRow}
        {socialRow}
      </div>
    </footer>
  );
};

export default withTheme(Footer);
