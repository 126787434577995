import { logout } from "pages/web-client/AccountModals/loginSlice";
import { Link } from "react-router-dom";
import { FC, useCallback, useEffect } from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";

import "./main-header.scss";
import SelectOrgUnit from "components/select-org-unit/SelectOrgUnit";
import {
  selectCurrentOrgUnit,
  setCurrentOrgUnit,
} from "pages/admin/settings/orgUnitSlice";
import { OrgUnitSimple } from "common/types/orgUnit";
import { useTheme } from "styled-components";
import { Settings } from "common/types";
import { useClientDispatch } from "webclient-store/hooks";
import { selectCurrentUser } from "pages/admin/login/slice";

const MainHeader: FC = () => {
  const theme = useTheme() as Settings.Settings;
  const dispatch = useAdminDispatch();
  const clientDispatch = useClientDispatch();
  const onLogout = useCallback(() => {
    clientDispatch(logout());
  }, [clientDispatch]);

  const currentUser = useAdminSelector(selectCurrentUser);
  const orgUnitId = useAdminSelector(selectCurrentOrgUnit);
  const onOrgUnitChange = useCallback(
    (orgUnit?: OrgUnitSimple) => {
      if (orgUnit) dispatch(setCurrentOrgUnit(orgUnit.id));
    },
    [dispatch]
  );
  useEffect(() => {
    if (currentUser && orgUnitId === -1) {
      dispatch(setCurrentOrgUnit(currentUser.orgUnit.id));
    }
  }, [orgUnitId, currentUser, dispatch]);

  return currentUser ? (
    <div className="main-header">
      <Link to="/" className="logo">
        <img src={theme.logoAsset} alt="logo" />
      </Link>
      <div className="select-wrapper">
        <SelectOrgUnit
          value={orgUnitId}
          onChange={onOrgUnitChange}
          className="top-header-org-unit-selector"
        />
      </div>
      <div className="account-wrapper">
        <span className="logout" onClick={onLogout}>
          Logout
        </span>
        <span className="account">
          {currentUser.email.slice(0, 2).toUpperCase()}
        </span>
      </div>
    </div>
  ) : null;
};
export default MainHeader;
