export const styles = {
  table: {
    style: {
      width: "100%",
    },
  },
  tableWrapper: {
    style: {
      display: "block",
      width: "100%",
      "@media (max-width: 600px)": {
        display: "table",
      },
    },
  },
  headRow: {
    style: {
      minHeight: "6rem",
      transition: "all 0.3s",
      outlineColor: "#f7faff",
      paddingLeft: "2rem",
      borderColor: "#e2e9f6",
      color: "#252c37",
      fontSize: "1.5rem",
      "&:hover": {
        outlineColor: "#f7faff",
      },
    },
  },
  rows: {
    style: {
      minHeight: "6rem",
      transition: "all 0.3s",
      outlineColor: "#f7faff",
      paddingLeft: "2rem",
      "&:not(:last-of-type)": {
        borderColor: "#e2e9f6",
        color: "#252c37",
        fontSize: "1.5rem",
      },
    },
    highlightOnHoverStyle: {
      outlineColor: "#f7faff",
      backgroundColor: "#f7faff",
    },
  },
  cells: {
    style: {
      fontSize: "1.4rem",
    },
  },
  expanderRow: {
    style: {
      backgroundColor: "#f0f3f7",
    },
  },
  expanderButton: {
    style: {
      borderRadius: "100%",
      height: "80%",
      width: "80%",
      "z-index": "10",
      "&:hover:not(:disabled)": {
        backgroundColor: "#ffffff",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "#f4f7fb",
      },
    },
  },
  pagination: {
    style: {
      borderColor: "#e2e9f6",
      color: "#252c37",
      fontSize: "1.5rem",
    },
    pageButtonsStyle: {
      "&:hover:not(:disabled)": {
        backgroundColor: "#f4f7fb",
      },
    },
  },
};

export default styles;
