import cn from "classnames";

import { ResourceField, ResourceFieldTypes } from "components";
import CONSTANTS from "./constants";
import { IsItemDisabledType, OnSubmitType } from "./types";
import Select from "./Select";
import Editor from "./Editor";

type PropTypes = {
  className?: string;
  agentId: string;
  path?: string;
  labels?: ResourceFieldTypes.LabelsType;
  allowNew?: boolean;
  onToggle: ResourceFieldTypes.OnToggleType;
  onSubmit: OnSubmitType;
  isItemDisabled?: IsItemDisabledType;
  multiple?: boolean;
};

const Connector = ({
  className,
  agentId,
  path = CONSTANTS.path,
  labels,
  allowNew,
  multiple,
  onToggle,
  onSubmit,
  isItemDisabled,
}: PropTypes) => {
  return (
    <ResourceField.Connector
      className={cn(CONSTANTS.className, className)}
      path={path}
      onToggle={onToggle}
    >
      {(id) => {
        return id && id === "new" && allowNew ? (
          <Editor id={id} agentId={agentId} />
        ) : (
          <Select
            onSubmit={onSubmit}
            isItemDisabled={isItemDisabled}
            labels={labels}
            allowNew={allowNew}
            multiple={multiple}
          />
        );
      }}
    </ResourceField.Connector>
  );
};

export default Connector;
