import { Settings } from "common/types";
import { createGlobalStyle } from "styled-components";
import { darken, rgba } from "polished";

const GlobalStyle = createGlobalStyle`
body {
	background-color: ${({ theme }: { theme: Settings.Settings }) =>
    theme.backgroundColor};
	color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
	font-family: ${({ theme }: { theme: Settings.Settings }) => theme.mainFont};

	.btn-primary {
		background-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentColor};
	  color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.secondaryAccentColor};

	  &:hover,
	  &:focus {
	    background-color: ${({ theme }: { theme: Settings.Settings }) =>
        darken(0.05, theme.accentColor!)};
	    color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryAccentColor};
	  }

		&.active:hover {
			background-color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.accentColor};
		}
	}

	.modal-footer .btn-wrapper.active .btn-primary {
		background-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentColor};
	  color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.secondaryAccentColor};

	  &:hover,
	  &:focus {
	    background-color: ${({ theme }: { theme: Settings.Settings }) =>
        darken(0.05, theme.accentColor!)};
	    color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryAccentColor};
	  }

		&.active:hover {
			background-color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.accentColor};
		}
	}

	.link {
		color: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};

	  &:hover,
	  &:focus {
	    color: ${({ theme }: { theme: Settings.Settings }) =>
        darken(0.05, theme.secondaryFontColor!)};

			svg path {
				fill: ${({ theme }: { theme: Settings.Settings }) =>
          darken(0.05, theme.secondaryFontColor!)};
				stroke: ${({ theme }: { theme: Settings.Settings }) =>
          darken(0.05, theme.secondaryFontColor!)};
			}
	  }

		svg path {
			fill: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
			stroke: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
		}
	}

	.login-page .subtitle {
		color: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};
	}

	input,
	textarea {
	  &:focus {
	    border-color: ${({ theme }: { theme: Settings.Settings }) =>
        rgba(theme.secondaryFontColor!, 0.6)};
			box-shadow: 0 0 0 1px ${({ theme }: { theme: Settings.Settings }) =>
        rgba(theme.secondaryFontColor!, 0.6)};
	  }
	}

	.select .custom__control--is-focused {
    border-color: ${({ theme }: { theme: Settings.Settings }) =>
      rgba(theme.secondaryFontColor!, 0.6)};
    box-shadow: 0 0 0 1px ${({ theme }: { theme: Settings.Settings }) =>
      rgba(theme.secondaryFontColor!, 0.6)};

    &:hover {
      border-color: ${({ theme }: { theme: Settings.Settings }) =>
        rgba(theme.secondaryFontColor!, 0.6)};
    }
  }

	.switcher input:checked + label {
		background: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
		border-color: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
	}

	.assets-wrapper li.selected .asset-block .img-block,
	.resource-items-wrapper li:not(.disabled).selected .asset-block .img-block {
		border-color: ${({ theme }: { theme: Settings.Settings }) =>
      rgba(theme.accentColor!, 0.6)};
	}

	.resource-items-wrapper li:not(.disabled).selected:hover .asset-block .img-block,
	.resource-items-wrapper li:not(.disabled).selected:hover .resource-table-wrapper {
		border-color: ${({ theme }: { theme: Settings.Settings }) =>
      rgba(theme.accentColor!, 1)};
	}

	.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
		background-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentColor};
	  color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.secondaryAccentColor};
	}

	.rdtPicker td.rdtToday:before{
	  border-bottom-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentColor};
	}

	.preview-modal .preview-wrapper:before {
		background-color: ${({ theme }: { theme: Settings.Settings }) =>
      darken(0.05, theme.backgroundColor!)}
	}

	.client-page {
		&:before {
			background-image: url('${({ theme }: { theme: Settings.Settings }) =>
        theme.backgroundTvImageAsset}');
		}

		.header {
			background-color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.backgroundColor};
			background-image: url('${({ theme }: { theme: Settings.Settings }) =>
        theme.backgroundTvImageAsset}');

			.main-nav-icon  {
				&:before,
	      &:after,
	      div {
					background-color: ${({ theme }: { theme: Settings.Settings }) =>
            theme.secondaryFontColor};
				}
			}

			.nav a {
		    color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.secondaryFontColor};

					&.active,
				  &:hover {
				    color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
				  }
		  }

			.sub-nav .nav button {
		    color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.secondaryFontColor};

				&:first-child {
					border-color: ${({ theme }: { theme: Settings.Settings }) =>
            rgba(theme.secondaryFontColor!, 0.3)};
				}

				&.active,
				&:hover {
					color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
				}
		  }

			.search-block {
				svg .stroke {
					stroke: ${({ theme }: { theme: Settings.Settings }) =>
            theme.secondaryFontColor};
				}

				&.active {
					svg .stroke {
						stroke: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
					}
				}
			}

			.mobile-menu {
				background-color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.accentBackgroundColor};
				a,
				button {
		    color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.secondaryFontColor};

					&.active,
				  &:hover {
				    color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
				  }
				}
		  }
		}

		.video-wrapper {
				background-color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.backgroundColor};

				&:before {
					background-image: url('${({ theme }: { theme: Settings.Settings }) =>
            theme.backgroundTvImageAsset}');
				}

				.description, .preview-block {
					background-color: ${({ theme }: { theme: Settings.Settings }) =>
            theme.accentBackgroundColor};
				}
		}

		.live-tag {
			background-color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.accentColor};
		  color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryAccentColor};
		}

		.react-multi-carousel-list {
			&:after {
				background: linear-gradient(
		      90deg,
		      ${({ theme }: { theme: Settings.Settings }) =>
            rgba(theme.backgroundColor!, 0)} 0% ,
		      ${({ theme }: { theme: Settings.Settings }) => theme.backgroundColor} 40%
		    );
			}
		}

		.slider-item .channel-name,
		.subtitle,
		.next {
			color: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};
		}

		.next h4 {
			color: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};
		}

		.slider-item {
			.img-wrapper {
				background-color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.accentBackgroundColor};

				img:after {
					background-color: ${({ theme }: { theme: Settings.Settings }) =>
            theme.accentBackgroundColor};
				}
			}
		}

		.login-modal .login-wrapper{
			&:before {
				background-color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.backgroundColor};
				background-image: url('${({ theme }: { theme: Settings.Settings }) =>
          theme.backgroundTvImageAsset}');
			}

			.close-btn {
				background-color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.accentBackgroundColor};
			}
		}

		.footer {
			background-color: ${({ theme }: { theme: Settings.Settings }) =>
        darken(0.05, theme.backgroundColor!)};

				a {
			    color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};

					&:hover {
							color: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
					}
			  }

			  small {
			    color: ${({ theme }: { theme: Settings.Settings }) =>
            theme.secondaryFontColor};
			  }

				.apps-logos a {
					svg .fill {
						fill: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
					}

					&:hover {
						svg .fill {
							fill: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
						}
					}
				}

				.social-logos a {
					svg .fill {
						fill: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};
					}

					&:hover {
						svg .fill {
							fill: ${({ theme }: { theme: Settings.Settings }) =>
                darken(0.1, theme.secondaryFontColor!)};
						}
					}
				}
			}

			.activate-wrapper,
			.error-wrapper  {
				p {
					color: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};
				}

				h1 {
					color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
				}

				.react-pin-code-input input {
					border-color: ${({ theme }: { theme: Settings.Settings }) =>
            rgba(theme.secondaryFontColor!, 0.6)} !important;
					background: ${({ theme }: { theme: Settings.Settings }) =>
            rgba(theme.secondaryFontColor!, 0.1)} !important;
					color: ${({ theme }: { theme: Settings.Settings }) =>
            theme.mainFontColor} !important;

					&:focus {
				    border-color: ${({ theme }: { theme: Settings.Settings }) =>
              rgba(theme.secondaryFontColor!, 0.6)} !important;
						box-shadow: 0 0 0 1px ${({ theme }: { theme: Settings.Settings }) =>
              rgba(theme.secondaryFontColor!, 0.6)} !important;
				  }
				}
			}

			.success-message .success-icon {
				background: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};

				.stroke {
					stroke: ${({ theme }: { theme: Settings.Settings }) =>
            theme.secondaryAccentColor};
				}
			}

			.search-page {
				.input-wrapper {
					background-color: ${({ theme }: { theme: Settings.Settings }) =>
            theme.backgroundColor};

					&:before {
						background-image: url('${({ theme }: { theme: Settings.Settings }) =>
              theme.backgroundTvImageAsset}');
					}
				}

				.search-input {
					border-color: ${({ theme }: { theme: Settings.Settings }) =>
            rgba(theme.secondaryFontColor!, 0.4)};
					background-color: ${({ theme }: { theme: Settings.Settings }) =>
            rgba(theme.secondaryFontColor!, 0.1)};
					color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};

					&:focus {
						border-color: ${({ theme }: { theme: Settings.Settings }) =>
              rgba(theme.secondaryFontColor!, 0.4)};
						box-shadow: 0 0 0 1px ${({ theme }: { theme: Settings.Settings }) =>
              rgba(theme.secondaryFontColor!, 0.4)};
					}
				}

				.video-list h4 {
					color: ${({ theme }: { theme: Settings.Settings }) => theme.secondaryFontColor};
				}
			}
		}
	}
`;

export default GlobalStyle;
