import { ItemComponentType } from "./types";

const Item: ItemComponentType = ({ data }) => {
  return (
    <div className="resource-table-wrapper remote-transcode-videos-field-item">
      <div className="inline-wrapper">
        <span>Id</span>
        <p>{data.id}</p>
      </div>
      <div className="block-wrapper">
        <span>Name</span>
        <p>{data.name}</p>
      </div>
      <div className="inline-wrapper">
        <span>Size</span>
        <p>{`${data.width}x${data.height}`}</p>
      </div>
      <div className="block-wrapper">
        <span>Preset</span>
        <p>{data.preset}</p>
      </div>
      <div className="block-wrapper">
        <span>Codec</span>
        <p>{data.codec}</p>
      </div>
      <div className="block-wrapper">
        <span>Extra</span>
        <p>{data.extraArguments}</p>
      </div>
    </div>
  );
};

export default Item;
