import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import * as api from "common/api";

import { Categories } from "common/types";
import { ClientRootState } from "webclient-store";
import { selectToken } from "../AccountModals/loginSlice";

type CategoryState = {
  isLoading: boolean;
  error: string | undefined;
  data: Categories.CategoryItem[];
  pageCount: number;
};

const initialState: CategoryState = {
  isLoading: true,
  error: undefined,
  data: [],
  pageCount: Number.POSITIVE_INFINITY,
};

const PAGE_SIZE = 40;

export const getCategory = createAsyncThunk(
  "webclientCategory/get",
  async (id: number, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    return api.getClientCategory({ token, id, size: PAGE_SIZE });
  }
);

export const getNextPage = createAsyncThunk(
  "webclientCategory/nextPage",
  async (id: number, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    const numberOfItems = selectCategoryData(state).length;
    const pageCount = selectPageCount(state);
    const page = Math.ceil(numberOfItems / PAGE_SIZE);
    if (page < pageCount) {
      return api.getClientCategory({ token, id, size: PAGE_SIZE, page });
    }
  }
);

const categoriesSlice = createSlice({
  name: "web-client",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getCategory.pending, (state) => {
        if (state.data.length !== 0) state.data = [];
      })
      .addCase(getCategory.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.pageCount = Number.isNaN(payload.pageCount)
          ? Number.POSITIVE_INFINITY
          : payload.pageCount;
      })
      .addCase(getNextPage.fulfilled, (state, { payload }) => {
        if (payload) {
          state.data.push(...payload.data);
          state.pageCount = Number.isNaN(payload.pageCount)
            ? Number.POSITIVE_INFINITY
            : payload.pageCount;
        }
      })
      .addMatcher(
        ({ type }) => {
          return type.match(/^webclientCategory.*pending$/);
        },
        (state) => {
          state.isLoading = true;
          state.error = undefined;
        }
      )
      .addMatcher(
        ({ type }) => {
          return type.match(/^webclientCategory.*fulfilled$/);
        },
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        ({ type }) => {
          return type.match(/^webclientCategory.*rejected$/);
        },
        (state, { error }) => {
          state.isLoading = false;
          state.error = error.message;
        }
      );
  },
});

const sliceSelector = (state: ClientRootState) => state.categories;

export const selectCategoryData = createSelector(
  sliceSelector,
  (state) => state.data
);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);

export const selectError = createSelector(
  sliceSelector,
  (state) => state.error
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);

export default categoriesSlice.reducer;
