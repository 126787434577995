import { FC } from "react";

import "./page-wrapper.scss";

const PageWrapper: FC<any> = ({ children, ...props }) => (
  <div className="page-wrapper" {...props}>
    {children}
  </div>
);

export default PageWrapper;
