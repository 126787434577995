import { Categories } from "common/types";
import moment from "moment";
import { memo, FC } from "react";

type Props = {
  item: Categories.CategoryItem;
  onClick: (item: Categories.CategoryItem) => void;
};

const CategoryCard: FC<Props> = memo(({ item, onClick }) => {
  const { id, name, thumbnailUrl, duration } = item;
  return (
    <div
      className="slider-item"
      key={id}
      onClick={() => {
        onClick(item);
      }}
    >
      <div className="img-wrapper">
        <img alt={name} src={thumbnailUrl} />
        {duration !== 0 && (
          <div className="tag">
            {moment()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .add(duration, "seconds")
              .format("mm:ss")}
          </div>
        )}
      </div>
      <div className="program-name">{name}</div>
    </div>
  );
});

export default CategoryCard;
