import { Link, useRouteMatch } from "react-router-dom";

type PropTypes = {
  path: string;
  label: string;
};

const AddNewButton = ({ path, label }: PropTypes) => {
  const { url } = useRouteMatch();

  return (
    <Link className="add-btn" to={`${url}/${path}/new`}>
      {`Add ${label}`}
    </Link>
  );
};

export default AddNewButton;
