import { useCallback, memo } from "react";
import { useClientDispatch } from "webclient-store/hooks";
import { cancelInitiative } from "./loginSlice";

import "./login.scss";
import { useHistory } from "react-router";

const ForgotPasswordSent = memo(() => {
  const dispatch = useClientDispatch();

  const {
    location: { pathname },
    push,
  } = useHistory();
  const closeModal = useCallback(() => {
    if (pathname === "/activate") {
      push("/");
    }
    dispatch(cancelInitiative());
  }, [dispatch, pathname, push]);

  return (
    <div className="login-block">
      <button className="close-btn" onClick={closeModal}></button>
      <h3 className="smaller">Check your email for further instructions</h3>
    </div>
  );
});

export default ForgotPasswordSent;
