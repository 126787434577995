import {
  TypedUseSelectorHook,
  createSelectorHook,
  createDispatchHook,
} from "react-redux";
import type { ClientRootState, ClientDispatch } from ".";
import { WebClientContext } from ".";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useClientDispatch: () => ClientDispatch =
  createDispatchHook<ClientRootState>(WebClientContext);
export const useClientSelector: TypedUseSelectorHook<ClientRootState> =
  createSelectorHook(WebClientContext);
