import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { OrgUnits } from "common/types";
import { setUserData, selectToken } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";

type OrgUnitsState = SliceBase<OrgUnits.OrgUnit> & {
  currentOrgUnitId: number;
};

const initialState: OrgUnitsState = {
  pageCount: 0,
  currentOrgUnitId: -1,
  data: [],
  isLoading: false,
  error: undefined,
};

export const getOrgUnitList = createAsyncThunk(
  "orgUnits/getList",
  async (_, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    return api.getOrgUnits({ token });
  }
);

const orgUnitSlice = createSlice({
  name: "orgUnitsReducer",
  initialState,
  reducers: {
    setCurrentOrgUnit(state, { payload }: { payload: number }) {
      state.currentOrgUnitId = payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getOrgUnitList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getOrgUnitList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
      })
      .addCase(getOrgUnitList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      });
  },
});

const sliceSelector = (state: AdminRootState) => state.orgUnits;

export const { setCurrentOrgUnit } = orgUnitSlice.actions;

export const selectOrgUnitsList = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectCurrentOrgUnit = createSelector(
  sliceSelector,
  (state) => state.currentOrgUnitId
);

export default orgUnitSlice.reducer;
