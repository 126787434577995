import React, { Context } from "react";
import { ReactReduxContextValue } from "react-redux";
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import theme from "theme/slice";
import webClient from "pages/web-client/HomePage/slice";
import categories from "pages/web-client/CategoryPage/slice";
import adminUserReducer from "pages/web-client/AccountModals/loginSlice";
import search from "pages/web-client/SearchPage/slice";

const persistConfig = {
  key: "user",
  storage,
  blacklist: ["currentStatus", "error", "isLoading"],
};

const persistedUserReducer = persistReducer(persistConfig, adminUserReducer);

const reducer = combineReducers({
  theme,
  webClient,
  rootUser: persistedUserReducer,
  categories,
  search,
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
export const WebClientContext = React.createContext(
  undefined
) as unknown as Context<ReactReduxContextValue>;

export type ClientDispatch = typeof store.dispatch;
export type ClientRootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ClientRootState,
  unknown,
  Action<string>
>;
