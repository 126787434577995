import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { Assets, Categories } from "common/types";
import { selectToken, setUserData } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import { produce } from "immer";
import * as api from "common/api";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";

type CategoriesState = SliceBase<Categories.Category> & {
  editedCategoryAssets: Assets.CategoryItem[];
  isLoadingAssets: boolean;
};

const initialState: CategoriesState = {
  isLoading: false,
  error: undefined,
  isLoadingAssets: false,
  editedCategoryAssets: [],
  data: [],
  pageCount: 0,
};

export const getAssets = createAsyncThunk(
  "categories/getAssets",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.getCategoryAssets({ token, id });
  }
);

export const getCategoriesList = createAsyncThunk(
  "categories/getList",
  async ({ page, size }: { page: number; size: number }, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(getState() as AdminRootState);
    return api.getCategories({ token, orgUnitId, page, size });
  }
);

export const toggleActive = createAsyncThunk(
  "categories/toggleActive",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const category = selectCategoriesList(getState() as AdminRootState).find(
      (cat) => cat.id === id
    )!;
    return api.updateCategory({
      token,
      category: produce(category, (draft) => {
        draft.active = !draft.active;
      }),
    });
  }
);

export const updateCategory = createAsyncThunk(
  "categories/update",
  (category: Categories.Category, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateCategory({
      token,
      category,
    });
  }
);

export const createCategory = createAsyncThunk(
  "categories/create",
  async (category: Categories.Category, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createCategory({
      token,
      category,
    });
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/delete",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.deleteCategory({ token, id });
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(getCategoriesList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCategoriesList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.pageCount = payload.pageCount;
        state.data = payload.data;
      })
      .addCase(getCategoriesList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(toggleActive.fulfilled, (state, { payload }) => {
        const itemIdx = state.data.findIndex((item) => item.id === payload.id);
        if (itemIdx >= 0) {
          state.data[itemIdx] = payload;
        }
      })
      .addCase(toggleActive.rejected, (state, { error }) => {
        state.error = error.message;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const itemIdx = state.data.findIndex((item) => item.id === payload.id);
        if (itemIdx >= 0) {
          state.data[itemIdx] = payload;
        }
      })
      .addCase(updateCategory.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(createCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createCategory.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(({ id }) => id !== payload);
      })
      .addCase(deleteCategory.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(getAssets.pending, (state) => {
        state.isLoadingAssets = true;
      })
      .addCase(getAssets.fulfilled, (state, { payload }) => {
        state.isLoadingAssets = false;
        state.editedCategoryAssets = payload;
      })
      .addCase(getAssets.rejected, (state, { error }) => {
        state.isLoadingAssets = false;
        state.error = error.message;
      });
  },
});

export default categoriesSlice.reducer;

export const { clearError } = categoriesSlice.actions;

const sliceSelector = (state: AdminRootState) => state.categories;

export const selectCategoriesList = createSelector(
  sliceSelector,
  (state) => state.data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);
export const selectError = createSelector(
  sliceSelector,
  (state) => state.error
);

export const selectIsLoadingAssets = createSelector(
  sliceSelector,
  (state) => state.isLoadingAssets
);

export const selectRelatedAssets = createSelector(
  sliceSelector,
  (state) => state.editedCategoryAssets
);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);
