import { ResourceField, ResourceFieldTypes } from "components";
import { ResourceType, IsItemDisabledType, OnSubmitType } from "./types";
import Item from "./Item";
import CONSTANTS from "./constants";
import { useItems } from "./hooks";

const ASSETS_LIST_SIZE = 20;

type PropTypes = {
  onSubmit: OnSubmitType;
  isItemDisabled?: IsItemDisabledType;
  labels?: ResourceFieldTypes.LabelsType;
  multiple?: boolean;
  allowNew?: boolean;
  contentType?: string;
  closeIcon?: boolean;
  backLink?: boolean;
};

const Select = ({
  onSubmit,
  isItemDisabled,
  labels = CONSTANTS.labels,
  multiple,
  allowNew,
  contentType,
  closeIcon = false,
  backLink = true,
}: PropTypes) => {
  const {
    itemsList,
    error,
    isLoading,
    onSearch,
    onLoadMore,
    showLoadMoreButton,
    isLoadMoreLoading,
  } = useItems({
    itemsPerPage: ASSETS_LIST_SIZE,
    contentType,
  });

  return (
    <ResourceField.Select<ResourceType>
      items={itemsList}
      allowNew={allowNew}
      multiple={multiple}
      onSubmit={onSubmit}
      isItemDisabled={isItemDisabled}
      onSearch={onSearch}
      error={error}
      isLoading={isLoading}
      labels={labels}
      Item={Item}
      onLoadMore={onLoadMore}
      isLoadMoreLoading={isLoadMoreLoading}
      showLoadMoreButton={showLoadMoreButton}
      closeIcon={closeIcon}
      backLink={backLink}
    />
  );
};

export default Select;
