import { FC } from "react";
import "./table-header.scss";

const TableHeader: FC<any> = ({ children, ...props }) => (
  <div className="table-header" {...props}>
    {children}
  </div>
);

export default TableHeader;
