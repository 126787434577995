import { Categories } from "common/types";
import { ErrorMessage, LoadingIndicator } from "components";
import { memo, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import "./search-page.scss";
import CategoryCard from "../CategoryPage/CategoryCard";
import { watchVideo } from "../HomePage/slice";
import {
  getSearchResult,
  selectIsLoading,
  selectSearchResult,
  clear,
  getNextPage,
  selectError,
} from "./slice";

const SearchPage = memo(() => {
  const error = useClientSelector(selectError);
  const search = new URLSearchParams(useLocation().search);
  const searchText = search.get("q") || "";
  const history = useHistory();
  const isLoading = useClientSelector(selectIsLoading);

  const dispatch = useClientDispatch();
  useEffect(() => {
    if (searchText.length >= 3) {
      const timeout = setTimeout(() => {
        dispatch(getSearchResult(searchText));
      }, 300);

      return () => clearTimeout(timeout);
    } else if (searchText === "") {
      dispatch(clear());
    }
  }, [dispatch, searchText]);

  const data = useClientSelector(selectSearchResult);
  const onItemClick = useCallback(
    (item: Categories.CategoryItem) => {
      dispatch(watchVideo(item));
      window.scrollTo({ top: 0 });
      history.push("/");
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (!isLoading) {
      const f = () => {
        if (
          window.scrollY + window.innerHeight >= document.body.scrollHeight &&
          searchText.length >= 3
        ) {
          dispatch(getNextPage(searchText));
        }
      };
      window.addEventListener("scroll", f);
      return () => window.removeEventListener("scroll", f);
    }
  }, [dispatch, isLoading, searchText]);

  return (
    <div className="search-page">
      {error && <ErrorMessage error={error} />}
      <div className="content-wrapper">
        <div className="input-wrapper">
          <input
            type="text"
            className="search-input"
            placeholder="Search ..."
            value={searchText}
            onChange={({ target: { value } }) => {
              history.replace({
                pathname: history.location.pathname,
                search: value ? "?q=" + encodeURIComponent(value) : "",
              });
            }}
            ref={(item) => {
              item && item.focus();
            }}
          />
        </div>
        <div className="video-list">
          {data.length === 0 ? (
            <h4>No matches</h4>
          ) : (
            data.map((item, idx) => (
              <CategoryCard
                item={item}
                key={`${item.id}_${idx}`}
                onClick={onItemClick}
              />
            ))
          )}
        </div>
      </div>
      {isLoading && (
        <div className="center-block">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
});

export default SearchPage;
