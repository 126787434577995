import { Tags } from "common/types";
import { FormGroup, SelectTag, EditorPage } from "components";
import { useCancellableSubmit } from "hooks";
import { useEffect, useMemo, useState, useCallback, ChangeEvent } from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  getItemChildren,
  selectItemsList,
  selectError,
  selectIsLoading,
  updateItem,
} from "./slice";
import { ResourceType } from "./types";
import SubAsset from "./SubAsset";

type PropTypes = {
  id: string;
  renderSubAssetsTo?: Element | null;
  additionalTags?: Tags.TagSimple[];
};

type AssetEditable = {
  description: ResourceType["description"];
  name: ResourceType["name"];
  orgUnitId: ResourceType["orgUnitId"];
  tags: ResourceType["tags"];
};

const AssetsEditor = ({ id, renderSubAssetsTo, additionalTags }: PropTypes) => {
  const assetsList = useAdminSelector(selectItemsList);
  const rootAsset = useMemo(
    () => assetsList.find((asset) => asset.id === +id),
    [assetsList, id]
  );
  const dispatch = useAdminDispatch();
  useEffect(() => {
    if (rootAsset && !rootAsset.children)
      dispatch(getItemChildren(rootAsset.id));
  }, [rootAsset, dispatch]);

  const [assetItem, setAssetItem] = useState<AssetEditable | undefined>();
  useEffect(() => {
    rootAsset &&
      setAssetItem({
        description: rootAsset.description,
        name: rootAsset?.name,
        orgUnitId: rootAsset.orgUnitId,
        tags: [...(rootAsset?.tags || []), ...(additionalTags || [])].filter(
          ({ id }, idx, array) => array.findIndex((t) => t.id === id) === idx
        ),
      });
  }, [rootAsset, additionalTags]);

  const onNameChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setAssetItem((v) => v && { ...v, name: value }),
    []
  );

  const onDescriptionChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
      setAssetItem((v) => v && { ...v, description: value }),
    []
  );

  const onTagsChange = useCallback(
    (tags: Tags.TagSimple[]) => setAssetItem((v) => v && { ...v, tags }),
    []
  );
  const error = useAdminSelector(selectError);
  const isLoading = useAdminSelector(selectIsLoading);

  const onSubmit = useCancellableSubmit({
    item: {
      name: assetItem?.name!,
      orgUnitId: assetItem?.orgUnitId!,
      tags: assetItem?.tags!,
      description: assetItem?.description!,
      contentType: rootAsset?.contentType!,
      created: rootAsset?.created!,
      createdById: rootAsset?.createdById!,
      id: rootAsset?.id!,
      assetUrl: "",
    },
    submittingAction: updateItem,
  });

  const childImages = useMemo(
    () =>
      rootAsset?.children
        ? rootAsset.children.filter((asset) =>
            asset.contentType.startsWith("image/")
          )
        : [],
    [rootAsset?.children]
  );

  const childVideos = useMemo(
    () =>
      rootAsset?.children
        ? rootAsset.children.filter((asset) =>
            asset.contentType.startsWith("video/")
          )
        : [],
    [rootAsset?.children]
  );

  return (
    <EditorPage
      closeIcon
      title="Edit Asset"
      error={error}
      isLoading={isLoading}
      controls={{
        submitText: "Save",
        isValid: true,
        onSubmit,
      }}
    >
      {assetItem && (
        <>
          <FormGroup label="Name" invalid={assetItem.name === ""}>
            <input
              className="form-control"
              type="text"
              value={assetItem.name}
              onChange={onNameChange}
            />
          </FormGroup>
          <FormGroup label="Description">
            <textarea
              className="form-control"
              value={assetItem.description}
              onChange={onDescriptionChange}
            />
          </FormGroup>
          <FormGroup label="Tags">
            <SelectTag value={assetItem.tags} isMulti onChange={onTagsChange} />
          </FormGroup>
          {rootAsset?.children && (
            <div>
              {!!childVideos.length && (
                <FormGroup label="Video">
                  <ul className="assets-wrapper">
                    {childVideos.map((asset) => {
                      return (
                        <SubAsset
                          asset={asset}
                          key={asset.id}
                          renderSubAssetsTo={renderSubAssetsTo}
                        />
                      );
                    })}
                  </ul>
                </FormGroup>
              )}
              {!!childImages.length && (
                <FormGroup label="Images">
                  <ul className="assets-wrapper">
                    {childImages.map((asset) => {
                      return (
                        <SubAsset
                          asset={asset}
                          key={asset.id}
                          renderSubAssetsTo={renderSubAssetsTo}
                        />
                      );
                    })}
                  </ul>
                </FormGroup>
              )}
            </div>
          )}
        </>
      )}
    </EditorPage>
  );
};

export default AssetsEditor;
