import cn from "classnames";
import { FC } from "react";
import { ModalWrapperStyled } from "components/styled-blocks";

import "./modal-wrapper.scss";

type PropTypes = {
  show: boolean;
};

const Modal: FC<PropTypes> = ({ show, children }) => {
  return (
    <ModalWrapperStyled className={cn("modal-wrapper", { visible: show })}>
      <div className="modal">{show && children}</div>
    </ModalWrapperStyled>
  );
};

export default Modal;
