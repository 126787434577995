import Select from "react-select";

import { ResourceType } from "./types";
import {
  EditorPage,
  FormGroup,
  Switcher,
  RemoteTranscodeProfilesField,
  RemoteTranscodeProfilesFieldTypes,
} from "components";
import { useCancellableSubmit } from "hooks";
import {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  useMemo,
} from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  createItem,
  selectError,
  selectIsLoading,
  selectItemsList,
  updateItem,
} from "./slice";
import { RemoteChannels } from "common/types";

type PropTypes = {
  id: string;
  agentId: string;
  closePartsToSkip?: number;
  channeldata?: RemoteChannels.RemoteChannel;
  onSubmit?: (schedule: ResourceType) => void;
};

const Editor = ({
  id,
  agentId,
  closePartsToSkip,
  channeldata,
  onSubmit,
}: PropTypes) => {
  const dispatch = useAdminDispatch();

  const [data, setData] = useState<ResourceType | undefined>();

  const items = useAdminSelector(selectItemsList);

  useEffect(() => {
    if (id === "new") {
      setData({
        id: -1,
        name: "",
        type: "",
        sourceUrl: "",
        record: false,
        stationId: "",
        hoursToStore: 0,
        sourceWidth: 1280,
        sourceHeight: 720,
        transcodeProfile: undefined,
        remoteAgentId: parseInt(agentId),
      });
    } else {
      setData(channeldata || items.find((s) => s.id === +id));
    }
  }, [agentId, id, dispatch, items, channeldata]);

  const isLoading = useAdminSelector(selectIsLoading);

  const onTranscodeProfileChange =
    useCallback<RemoteTranscodeProfilesFieldTypes.OnSubmitType>(
      (selected, next) => {
        setData((item) => item && { ...item, transcodeProfile: selected[0] });

        next();
      },
      []
    );

  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, name: value });
    },
    []
  );

  const onTypeChange = useCallback((option) => {
    setData((item) => item && { ...item, type: option.value });
  }, []);

  const onSourceUrlChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, sourceUrl: value });
    },
    []
  );

  const toggleRecord = useCallback(() => {
    setData((item) => item && { ...item, record: !item.record });
  }, []);

  const onStationIdChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, stationId: value });
    },
    []
  );

  const onHoursToStoreChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, hoursToStore: Number(value) });
    }, []);

  const onSourceWidthChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, sourceWidth: Number(value) });
    },
    []
  );

  const onSourceHeightChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, sourceHeight: Number(value) });
    }, []);

  const invalid = useMemo(
    (): {
      [k in keyof ResourceType]?: boolean;
    } => ({
      name: !data?.name,
      type: !data?.type,
      sourceUrl: !data?.sourceUrl,
      sourceWidth: !data?.sourceWidth,
      sourceHeight: !data?.sourceHeight,
      remoteAgentId: !data?.remoteAgentId,
      transcodeProfile: !data?.transcodeProfile,
    }),
    [data]
  );

  const isValid = useMemo(
    () => Object.values(invalid).every((x) => !x),
    [invalid]
  );

  const onSubmitHandler = useCancellableSubmit({
    item: data,
    submittingAction: id === "new" ? createItem : updateItem,
    closePartsToSkip,
    onFinal: onSubmit,
  });

  const error = useAdminSelector(selectError);

  const typeOptions = useMemo(
    () => [
      { value: "live", label: "Live" },
      { value: "linear", label: "Linear" },
    ],
    []
  );

  const selectorTypeValue = useMemo(
    () => typeOptions.find((opt) => opt.value === data?.type),
    [typeOptions, data?.type]
  );

  const [showForm, setShowForm] = useState(true);

  return (
    <>
      <RemoteTranscodeProfilesField.Connector
        onToggle={setShowForm}
        onSubmit={onTranscodeProfileChange}
        multiple={false}
      />
      {showForm && (
        <EditorPage
          title={`${id === "new" ? "Create" : "Edit"} Remote Channel`}
          breadcrumbs={[
            { title: "Agent", id: agentId },
            { title: "Channel", id },
          ]}
          error={error}
          closeIcon
          closePartsToSkip={closePartsToSkip}
          isLoading={isLoading}
          controls={{
            submitText: id === "new" ? "Create" : "Save",
            isValid,
            onSubmit: onSubmitHandler,
          }}
        >
          {data && (
            <>
              <FormGroup label="Name" invalid={invalid.name}>
                <input
                  className="form-control"
                  type="text"
                  value={data.name}
                  onChange={onNameChange}
                />
              </FormGroup>
              <FormGroup label="Type" invalid={invalid.type}>
                <Select
                  className="select"
                  classNamePrefix="custom"
                  options={typeOptions}
                  value={selectorTypeValue}
                  onChange={onTypeChange}
                />
              </FormGroup>
              <FormGroup label="Source URL" invalid={invalid.sourceUrl}>
                <input
                  className="form-control"
                  type="text"
                  value={data.sourceUrl}
                  onChange={onSourceUrlChange}
                />
              </FormGroup>
              <FormGroup label="Station Id">
                <input
                  className="form-control"
                  type="text"
                  value={data.stationId}
                  onChange={onStationIdChange}
                />
              </FormGroup>
              <div className="form-row half">
                <FormGroup label="Record">
                  <Switcher
                    value={!!data.record}
                    onToggle={toggleRecord}
                    className="switcher-field"
                  />
                </FormGroup>
                <FormGroup label="Hours to Store">
                  <input
                    type="number"
                    value={data.hoursToStore || 0}
                    onChange={onHoursToStoreChange}
                  />
                </FormGroup>
              </div>
              <div className="form-row half">
                <FormGroup label="Source Width" invalid={invalid.sourceWidth}>
                  <input
                    type="number"
                    value={data.sourceWidth}
                    onChange={onSourceWidthChange}
                  />
                </FormGroup>
                <FormGroup label="Source Height" invalid={invalid.sourceHeight}>
                  <input
                    type="number"
                    value={data.sourceHeight}
                    onChange={onSourceHeightChange}
                  />
                </FormGroup>
              </div>
              <FormGroup label="Transcode Profile">
                <RemoteTranscodeProfilesField.Field
                  value={data.transcodeProfile && [data.transcodeProfile]}
                />
              </FormGroup>
            </>
          )}
        </EditorPage>
      )}
    </>
  );
};

export default Editor;
