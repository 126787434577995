import { useCallback, useState } from "react";
import { Settings } from "common/types";
import { NavLink } from "react-router-dom";
import { useTheme } from "styled-components";
import cn from "classnames";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  initiateLogin,
  initiateRegistration,
  logout,
  selectIsLoggedIn,
} from "pages/web-client/AccountModals/loginSlice";
import { useMedia } from "./hooks";

import "./header.scss";

const Header = () => {
  const theme = useTheme() as Settings.Settings;
  const [headerActive, setActive] = useState(false);
  const toggleActive = useCallback(() => {
    setActive((x) => !x);
  }, []);
  const deactivate = useCallback(() => {
    setActive(false);
  }, []);
  const isMobile = useMedia(600);
  const active = headerActive && isMobile;
  const dispatch = useClientDispatch();
  const isLoggedIn = useClientSelector(selectIsLoggedIn);

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onLogin = useCallback(() => {
    dispatch(initiateLogin());
  }, [dispatch]);

  const onRegister = useCallback(() => {
    dispatch(initiateRegistration());
  }, [dispatch]);
  return (
    <header className="header">
      <div>
        <div className={cn("main-nav-icon", { active })} onClick={toggleActive}>
          <div />
        </div>
        <div className={cn("mobile-menu", { active })} onClick={deactivate}>
          <NavLink to="/" exact>
            Home
          </NavLink>
          <NavLink to="/activate">Activate</NavLink>
          {isLoggedIn ? (
            <button onClick={onLogout}>Logout</button>
          ) : (
            <>
              <button onClick={onLogin}>Login</button>
              <button onClick={onRegister}>Register</button>
            </>
          )}
        </div>
        <div className="nav">
          <NavLink to="/" exact>
            Home
          </NavLink>
          <NavLink to="/activate">Activate</NavLink>
        </div>
      </div>
      <div className="sub-nav">
        <div className="nav">
          {isLoggedIn ? (
            <button onClick={onLogout}>Logout</button>
          ) : (
            <>
              <button onClick={onLogin}>Login</button>
              <button onClick={onRegister}>Register</button>
            </>
          )}
          <NavLink to="/search" className="search-block">
            <svg width="17.59" height="17.593" viewBox="0 0 17.59 17.593">
              <circle className="stroke" cx="7.06" cy="7.063" r="6.06" />
              <path
                className="stroke"
                d="M3395.82,179.315l-5.25-5.246"
                transform="translate(-3379.22 -162.719)"
              />
            </svg>
          </NavLink>
        </div>
        <div className="logo">
          <img src={theme.logoAsset} alt="logo" />
        </div>
      </div>
    </header>
  );
};
export default Header;
