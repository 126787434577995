import { memo, FC, ReactNode } from "react";
import cn from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { useLocation } from "react-router";

type NavItemProps = {
  to: LinkProps["to"];
  icon?: ReactNode;
  onClick: () => void;
};

const NavItem: FC<NavItemProps> = memo(({ icon, children, to, onClick }) => {
  const location = useLocation();
  return (
    <li
      className={cn("nav-item", { active: location.pathname === to })}
      onClick={onClick}
    >
      <Link to={to} className="nav-item-container">
        <span className="nav-icon">{icon}</span>
        <span className="label">{children}</span>
      </Link>
    </li>
  );
});

export default NavItem;
