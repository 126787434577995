import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
  memo,
} from "react";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  cancelInitiative,
  initiateLogin,
  register,
  selectError,
  selectIsLoggingIn,
} from "./loginSlice";
import cn from "classnames";
import isValidEmail from "./emailTest";

import "./login.scss";
import { LoadingIndicator } from "components";
import { useHistory } from "react-router";

const RegisterModal = memo(() => {
  const isLoggingIn = useClientSelector(selectIsLoggingIn);
  const storeError = useClientSelector(selectError);
  const [name, setName] = useState("");
  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError((v) => v | 0b1000);
      setName(value);
    },
    []
  );

  const [email, setEmail] = useState("");
  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError((v) => v | 0b0100);
      setEmail(value);
    },
    []
  );

  const [password1, setPassword1] = useState("");
  const onPassword1Change: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError((v) => v | 0b0010);
      setPassword1(value);
    },
    []
  );

  const [password2, setPassword2] = useState("");
  const onPassword2Change: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError((v) => v | 0b0001);
      setPassword2(value);
    },
    []
  );

  const dispatch = useClientDispatch();

  const error: string = useMemo(() => {
    if (!isValidEmail(email)) {
      return "Invalid Email";
    }
    if (password1 !== password2) {
      return "Passwords don't match";
    }
    if (name === "") return "All fields are required";
    return "";
  }, [email, name, password1, password2]);

  const [showError, setShowError] = useState(0b0000);

  const onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    setShowError(0b1111);
    if (error === "")
      dispatch(
        register({
          email,
          name,
          password: password1,
        })
      );
  };

  const {
    location: { pathname },
    push,
  } = useHistory();
  const closeModal = useCallback(() => {
    if (pathname === "/activate") {
      push("/");
    }
    dispatch(cancelInitiative());
  }, [dispatch, pathname, push]);

  const goToLogin: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch(initiateLogin());
    },
    [dispatch]
  );

  return (
    <div className="login-block">
      {isLoggingIn ? (
        <LoadingIndicator />
      ) : (
        <>
          <button className="close-btn" onClick={closeModal}></button>
          <h3>Register new account</h3>
          {((showError === 0b1111 && error !== "") || storeError) && (
            <p className="error-message">
              <span className="error-icon">&#10006;</span> {error || storeError}
            </p>
          )}
          <form className="form" onSubmit={onSubmit}>
            <input
              type="text"
              value={name}
              onChange={onNameChange}
              placeholder="Name"
            />
            <input
              type="text"
              value={email}
              onChange={onEmailChange}
              placeholder="Email"
            />
            <input
              type="password"
              value={password1}
              onChange={onPassword1Change}
              placeholder="Password"
            />
            <input
              type="password"
              value={password2}
              onChange={onPassword2Change}
              placeholder="Repeat Password"
            />
            <input
              className={cn("btn btn-primary", {
                active: error,
              })}
              type="submit"
              value="Register"
            />
          </form>

          <div className="login-footer">
            <p>Already have WFMZ account?</p>
            <a className="link" href="/" onClick={goToLogin}>
              Login
            </a>
          </div>
        </>
      )}
    </div>
  );
});

export default RegisterModal;
