import { memo, FC } from "react";
import cn from "classnames";
import { useClientSelector } from "webclient-store/hooks";
import { selectCurrentVideo } from "./slice";
import type { Categories, Channel } from "common/types";
import { useCurrentProgram } from "../hooks";
import Video from "./Video";

import "./description.scss";

const LiveDescription = ({ item }: { item: Channel.Channel }) => {
  const [currentProgram, nextProgram] = useCurrentProgram(item);
  return (
    <div className="description">
      <div>
        <div className="live-tag">Live Now</div>
        <div className="subtitle">{item.name}</div>
        {currentProgram && (
          <>
            <h2 className="title">{currentProgram.program.title}</h2>
            <p>{currentProgram.program.shortDescription}</p>
          </>
        )}
      </div>
      {nextProgram && (
        <div className="next">
          <h4>Up Next</h4>
          <div className="next-title">{nextProgram.program.title}</div>
        </div>
      )}
    </div>
  );
};

const VODDescription = ({ item }: { item: Categories.CategoryItem }) => (
  <div className="description">
    {item && (
      <div>
        <h2 className="title">{item.name}</h2>
        <p>{item.description}</p>
      </div>
    )}
  </div>
);

const VideoScreen: FC = memo(() => {
  const currentVideo = useClientSelector(selectCurrentVideo);

  let description = null;

  if (currentVideo && "listings" in currentVideo) {
    description = <LiveDescription item={currentVideo} />;
  } else if (currentVideo && "url" in currentVideo) {
    description = <VODDescription item={currentVideo} />;
  }

  return (
    <div className={cn("video-block", { hasDescription: !!description})}>
      <Video hasDescription={!!description} />
      {description}
    </div>
  );
});

export default VideoScreen;
