import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { Analytics } from "common/types";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import { AdminRootState } from "admin-store";
import * as api from "common/api";
import { selectToken, setUserData } from "../login/slice";

type AnalyticsState = {
  error?: string;
  isLoading: boolean;
  data: null | Analytics.AnalyticsDashboardResponse;
};

const initialState: AnalyticsState = {
  data: null,
  isLoading: false,
  error: undefined,
};

export const getDashboard = createAsyncThunk(
  "analytics/getDashboard",
  async ({ id }: { id: number }, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(getState() as AdminRootState);

    return api.getAnalyticsDashboard({ token, orgUnitId });
  }
);

const analyticsSlice = createSlice({
  name: "analyticsReducer",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDashboard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(getDashboard.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { clearError } = analyticsSlice.actions;

const sliceSelector = (state: AdminRootState) => state.analytics;

export const selectAnalytics = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export default analyticsSlice.reducer;
