import cn from "classnames";

import { Tags } from "common/types";
import { ResourceField, ResourceFieldTypes } from "components";
import CONSTANTS from "./constants";
import { IsItemDisabledType, OnSubmitType, AssetAcceptTypes } from "./types";
import { SubAssetType } from "./SubAsset";
import Select from "./Select";
import Editor from "./Editor";

type PropTypes = {
  className?: string;
  path?: string;
  labels?: ResourceFieldTypes.LabelsType;
  allowNew?: boolean;
  onToggle: ResourceFieldTypes.OnToggleType;
  onSubmit: OnSubmitType;
  isItemDisabled?: IsItemDisabledType;
  multiple?: boolean;
  additionalTags?: Tags.TagSimple[];
  renderSubAssetsTo?: SubAssetType["renderSubAssetsTo"];
  acceptTypes?: AssetAcceptTypes;
  contentType?: string;
};

const Connector = ({
  className,
  path = CONSTANTS.path,
  labels,
  allowNew,
  multiple,
  onToggle,
  onSubmit,
  isItemDisabled,
  additionalTags,
  renderSubAssetsTo,
  acceptTypes,
  contentType,
}: PropTypes) => {
  return (
    <ResourceField.Connector
      className={cn(CONSTANTS.className, className)}
      path={path}
      onToggle={onToggle}
    >
      {(id) => {
        return id && id === "new" && allowNew ? (
          <Editor
            id={id}
            acceptTypes={acceptTypes}
            additionalTags={additionalTags}
            renderSubAssetsTo={renderSubAssetsTo}
          />
        ) : (
          <Select
            onSubmit={onSubmit}
            isItemDisabled={isItemDisabled}
            labels={labels}
            allowNew={allowNew}
            multiple={multiple}
            contentType={contentType}
          />
        );
      }}
    </ResourceField.Connector>
  );
};

export default Connector;
