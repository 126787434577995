import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import * as api from "common/api";

import { Categories } from "common/types";
import { ClientRootState } from "webclient-store";
import { selectToken } from "../AccountModals/loginSlice";

type SearchState = {
  isLoading: boolean;
  error: string | undefined;
  data: Categories.CategoryItem[];
  pageCount: number;
};

const initialState: SearchState = {
  isLoading: true,
  error: undefined,
  data: [],
  pageCount: Number.POSITIVE_INFINITY,
};

const PAGE_SIZE = 40;

export const getSearchResult = createAsyncThunk(
  "search/get",
  async (search: string, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    return api.searchCategoryItems({ token, search, size: PAGE_SIZE });
  }
);

export const getNextPage = createAsyncThunk(
  "search/nextPage",
  async (search: string, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    const numberOfItems = selectSearchResult(state).length;
    const pageCount = selectPageCount(state);
    const page = Math.ceil(numberOfItems / PAGE_SIZE);
    if (page < pageCount) {
      return api.searchCategoryItems({ token, search, size: PAGE_SIZE, page });
    }
  }
);

const searchSlice = createSlice({
  name: "web-client",
  initialState,
  reducers: {
    clear(state) {
      state.data = [];
      state.isLoading = false;
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getSearchResult.pending, (state) => {
        if (state.data.length !== 0) state.data = [];
      })
      .addCase(getSearchResult.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.pageCount = Number.isNaN(payload.pageCount)
          ? Number.POSITIVE_INFINITY
          : payload.pageCount;
      })
      .addCase(getNextPage.fulfilled, (state, { payload }) => {
        if (payload) {
          state.data.push(...payload.data);
          state.pageCount = Number.isNaN(payload.pageCount)
            ? Number.POSITIVE_INFINITY
            : payload.pageCount;
        }
      })
      .addMatcher(
        ({ type }) => {
          return type.match(/^search.*pending$/);
        },
        (state) => {
          state.isLoading = true;
          state.error = undefined;
        }
      )
      .addMatcher(
        ({ type }) => {
          return type.match(/^search.*fulfilled$/);
        },
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        ({ type }) => {
          return type.match(/^search.*rejected$/);
        },
        (state, { error }) => {
          state.isLoading = false;
          state.error = error.message;
        }
      );
  },
});

export const { clear } = searchSlice.actions;

const sliceSelector = (state: ClientRootState) => state.search;

export const selectSearchResult = createSelector(
  sliceSelector,
  (state) => state.data
);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);

export const selectError = createSelector(
  sliceSelector,
  (state) => state.error
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);

export default searchSlice.reducer;
