import { useCallback, useEffect, useMemo, useState } from "react";

import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  getItemsList,
  selectError,
  selectIsLoading,
  selectItemsList,
} from "./slice";
import { ResourceField, ResourceFieldTypes } from "components";
import { ResourceType, IsItemDisabledType, OnSubmitType } from "./types";
import Item from "./Item";
import CONSTANTS from "./constants";

type PropTypes = {
  onSubmit: OnSubmitType;
  isItemDisabled?: IsItemDisabledType;
  labels?: ResourceFieldTypes.LabelsType;
  multiple?: boolean;
  allowNew?: boolean;
};

const Select = ({
  onSubmit,
  isItemDisabled,
  labels = CONSTANTS.labels,
  multiple,
  allowNew,
}: PropTypes) => {
  const dispatch = useAdminDispatch();

  const itemsList = useAdminSelector(selectItemsList);

  const isLoading = useAdminSelector(selectIsLoading);

  const error = useAdminSelector(selectError);

  const [searchQuery, setSearchQuery] = useState("");

  const displayedItems = useMemo(
    () =>
      itemsList.filter(({ name }) =>
        name.toLowerCase().startsWith(searchQuery.toLowerCase())
      ),
    [searchQuery, itemsList]
  );

  const onSearchCallback = useCallback<ResourceFieldTypes.OnSearchType>(
    (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
    },
    []
  );

  useEffect(() => {
    dispatch(getItemsList());
  }, [dispatch]);

  return (
    <ResourceField.Select<ResourceType>
      items={displayedItems}
      allowNew={allowNew}
      multiple={multiple}
      onSubmit={onSubmit}
      isItemDisabled={isItemDisabled}
      onSearch={onSearchCallback}
      error={error}
      isLoading={isLoading}
      labels={labels}
      Item={Item}
    />
  );
};

export default Select;
