export const apiPath = process.env.REACT_APP_API_PATH;

export const apiToken = process.env.REACT_APP_API_TOKEN;

export const isProd = process.env.REACT_APP_SERVER_ENV === "production";

export const isStage = process.env.REACT_APP_SERVER_ENV === "staging";

export const isDev = process.env.REACT_APP_SERVER_ENV === "development";

export const MIMERegex = /\w+\/[-+.\w]+/;

export const URLRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const ImagesContentTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
];
