import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  // getDefaultMiddleware,
} from "@reduxjs/toolkit";
import adminUser from "pages/admin/login/slice";
import usersReducer from "pages/admin/users/slice";
import tagsReducer from "pages/admin/tagsPage/slice";
import orgUnitReducer from "pages/admin/settings/orgUnitSlice";
import settingsReducer from "pages/admin/settings/settingsSlice";
import tagTypesReducer from "./tagTypeSlice";
import categories from "pages/admin/categories/slice";
import sourcesReducer from "pages/admin/sources/slice";
import liveSourcesReducer from "pages/admin/live-sources/slice";
import remoteAgentsReducer from "components/remote-agents-field/slice";
import remoteChannelsReducer from "components/remote-channels-field/slice";
import remoteSchedulesReducer from "components/remote-schedules-field/slice";
import remoteTranscodeProfilesReducer from "components/remote-transcode-profiles-field/slice";
import remoteTranscodeAudiosReducer from "components/remote-transcode-audios-field/slice";
import remoteTranscodeVideosReducer from "components/remote-transcode-videos-field/slice";
import assetsReducer from "components/assets-field/slice";
import analyticsReducer from "pages/admin/analyticsPage/slice";
import React, { Context } from "react";
import { ReactReduxContextValue } from "react-redux";

const reducer = combineReducers({
  users: usersReducer,
  tagsReducer,
  orgUnits: orgUnitReducer,
  tagTypes: tagTypesReducer,
  adminUser,
  categories,
  sources: sourcesReducer,
  liveSources: liveSourcesReducer,
  remoteAgents: remoteAgentsReducer,
  remoteChannels: remoteChannelsReducer,
  remoteSchedules: remoteSchedulesReducer,
  remoteTranscodeProfiles: remoteTranscodeProfilesReducer,
  remoteTranscodeAudios: remoteTranscodeAudiosReducer,
  remoteTranscodeVideos: remoteTranscodeVideosReducer,
  assets: assetsReducer,
  settings: settingsReducer,
  analytics: analyticsReducer,
});

export const store = configureStore({
  reducer,
});

export const AdminContext = React.createContext(
  undefined
) as unknown as Context<ReactReduxContextValue>;

export type AdminDispatch = typeof store.dispatch;
export type AdminRootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AdminRootState,
  unknown,
  Action<string>
>;

export type SliceBase<T> = {
  pageCount: number;
  data: T[];
  isLoading: boolean;
  error: string | undefined;
};
