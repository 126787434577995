import { useMemo, memo, FC } from "react";
import cn from "classnames";
import type { Categories } from "common/types";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import moment from "moment";
import { useClientSelector } from "webclient-store/hooks";
import { selectCurrentVideo } from "./slice";

import "./category-row.scss";

type Props = {
  category: Categories.ClientCategory;
  onClick: (categoryItem: Categories.CategoryItem) => void;
};

const CategoryRow: FC<Props> = memo(({ category, onClick }) => {
  const items = useMemo(() => category.categoryItems.slice(0, 5), [category]);
  const currentVideo = useClientSelector(selectCurrentVideo);
  return (
    <div className="category-wrapper">
      <div className="wrapper-title">
        <h3>{category.name}</h3>
        <Link className="link" to={`/category/${category.tag.id}`}>
          View All
          <svg width="21.7px" height="20.9px" viewBox="0 0 21.7 20.9">
            <path d="M1.9,0.4l9.6,9.1c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.9-0.5,1.1l-9.6,9.2L0,18.7l8.3-8L0,2.7L1.9,0.4z" />
            <path d="M11.6,0l9.6,9.1c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.9-0.5,1.1l-9.6,9.2l-1.9-2.2l8.3-8l-8.3-8L11.6,0z" />
          </svg>
        </Link>
      </div>
      <Carousel>
        {items.map((item) => {
          const { id, name, thumbnailUrl, duration } = item;
          return (
            <div
              className={cn("slider-item", {
                "playing-now": currentVideo?.id === id,
              })}
              key={id}
              onClick={() => {
                onClick(item);
              }}
            >
              <div className="img-wrapper">
                <img alt={name} src={thumbnailUrl} />
                {duration !== 0 && (
                  <div className="tag">
                    {moment()
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .add(duration, "seconds")
                      .format("mm:ss")}
                  </div>
                )}
              </div>
              <div className="program-name">{name}</div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
});

export default CategoryRow;
