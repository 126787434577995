import { memo, useEffect, useState, FC, ChangeEvent } from "react";
import "./search-bar.scss";

type PropTypes = {
  timeOut?: number;
  onChange: (value: string) => void;
};

const SearchBar: FC<PropTypes> = ({ timeOut = 500, onChange }) => {
  const [value, setValue] = useState("");

  const onFieldChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onChange(value);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [value, onChange, timeOut]);

  return (
    <div className="search-wrapper">
      <input
        type="search"
        placeholder="Search"
        value={value}
        onChange={onFieldChange}
        className="search"
      />
    </div>
  );
};

export default memo(SearchBar);
