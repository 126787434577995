import { Assets } from "common/types";
import { useState, useMemo } from "react";
import { createPortal } from "react-dom";

export type SubAssetType = {
  asset: Assets.Asset;
  renderSubAssetsTo?: Element | null;
};

const SubAsset = ({ asset, renderSubAssetsTo }: SubAssetType) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [isVideo, isImage] = useMemo(
    () => [
      asset.contentType.startsWith("video/"),
      asset.contentType.startsWith("image/"),
    ],
    [asset.contentType]
  );

  const fullscreenContent = useMemo(
    () => (
      <div className="preview-wrapper" onClick={() => setIsFullscreen(false)}>
        <button className="close-btn">Close</button>
        <div className="preview-block">
          {isImage && (
            <img
              src={asset.url || asset.assetUrl || asset.thumbnailUrl}
              alt={asset.description}
            />
          )}
          {isVideo && (
            <video
              controls
              autoPlay
              src={asset.url}
              width={400}
              height={400}
            ></video>
          )}
        </div>
      </div>
    ),
    [asset, isImage, isVideo]
  );

  const visible =
    (isVideo && asset.url) ||
    (isImage && (asset.url || asset.thumbnailUrl || asset.assetUrl));

  if (!visible) return null;

  return (
    <>
      {isFullscreen &&
        renderSubAssetsTo &&
        createPortal(fullscreenContent, renderSubAssetsTo)}
      <li
        onClick={() => {
          setIsFullscreen(true);
        }}
      >
        <span className="asset-block">
          {isImage && (
            <img
              src={asset.url || asset.thumbnailUrl || asset.assetUrl}
              alt={asset.description}
            />
          )}
          {isVideo && (
            <img src={"/video-placeholder.png"} alt={asset.description} />
          )}
          <span className="asset-name">Preview</span>
        </span>
      </li>
    </>
  );
};

export default SubAsset;
