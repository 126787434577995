import { Tags } from "common/types";
import AssetsEditor from "./AssetsEditor";
import AssetUploader from "./AssetUploader";
import { SubAssetType } from "./SubAsset";
import { AssetAcceptTypes } from "./types";

type PropTypes = {
  id: string;
  acceptTypes?: AssetAcceptTypes;
  additionalTags?: Tags.TagSimple[];
  renderSubAssetsTo?: SubAssetType["renderSubAssetsTo"];
};

const Editor = ({
  id,
  acceptTypes,
  additionalTags,
  renderSubAssetsTo,
}: PropTypes) => {
  return id === "new" ? (
    <AssetUploader isSub acceptTypes={acceptTypes} />
  ) : (
    <AssetsEditor
      id={id}
      additionalTags={additionalTags}
      renderSubAssetsTo={renderSubAssetsTo}
    />
  );
};

export default Editor;
