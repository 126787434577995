import { FC } from "react";

import "./editor-form.scss";

const EditorForm: FC = ({ children, ...rest }) => {
  return (
    <div className="modal-form" {...rest}>
      {children}
    </div>
  );
};

export default EditorForm;
