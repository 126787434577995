import { OrgUnit } from "./orgUnit";

export type LoginCredentials = { password: string; username: string };

export type UserSimple = {
  id: number;
  name: string;
  email: string;
};

export enum Role {
  Admin = "Admin",
  Manager = "Manager",
  User = "User",
  Consumer = "Consumer",
}

export type UserComplete = UserSimple & {
  password: string;
  orgUnit: OrgUnit;
  role: Role;
};

export type UserRequest = {
  email: string;
  name: string;
  orgUnitId?: number;
  password: string;
  role: Role;
};
