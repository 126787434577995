import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAdminSelector } from "admin-store/hooks";
import { produce } from "immer";

import { FormGroup, SelectOrgUnit, EditorPage } from "components";
import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useReducer,
} from "react";
import { selectError, createItem, selectIsLoading } from "./slice";
import { useCancellableSubmit, useModalClosePath } from "hooks";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import { contentTypeIsValid, createAcceptTypesString } from "./helpers";
import { AssetContentType, AssetAcceptTypes } from "./types";

type State = {
  isDroppingFile: boolean;
  formData: {
    file?: File | null;
    orgUnitId: number;
  };
};

enum ACTIONS {
  SET_IS_DROPPING = "SET_IS_DROPPING",
  SET_FILE = "SET_FILE",
  SET_ORG_UNIT = "SET_ORG_UNIT",
}

const componentReducer = produce(
  (state: State, action: { type: ACTIONS; payload: any }) => {
    switch (action.type) {
      case ACTIONS.SET_IS_DROPPING:
        state.isDroppingFile = action.payload;
        return;
      case ACTIONS.SET_FILE:
        state.formData.file = action.payload;
        return;
      case ACTIONS.SET_ORG_UNIT:
        state.formData.orgUnitId = action.payload;
        return;
    }
  }
);

const DEFAULT_ACCEPT_TYPES = ["image/*", "video/*"];

type PropTypes = {
  isSub?: boolean;
  acceptTypes?: AssetAcceptTypes;
};

const AssetUploader = ({
  isSub,
  acceptTypes = DEFAULT_ACCEPT_TYPES,
}: PropTypes) => {
  const error = useAdminSelector(selectError);
  const isLoading = useAdminSelector(selectIsLoading);
  const orgUnit = useAdminSelector(selectCurrentOrgUnit)!;

  const [showInputError, setShowInputError] = useState(false);

  const accept = createAcceptTypesString(acceptTypes);

  const contentTypeValid = useCallback(
    (type: AssetContentType): boolean => {
      return contentTypeIsValid(type, acceptTypes);
    },
    [acceptTypes]
  );

  const [
    {
      isDroppingFile,
      formData: { file, orgUnitId },
    },
    componentDispatch,
  ] = useReducer(componentReducer, {
    isDroppingFile: false,
    formData: { orgUnitId: orgUnit },
  });

  const showDrop: DragEventHandler = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    componentDispatch({
      type: ACTIONS.SET_IS_DROPPING,
      payload: true,
    });
  }, []);

  const hideDrop: DragEventHandler = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    componentDispatch({
      type: ACTIONS.SET_IS_DROPPING,
      payload: false,
    });
  }, []);

  const onFileDrop: DragEventHandler = useCallback(
    (evt) => {
      hideDrop(evt);
      const payload = evt.dataTransfer.files[0];
      if (payload && contentTypeValid(payload.type)) {
        componentDispatch({ type: ACTIONS.SET_FILE, payload });
        setShowInputError(false);
      } else {
        setShowInputError(true);
      }
    },
    [hideDrop, contentTypeValid]
  );

  const onFileInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (evt) => {
      const payload = evt.target.files?.[0];
      if (payload && contentTypeValid(payload.type)) {
        setShowInputError(false);
        componentDispatch({ type: ACTIONS.SET_FILE, payload });
      } else {
        setShowInputError(true);
      }
    },
    [contentTypeValid]
  );

  const item = { file, orgUnitId } as { file: File; orgUnitId: number };

  const backUrl = useModalClosePath();

  const history = useHistory();

  const onSubmit = useCancellableSubmit({
    item,
    submittingAction: createItem,
    onReady: (data) => {
      history.push(`${backUrl}/${data.id}`);
    },
  });

  return (
    <EditorPage
      closeIcon
      title="New Asset"
      error={error}
      isLoading={isLoading}
      controls={{
        submitText: "Create",
        isValid: !!file,
        onSubmit,
      }}
    >
      <FormGroup label="Upload file">
        <div
          onDragEnter={showDrop}
          onDragLeave={hideDrop}
          onDragOver={showDrop}
          onDrop={onFileDrop}
          style={{
            backgroundColor: isDroppingFile
              ? "rgba(180, 188, 203, 0.2)"
              : "#ffffff",
          }}
          className="drag-drop"
        >
          {isDroppingFile ? (
            <div />
          ) : file ? (
            <div className="uploaded">
              <span className="file-name">{file.name}</span>
              <button
                className="cancel-btn"
                onClick={() =>
                  componentDispatch({
                    type: ACTIONS.SET_FILE,
                    payload: null,
                  })
                }
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-tj5bde-Svg"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </button>
            </div>
          ) : (
            <label className="custom-file-upload">
              <input type="file" accept={accept} onChange={onFileInput} />
              Choose a file ...
            </label>
          )}
        </div>
        {showInputError && (
          <p className="invalid">{`Error: valid content types ${accept}`}</p>
        )}
      </FormGroup>
      <FormGroup label="orgUnit">
        <SelectOrgUnit
          value={orgUnitId}
          onChange={(orgUnit) => {
            if (orgUnit) {
              componentDispatch({
                type: ACTIONS.SET_ORG_UNIT,
                payload: orgUnit.id,
              });
            }
          }}
        />
      </FormGroup>
    </EditorPage>
  );
};

export default AssetUploader;
