const CONSTANTS = {
  labels: {
    singular: "Transcode Audio",
    plural: "Transcode Audios",
  },
  path: "transcode-audio",
  className: "remote-transcode-audios-field",
};

export default CONSTANTS;
