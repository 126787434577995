import { OrgUnits } from "common/types";
import {
  EditorPage,
  FormGroup,
  Switcher,
  RemoteTranscodeAudiosField,
  RemoteTranscodeAudiosFieldTypes,
  RemoteTranscodeVideosField,
  RemoteTranscodeVideosFieldTypes,
  SelectOrgUnit,
} from "components";
import { useCancellableSubmit } from "hooks";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import {
  ReactNode,
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  useMemo,
} from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import { ResourceType } from "./types";
import {
  createItem,
  selectError,
  selectIsLoading,
  selectItemsList,
  updateItem,
} from "./slice";

const WrapTranscodeField = ({
  id,
  children,
}: {
  id: string | number;
  children: ReactNode;
}) => {
  return id === "new" ? (
    <i>Save current Transcode Profile first</i>
  ) : (
    <div className="actions-wrapper">{children}</div>
  );
};

type PropTypes = {
  id: string;
};

const Editor = ({ id }: PropTypes) => {
  const dispatch = useAdminDispatch();

  const [data, setData] = useState<ResourceType | undefined>();

  const items = useAdminSelector(selectItemsList);

  const defaultOrgUnit = useAdminSelector(selectCurrentOrgUnit)!;

  useEffect(() => {
    if (id === "new") {
      setData({
        id: -1,
        name: "",
        frameRate: 0,
        keyframeInterval: 0,
        pixelFormat: "",
        segmentLength: 0,
        isLiveContent: false,
        isActive: false,
        orgUnitId: defaultOrgUnit,
        transcodeAudioVariants: [],
        transcodeVideoVariants: [],
      });
    } else {
      setData(items.find((s) => s.id === +id));
    }
  }, [id, dispatch, items, defaultOrgUnit]);

  const isLoading = useAdminSelector(selectIsLoading);

  const toggleActive = useCallback(() => {
    setData((item) => item && { ...item, isActive: !item.isActive });
  }, []);

  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, name: value });
    },
    []
  );

  const onFrameRateChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, frameRate: Number(value) });
    },
    []
  );
  const onKeyframeIntervalChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, keyframeInterval: Number(value) });
    }, []);

  const onPixelFormatChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, pixelFormat: value });
    },
    []
  );

  const onSegmentLengthChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, segmentLength: Number(value) });
    }, []);

  const toggleIsLiveContent = useCallback(() => {
    setData((item) => item && { ...item, isLiveContent: !item.isLiveContent });
  }, []);

  const onOrgUnitChange = useCallback((orgUnit?: OrgUnits.OrgUnitSimple) => {
    orgUnit && setData((item) => item && { ...item, orgUnitId: orgUnit.id });
  }, []);

  const onTranscodeAudiosChange =
    useCallback<RemoteTranscodeAudiosFieldTypes.OnSubmitType>(
      (selected, next) => {
        setData((item) => {
          if (item) {
            const tav = item.transcodeAudioVariants
              ? [...item.transcodeAudioVariants]
              : [];

            const targetIndex = tav.findIndex((i) => i.id === selected.id);

            if (targetIndex > -1) {
              // item was updated
              tav[targetIndex] = selected;
            } else {
              // item was created
              tav.push(selected);
            }

            return { ...item, transcodeAudioVariants: tav };
          }
        });

        next();
      },
      []
    );

  const onTranscodeAudioDelete = useCallback((deletedId) => {
    setData(
      (item) =>
        item && {
          ...item,
          transcodeAudioVariants: item.transcodeAudioVariants.filter(
            ({ id }) => id !== deletedId
          ),
        }
    );
  }, []);

  const onTranscodeVideosChange =
    useCallback<RemoteTranscodeVideosFieldTypes.OnSubmitType>(
      (selected, next) => {
        setData((item) => {
          if (item) {
            const tav = item.transcodeVideoVariants
              ? [...item.transcodeVideoVariants]
              : [];

            const targetIndex = tav.findIndex((i) => i.id === selected.id);

            if (targetIndex > -1) {
              // item was updated
              tav[targetIndex] = selected;
            } else {
              // item was created
              tav.push(selected);
            }

            return { ...item, transcodeVideoVariants: tav };
          }
        });

        next();
      },
      []
    );

  const onTranscodeVideoDelete = useCallback((deletedId) => {
    setData(
      (item) =>
        item && {
          ...item,
          transcodeVideoVariants: item.transcodeVideoVariants.filter(
            ({ id }) => id !== deletedId
          ),
        }
    );
  }, []);

  const invalid = useMemo(
    (): {
      [k in keyof ResourceType]?: boolean;
    } => ({
      name: !data?.name,
    }),
    [data]
  );

  const isValid = useMemo(
    () => Object.values(invalid).every((x) => !x),
    [invalid]
  );

  const onSubmit = useCancellableSubmit({
    item: data,
    submittingAction: id === "new" ? createItem : updateItem,
  });

  const error = useAdminSelector(selectError);

  const [showForm, setShowForm] = useState(true);

  return (
    <>
      <RemoteTranscodeAudiosField.Connector
        transcodeProfileId={data?.id || -1}
        onToggle={setShowForm}
        onSubmit={onTranscodeAudiosChange}
      />
      <RemoteTranscodeVideosField.Connector
        transcodeProfileId={data?.id || -1}
        onToggle={setShowForm}
        onSubmit={onTranscodeVideosChange}
      />
      {showForm && (
        <EditorPage
          closeIcon
          title={`${id === "new" ? "Create" : "Edit"} Transcode Profile`}
          error={error}
          isLoading={isLoading}
          controls={{
            submitText: id === "new" ? "Create" : "Save",
            isValid,
            onSubmit,
          }}
        >
          {data && (
            <>
              {" "}
              <Switcher
                value={!!data.isActive}
                onToggle={toggleActive}
                className="active-switcher"
              />
              <FormGroup label="Name" invalid={invalid.name}>
                <input type="text" value={data.name} onChange={onNameChange} />
              </FormGroup>
              <div className="form-row half">
                <FormGroup label="Frame Rate" invalid={invalid.frameRate}>
                  <input
                    type="number"
                    value={data.frameRate}
                    onChange={onFrameRateChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Keyframe Interval"
                  invalid={invalid.keyframeInterval}
                >
                  <input
                    type="number"
                    value={data.keyframeInterval}
                    onChange={onKeyframeIntervalChange}
                  />
                </FormGroup>
              </div>
              <div className="form-row half">
                <FormGroup label="Pixel Format" invalid={invalid.pixelFormat}>
                  <input
                    type="text"
                    value={data.pixelFormat}
                    onChange={onPixelFormatChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Segment Length"
                  invalid={invalid.segmentLength}
                >
                  <input
                    type="number"
                    value={data.segmentLength}
                    onChange={onSegmentLengthChange}
                  />
                </FormGroup>
              </div>
              <FormGroup label="Is LiveContent">
                <Switcher
                  value={!!data.isLiveContent}
                  onToggle={toggleIsLiveContent}
                  className="switcher-field"
                />
              </FormGroup>
              <FormGroup label="Org Unit">
                <SelectOrgUnit
                  value={data.orgUnitId}
                  onChange={onOrgUnitChange}
                />
              </FormGroup>
              <FormGroup label="Transcode Audios">
                <WrapTranscodeField id={id}>
                  <RemoteTranscodeAudiosField.Field
                    value={data.transcodeAudioVariants}
                    onDelete={onTranscodeAudioDelete}
                  />
                </WrapTranscodeField>
              </FormGroup>
              <FormGroup label="Transcode Videos">
                <WrapTranscodeField id={id}>
                  <RemoteTranscodeVideosField.Field
                    value={data.transcodeVideoVariants}
                    onDelete={onTranscodeVideoDelete}
                  />
                </WrapTranscodeField>
              </FormGroup>
            </>
          )}
        </EditorPage>
      )}
    </>
  );
};

export default Editor;
