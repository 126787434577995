import { Channel } from "common/types";
import { FC, memo, useCallback } from "react";
import { useClientSelector } from "webclient-store/hooks";
import { useCurrentProgram } from "../hooks";
import { selectCurrentVideo } from "./slice";
import cn from "classnames";

import "./station.scss";
import ChannelLockedIcon from "./ChannelLockedIcon";

type Props = {
  onClick: (channel: Channel.Channel) => void;
  channel: Channel.Channel;
};

const Station: FC<Props> = memo(({ onClick, channel }) => {
  const _onClick = useCallback(() => onClick(channel), [channel, onClick]);
  const [currentProgram] = useCurrentProgram(channel);
  const currentVideo = useClientSelector(selectCurrentVideo);

  const locked = channel.restrictions.length !== 0;

  return (
    <div
      className={cn("slider-item", {
        "playing-now": currentVideo?.id === channel.id,
        locked,
      })}
      onClick={_onClick}
    >
      <div className="img-wrapper">
        <img
          src={channel.logoUrl}
          alt={channel.name}
          style={{ height: "100%", marginRight: "10px" }}
        />
        <div className="live-tag">Live</div>
        <div className="locked-wrapper">{locked && <ChannelLockedIcon />}</div>
      </div>
      <div className="channel-name">{channel.name}</div>
      <div className="program-name live">
        {currentProgram && <div>{currentProgram.program.title}</div>}
      </div>
    </div>
  );
});

export default Station;
