import { AssetContentType, AssetAcceptTypes } from "./types";

export function createAcceptTypesString(acceptTypes: AssetAcceptTypes): string {
  return acceptTypes.join(", ");
}

export function contentTypeIsValid(
  contentType: AssetContentType,
  acceptTypes: AssetAcceptTypes = []
): boolean {
  return acceptTypes.some((at) => contentType.startsWith(at.replace("*", "")));
}
