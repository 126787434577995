import { EditorFooter } from "components";
import cn from "classnames";
import { Link } from "react-router-dom";

import "./editor-controls.scss";
import { useCallback } from "react";

export type EditorControlsPropTypes = {
  submitText: string;
  isValid?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  modalClosePath?: string;
};

const EditorControls = ({
  submitText,
  onCancel,
  isValid = true,
  onSubmit,
  modalClosePath,
}: EditorControlsPropTypes) => {
  const submit = useCallback(() => {
    if (isValid && onSubmit) {
      onSubmit();
    }
  }, [onSubmit, isValid]);

  return (
    <EditorFooter>
      <div className="btn-wrapper">
        {onCancel ? (
          <button className="btn btn-secondary modal-btn" onClick={onCancel}>
            Cancel
          </button>
        ) : (
          modalClosePath && (
            <Link to={modalClosePath}>
              <button className="btn btn-secondary modal-btn">Cancel</button>
            </Link>
          )
        )}
      </div>
      <div className={cn("btn-wrapper", { active: isValid })}>
        <button className="btn btn-primary modal-btn" onClick={submit}>
          {submitText}
        </button>
      </div>
    </EditorFooter>
  );
};

export default EditorControls;
