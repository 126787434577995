import cn from "classnames";

import "./editor-breadcrumbs.scss";

export type EditorBreadcrumbsPropTypes = {
  className?: string;
  data: { title: string; id: number | string }[];
};

const EditorBreadcrumbs = ({ className, data }: EditorBreadcrumbsPropTypes) => {
  return (
    <div className={cn("editor-breadcrumbs", className)}>
      {data.map(({ title, id }) => `${title}: ${id}`).join(" > ")}
    </div>
  );
};

export default EditorBreadcrumbs;
