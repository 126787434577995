import { memo } from "react";
import { Link } from "react-router-dom";

import "./not-found.scss";

const NotFound = memo(() => {
  return (
    <div className="content-wrapper">
      <div className="center">
        <div className="error-wrapper">
          <h1>Page Not Found</h1>
          <p>Sorry, but we couldn’t find that page you were looking for.</p>
          <Link to="/" className="btn btn-primary">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
});

export default NotFound;
