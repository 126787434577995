import { FC } from "react";
import "./table-wrapper.scss";

const TableWrapper: FC<any> = ({ children, ...props }) => (
  <div className="table-wrapper" {...props}>
    {children}
  </div>
);

export default TableWrapper;
