import { LoadingIndicator } from "components";
import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
  memo,
  useMemo,
} from "react";
import cn from "classnames";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  cancelInitiative,
  selectIsLoggingIn,
  requestPasswordRestore,
  initiateLogin,
  selectError,
} from "./loginSlice";
import isValidEmail from "./emailTest";

import "./login.scss";
import { useHistory } from "react-router";

const ForgotPasswordModal = memo(() => {
  const isLoggingIn = useClientSelector(selectIsLoggingIn);
  const [username, setUsername] = useState("");
  const storeError = useClientSelector(selectError);
  const [showError, setShowError] = useState(false);
  const onUsernameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError(true);
      setUsername(value);
    },
    []
  );

  const error = useMemo(() => {
    if (!isValidEmail(username)) {
      return "Invalid Email";
    }
    return "";
  }, [username]);

  const dispatch = useClientDispatch();
  const onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    setShowError(true);
    if (!error) dispatch(requestPasswordRestore(username));
  };

  const {
    location: { pathname },
    push,
  } = useHistory();
  const closeModal = useCallback(() => {
    if (pathname === "/activate") {
      push("/");
    }
    dispatch(cancelInitiative());
  }, [dispatch, pathname, push]);

  const goToLogin: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch(initiateLogin());
    },
    [dispatch]
  );

  return (
    <div className="login-block">
      {isLoggingIn ? (
        <LoadingIndicator />
      ) : (
        <>
          <button className="close-btn" onClick={closeModal}></button>
          <h3>Lost password</h3>
          {((showError && error !== "") || storeError) && (
            <p className="error-message">
              <span className="error-icon">&#10006;</span> {error || storeError}
            </p>
          )}
          <form className="form" onSubmit={onSubmit}>
            <input
              type="text"
              value={username}
              onChange={onUsernameChange}
              placeholder="Email"
            />
            <input
              className={cn("btn btn-primary", { active: error })}
              type="submit"
              value="Submit"
            />
          </form>

          <a className="link" href="/" onClick={goToLogin}>
            Back to Login
          </a>
        </>
      )}
    </div>
  );
});

export default ForgotPasswordModal;
