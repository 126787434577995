// @ts-nocheck

import videojs from "video.js";
import "videojs-contrib-ads";
import "videojs-ima";
import queryString from "query-string";
import _merge from "lodash.merge";

/**
 * LINKS:
 *
 * IMA SDK for HTML5
 * https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side
 *
 * IMA SDK Plugin for Video.js
 * https://github.com/googleads/videojs-ima
 *
 */

class Controller {
  // these options can be overridden
  DEFAULT_OPTIONS = {
    ima: {
      adLabel: "Advertisement",
      adLabelNofN: "of",
      debug: false,
      disableAdControls: false,
      prerollTimeout: 1000,
      preventLateAdStart: false,
      requestMode: "onLoad",
      showControlsForJSAds: true,
      timeout: 5000,
    },
    inner: {
      debug: false,
      showLiveStreamPoster: true,
      showVodPoster: false,
    },
  };

  // these options cannot be overridden
  OPTIONS = {
    ima: {
      autoPlayAdBreaks: false,
    },
  };

  playerData = null;

  activeListeners = [];

  playerMuteState = {
    muted: undefined,
    lockSync: undefined,
  };

  adState = { playing: undefined };

  constructor(player, options) {
    this.player = player;

    this.initOptions(options);

    this.initMiddlewares();

    this.initImaPlugin();

    this.initCleanUp();

    this.log("Player instance:", this.player);
  }

  log(title, ...args) {
    if (this.options.inner.debug) {
      console.log(`[VIDEOJS JTVWA] ${title}`, ...args);
    }
  }

  initOptions(options) {
    this.options = _merge(
      {},
      this.DEFAULT_OPTIONS,
      options || {},
      this.OPTIONS
    );

    this.log(`Plugin options:`, this.options);
  }

  initMiddlewares() {
    const middleware = function (player) {
      return {
        // prevent pause on live stream (for example by mouse click)
        pause: () => {
          const isLiveStream = this.playerData?.isLiveStream;

          this.log(`Middleware pause event -> isLiveStream: ${isLiveStream}`);

          if (isLiveStream) {
            this.log("Middleware pause event -> pause not allowed");

            player.play();
          }
        },
      };
    }.bind(this);

    videojs.use("*", middleware);
  }

  initImaPlugin() {
    this.player.ima(this.options.ima);

    this.player.ima.initializeAdDisplayContainer();
  }

  initCleanUp() {
    this.player.one(
      "dispose",
      function () {
        this.log("Event -> dispose");

        this.reset();
      }.bind(this)
    );
  }

  loadLiveStream({ hlsUrl, poster }) {
    this.log("Called -> loadLiveStream()");

    this.preload({ isLiveStream: true, hlsUrl, poster });

    const adsReadyCallback = function () {
      this.log("Event -> adsready live stream");

      this.player.ima.playAdBreak();
    }.bind(this);

    this.addEventsListener({
      events: ["adsready"],
      context: this.player,
      handler: "on",
      callback: adsReadyCallback,
    });

    const sources = this.getSources();

    this.player.ima.setContentWithAdTag(sources);

    const playerPlayCallback = function () {
      this.log("Event -> live stream play");

      let textTracks = this.player.textTracks();

      let adCuesTrack;

      this.log("Trying to find 'adCuesTrack'...");

      for (let i = 0; i < textTracks.length; i++) {
        if (
          textTracks[i].label === "ad-cues" &&
          textTracks[i].kind === "metadata"
        ) {
          adCuesTrack = textTracks[i];
        }
      }

      if (!adCuesTrack) {
        this.log("The 'adCuesTrack' was not found.");
      } else {
        this.log("The 'adCuesTrack' found. Waiting for 'cuechange' event...");

        adCuesTrack.mode = "hidden";

        const ts = Math.random();

        let lastCue;

        const cueChangeCallback = function () {
          this.log("Event -> cuechange", ts);

          this.log("Current player time", this.player.currentTime());

          let activeCues = adCuesTrack.activeCues;

          if (!activeCues.length) {
            this.log("No 'activeCues'...");
          } else {
            // Process only the last cue
            const activeCue = activeCues[0];

            this.log("activeCue", activeCue);

            if (lastCue && activeCue.adStartTime <= lastCue.adEndTime) {
              this.log(
                `Skip 'activeCue' ${activeCue.adStartTime} - ${activeCue.adEndTime} because 'lastCue' end time is ${lastCue.adEndTime}.`
              );
            } else {
              this.log("Processing 'activeCue'...");

              lastCue = activeCue;

              const duration = activeCue.adEndTime - activeCue.adStartTime;

              this.log(
                `Ad start time: ${activeCue.adStartTime}, ad end time: ${activeCue.adEndTime}.`
              );

              this.log(`Ad length: ${duration} seconds.`);

              const newAdTagUrl = this.getAdTagUrl();

              this.player.ima.changeAdTag(newAdTagUrl);

              this.player.ima.requestAds();
            }
          }
        }.bind(this);

        this.addEventsListener({
          events: ["cuechange"],
          context: adCuesTrack,
          handler: "addEventListener",
          callback: cueChangeCallback,
        });
      }
    }.bind(this);

    this.addEventsListener({
      events: ["play"],
      context: this.player,
      handler: "one",
      callback: playerPlayCallback,
    });
  }

  loadVod({ hlsUrl, poster }) {
    this.log("Called -> loadVod()");

    this.preload({ isLiveStream: false, hlsUrl, poster });

    const adsReadyCallback = function () {
      this.log("Event -> adsready VOD");

      this.player.ima.playAdBreak();
    }.bind(this);

    this.addEventsListener({
      events: ["adsready"],
      context: this.player,
      handler: "on",
      callback: adsReadyCallback,
    });

    const sources = this.getSources();

    const adTagUrl = this.getAdTagUrl();

    this.player.ima.setContentWithAdTag(sources, adTagUrl);

    this.player.ima.requestAds();
  }

  reset() {
    this.log("Called -> reset()");

    this.removeAllEventListeners();

    this.skipPip();

    this.player?.reset();

    this.player?.ima?.Controller?.reset();

    this.playerData = null;
  }

  preload(playerData) {
    this.log("Called -> preload()");

    this.reset();

    this.setPlayerData(playerData);

    this.togglePlayToggle();

    this.setPlayerPoster();

    this.initPlayerMuteState();

    this.addImaEventListeners();
  }

  skipPip() {
    if (this.player.isInPictureInPicture()) {
      this.log("PIP skipped.");

      this.player.exitPictureInPicture();

      this.player.isInPictureInPicture(false);
    }
  }

  setPlayerData(playerData) {
    this.playerData = {
      isLiveStream: playerData.isLiveStream,
      hlsUrl: playerData.hlsUrl,
      poster: playerData.poster,
    };

    this.log("Player data:", this.playerData);
  }

  getPlayToggle() {
    return this.player.getDescendant(["ControlBar", "PlayToggle"]);
  }

  togglePlayToggle() {
    const PlayToggle = this.getPlayToggle();

    if (!this.playerData.isLiveStream) {
      PlayToggle?.show();
    } else {
      PlayToggle?.hide();
    }
  }

  setPlayerPoster() {
    let poster = this.playerData.poster;

    if (
      (this.playerData.isLiveStream &&
        !this.options.inner.showLiveStreamPoster) ||
      (!this.playerData.isLiveStream && !this.options.inner.showVodPoster)
    ) {
      poster = null;
    }

    this.player.poster(poster);
  }

  initPlayerMuteState() {
    this.playerMuteState.muted = this.player.muted();
    this.playerMuteState.lockSync = false;

    this.log("Player muted state sync -> initial:", this.playerMuteState);

    const handleMutedStateCallback = function (e) {
      this.log(`Event -> handleMutedState -> ${e.type}`);

      if (this.playerMuteState.lockSync) {
        this.log("Player muted state sync -> skip when state sync is locked");
      } else {
        this.playerMuteState.muted = this.player.muted();

        this.log("Player muted state sync -> new:", this.playerMuteState);
      }
    }.bind(this);

    this.addEventsListener({
      events: ["playing", "volumechange"],
        context: this.player,
        handler: "on",
        callback: handleMutedStateCallback,
      });
    }

  onImaEventCallback(e) {
    this.log(`Event -> ${e.type}`);

    switch (e.type) {
      case google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED:
        this.log("Player muted state before ad break:", this.player.muted());

        this.adState.playing = true;

        this.player.muted(true);

        this.playerMuteState.lockSync = true;

        this.log("Player muted:", this.player.muted());

        break;

      case google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED:
      case "adserror":
        this.adState.playing = false;

        this.player.muted(this.playerMuteState.muted);

        this.playerMuteState.lockSync = false;

        this.log("Player muted state after ad break:", this.player.muted());

        // continue to play if player was unexpectedly paused
        if (this.player.autoplay()) {
          this.player.play();
        }

        break;
    }
  }

  addImaEventListeners() {
    const imaAdsReadyCallback = function () {
      // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdEvent
      this.addEventsListener({
        events: [
        google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
        google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
        ],
          context: this.player.ima,
          handler: "addEventListener",
          callback: this.onImaEventCallback.bind(this),
        });
    }.bind(this);

    this.addEventsListener({
      events: ["adserror"],
      context: this.player,
      handler: "on",
      callback: this.onImaEventCallback.bind(this),
    });

    this.addEventsListener({
      events: ["adsready"],
      context: this.player,
      handler: "on",
      callback: imaAdsReadyCallback,
    });
  }

  storeActiveEventListener(args) {
    this.activeListeners.push(args);
  }

  addEventsListener({ events, callback, context = this.player, handler }) {
    for (let index = 0; index < events.length; index++) {
      context[handler](events[index], callback);

      this.storeActiveEventListener({
        event: events[index],
        callback,
        context,
      });
    }
  }

  removeAllEventListeners() {
    this.log("Called -> removeAllEventListeners()");

    this.activeListeners.forEach((evl) => {
      if (evl.context) {
      const handler =
          "removeEventListener" in evl.context ? "removeEventListener" : "off";

        evl.context?.[handler] &&
          evl.context?.[handler](evl.event, evl.callback);
      }
    });

    this.log(`Removed ${this.activeListeners.length} event listeners`);

    this.activeListeners = [];
  }

  getSources() {
    return [
      {
        src: this.playerData.hlsUrl,
        type: "application/x-mpegURL",
      },
    ];
  }

  getAdTagDefaultUrl() {
    return this.playerData.isLiveStream
      ? this.options.adTags.midRollAdTag
      : this.options.adTags.preRollAdTag;
  }

  getAdTagUrl(args = {}) {
    const { duration } = args;

    const adTagUrl = this.getAdTagDefaultUrl();

    const parsedUrl = queryString.parseUrl(adTagUrl);

    if (duration) {
      parsedUrl.query.min_ad_duration = duration;
      parsedUrl.query.max_ad_duration = duration;
    }

    const parsedCustParams = queryString.parse(parsedUrl.query.cust_params);

    parsedCustParams.sample_ct = this.playerData.isLiveStream
      ? "linear"
      : "skippablelinear";

    parsedUrl.query.cust_params = queryString.stringify(parsedCustParams);

    this.log("Parsed ad tag url:", parsedUrl);

    return queryString.stringifyUrl(parsedUrl);
  }
}

class JtvwaPlugin {
  constructor(player, options) {
    this.controller = new Controller(player, options);
  }

  loadLiveStream(args) {
    this.controller.loadLiveStream(args);
  }

  loadVod(args) {
    this.controller.loadVod(args);
  }

  reset() {
    this.controller.reset();
  }
}

const init = function (options) {
  /* eslint no-invalid-this: 'off' */
  this.jtvwa = new JtvwaPlugin(this, options);
};

videojs.registerPlugin("jtvwa", init);

export default JtvwaPlugin;
