const CONSTANTS = {
  labels: {
    singular: "Channel",
    plural: "Channels",
  },
  path: "channels",
  className: "remote-channels-field",
};

export default CONSTANTS;
