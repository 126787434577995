import "./switcher.scss";

type PropTypes = {
  value: boolean;
  onToggle?: () => void;
  className?: string;
};

const Switcher = ({ value, onToggle, className = "" }: PropTypes) => (
  <div className={`switcher ${className}`} onClick={onToggle}>
    <input checked={value} readOnly type="checkbox" />
    <label>Toggle</label>
  </div>
);

export default Switcher;
