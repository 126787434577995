import {
  HomePage,
  AccountModals,
  ResetPassword,
  Activate,
  NotFound,
  LivePage,
  SearchPage,
} from "./pages/web-client";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import Header from "./pages/web-client/Header";
import Footer from "./pages/web-client/Footer";
import { useClientSelector } from "webclient-store/hooks";
import {
  selectCurrentStatus,
  UserFlow,
} from "pages/web-client/AccountModals/loginSlice";
import CategoryPage from "pages/web-client/CategoryPage/CategoryPage";

const WebClient = () => {
  const { path } = useRouteMatch();
  const currentStatus = useClientSelector(selectCurrentStatus);
  return (
    <div className="client-page">
      <Header />
      <div className="main">
        <Switch>
          <Route path={path} exact>
            <HomePage />
          </Route>
          <Route path={`${path}category/:id`}>
            <CategoryPage />
          </Route>
          <Route path={`${path}live-stations`}>
            <LivePage />
          </Route>
          <Route path={`${path}reset`}>
            <ResetPassword />
          </Route>
          <Route path={`${path}activate/:code?`}>
            <Activate />
          </Route>
          <Route path={`${path}search`}>
            <SearchPage />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        {currentStatus !== UserFlow.IDLE && (
          <div className="login-modal">
            <div className="login-wrapper">
              {currentStatus === UserFlow.CREATE_USER ? (
                <AccountModals.RegisterModal />
              ) : currentStatus === UserFlow.FORGOT_PASSWORD ? (
                <AccountModals.ForgotPasswordModal />
              ) : currentStatus === UserFlow.PASSWORD_RESTORE_SENT ? (
                <AccountModals.ForgotPasswordSent />
              ) : (
                <AccountModals.LoginModal />
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default WebClient;
