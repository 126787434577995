import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { selectToken } from "pages/admin/login/slice";
import { AdminRootState } from "admin-store";
import * as api from "common/api";
import { ResourceType } from "./types";

type PageState = {
  data: ResourceType | undefined;
  isLoading: boolean;
  error: string | undefined;
};

const initialState: PageState = {
  data: undefined,
  isLoading: false,
  error: undefined,
};

export const getItem = createAsyncThunk(
  "remoteTranscodeAudios/get",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.getRemoteTranscodeAudioItem({ token, id });
  }
);

export const createItem = createAsyncThunk(
  "remoteTranscodeAudios/create",
  (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createRemoteTranscodeAudio({ token, item });
  }
);

export const updateItem = createAsyncThunk(
  "remoteTranscodeAudios/update",
  async (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateRemoteTranscodeAudio({ token, item });
  }
);

export const deleteItem = createAsyncThunk(
  "remoteTranscodeAudios/delete",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.deleteRemoteTranscodeAudio({ token, id });
  }
);

const slice = createSlice({
  name: "remoteTranscodeAudiosReducer",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getItem.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(getItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(createItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(createItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(updateItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(updateItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (state.data?.id === payload) {
          state.data = undefined;
        }
      })
      .addCase(deleteItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      });
  },
});

export const { clearError } = slice.actions;

const sliceSelector = (state: AdminRootState) => state.remoteTranscodeAudios;

export const selectItem = createSelector(sliceSelector, ({ data }) => data);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export default slice.reducer;
