import DataTableOriginal, { TableProps } from "react-data-table-component";

import styles from "./data-table-styles";

type PropTypes<T> = TableProps<T>;

const DataTable = <T extends unknown>(props: PropTypes<T>) => {
  return <DataTableOriginal {...props} customStyles={styles} />;
};

export default DataTable;
