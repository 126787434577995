import cn from "classnames";
import { FC } from "react";

import "./resource-field.scss";

type PropTypes = {
  className?: string;
};

const FieldWrapper: FC<PropTypes> = ({ className, children }) => {
  return (
    <div className={cn("resource-field", "resource-field-field", className)}>
      {children}
    </div>
  );
};

export default FieldWrapper;
