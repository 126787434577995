import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";

import type { Settings } from "common/types";
import * as api from "common/api";
import { ClientRootState } from "webclient-store";

const defaultSettings: Settings.Settings = {
  orgUnitId: -1,
  accentColor: "#AFCB08",
  secondaryAccentColor: "#FFFFFF",
  backgroundColor: "#f1f5fb",
  secondaryBackgroundColor: "#ECF1FA",
  accentBackgroundColor: "#FFFFFF",
  mainFont: "Inter",
  mainFontColor: "#2c3542",
  secondaryFontColor: "#99A3BA",
  logoAsset: "/jb-logo.svg",
};

type ThemeState = {
  isLoading: boolean;
  error: string | undefined;
  settings: Settings.Settings;
};

const initialState: ThemeState = {
  isLoading: true,
  error: undefined,
  settings: defaultSettings,
};

export const userSliceSelector = (state: ClientRootState) => state.rootUser;

export const selectToken = createSelector(
  userSliceSelector,
  ({ token }) => token
);

export const getWebAppTheme = createAsyncThunk(
  "theme/fetch-settings",
  async (_, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    return api.getSettings({ token });
  }
);

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getWebAppTheme.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.settings = { ...state.settings, ...payload };
      })
      .addCase(getWebAppTheme.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      });
  },
});

const sliceSelector = (state: ClientRootState) => state.theme;

export const selectTheme = createSelector(
  sliceSelector,
  (state) => state.settings
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);

export default themeSlice.reducer;
