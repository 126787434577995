// @ts-nocheck

import videojs from "video.js";
import canAutoPlay from "can-autoplay";

import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useClientSelector } from "webclient-store/hooks";
import { selectAdTags } from "./slice";

import { isProd } from "common/constants";
import { PlayerData } from "common/types/player";
import "./videojs-jtvwa";

/**
 * LINKS:
 *
 * Video.js
 * https://docs.videojs.com/
 *
 * videojs-http-streaming (VHS)
 * https://github.com/videojs/http-streaming
 *
 * Autoplay policy in Chrome
 * https://developer.chrome.com/blog/autoplay/
 *
 */

const useCheckAutoPlay = () => {
  const [autoplayAllowed, setAutoplayAllowed] = useState();
  const [autoplayRequiresMute, setAutoplayRequiresMute] = useState();

  useEffect(() => {
    // check unmuted autoplay
    canAutoPlay.video({ timeout: 200, muted: false }).then((response) => {
      if (response.result === false) {
        // unmuted autoplay is not allowed.
        // check muted autoplay
        canAutoPlay.video({ timeout: 200, muted: true }).then((response) => {
          if (response.result === false) {
            // muted autoplay is not allowed.
            setAutoplayAllowed(false);
            setAutoplayRequiresMute(false);
          } else {
            // muted autoplay is allowed.
            setAutoplayAllowed(true);
            setAutoplayRequiresMute(true);
          }
        });
      } else {
        // unmuted autoplay is allowed.
        setAutoplayAllowed(true);
        setAutoplayRequiresMute(false);
      }
    });
  }, []);

  return { autoplayAllowed, autoplayRequiresMute };
};

export const usePlayer = (playerData: PlayerData) => {
  const playerRef: MutableRefObject<videojs.Player | null> = useRef(null);

  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);

  const [playerInited, setPlayerInited] = useState();

  const adTags = useClientSelector(selectAdTags);

  const { autoplayAllowed, autoplayRequiresMute } = useCheckAutoPlay();

  // init player and ima
  useEffect(() => {
    if (autoplayAllowed === undefined || autoplayRequiresMute === undefined) {
      return;
    }

    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      if (!videoRef.current) {
        return;
      }

      // https://docs.videojs.com/tutorial-options.html
      const options = {
        autoplay: autoplayAllowed,
        muted: autoplayRequiresMute,
        controls: true,
        bigPlayButton: !autoplayAllowed,
        responsive: true,
        fill: true,
        preload: "auto",
        html5: {
          vhs: {
            // https://github.com/videojs/http-streaming#usecuetags
            useCueTags: true,
            // https://github.com/videojs/http-streaming#overridenative
            overrideNative: true,
            // https://github.com/videojs/http-streaming#enablelowinitialplaylist
            enableLowInitialPlaylist: true,
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          nativeTextTracks: false,
        },
      };

      playerRef.current = videojs(videoRef.current, options, function () {
        this.jtvwa({ adTags, inner: { debug: !isProd } });

        setPlayerInited(true);
      });
    }
  }, [autoplayAllowed, autoplayRequiresMute, adTags]);

  // control player
  useEffect(() => {
    if (playerInited) {
      playerRef.current.ready(function () {
        const { isLiveStream, ...args } = playerData;

        if (isLiveStream) {
          this.jtvwa.loadLiveStream(args);
        } else {
          this.jtvwa.loadVod(args);
        }
      });
    }
  }, [playerInited, playerData, adTags]);

  // dispose player
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return { videoRef };
};
