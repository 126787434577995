const CONSTANTS = {
  labels: {
    singular: "Transcode Profile",
    plural: "Transcode Profiles",
  },
  path: "transcode-profiles",
  className: "remote-transcode-profiles-field",
};

export default CONSTANTS;
