import { AsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAdminDispatch } from "admin-store/hooks";

export const useModalClosePath = (closePartsToSkip: number = 1): string => {
  const { pathname } = useLocation();
  const modalClosePath = useMemo(() => {
    const pathComponents = pathname.split("/");
    const newPathComponents = pathComponents.slice(
      0,
      pathComponents.length - closePartsToSkip
    );
    return newPathComponents.join("/");
  }, [pathname, closePartsToSkip]);
  return modalClosePath;
};

export const useCancellableSubmit = <Data, ThunkResult>({
  item,
  submittingAction,
  onReady,
  onFinal,
  closePartsToSkip,
}: {
  item?: Data;
  onReady?: (data: ThunkResult) => void;
  onFinal?: (data: ThunkResult) => void;
  submittingAction: AsyncThunk<ThunkResult, Data, {}>;
  closePartsToSkip?: number;
}) => {
  const dispatch = useAdminDispatch();
  const history = useHistory();
  const cleanup = useRef(() => {});
  const modalClosePath = useModalClosePath(closePartsToSkip);
  const onSubmit = useCallback(() => {
    if (item) {
      const promise = dispatch(submittingAction(item));
      const cb = cleanup.current;
      cleanup.current = () => {
        cb();
        promise.abort();
      };

      promise
        .then(unwrapResult)
        .then((data) => {
          if (onReady) {
            onReady(data);
          } else {
            if (onFinal) {
              onFinal(data);
            }
            history.push(modalClosePath);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [
    item,
    dispatch,
    history,
    submittingAction,
    modalClosePath,
    onReady,
    onFinal,
  ]);

  useEffect(
    () => () => {
      cleanup.current();
    },
    []
  );

  return onSubmit;
};
