import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  initiateLogin,
  selectCurrentStatus,
  selectIsLoggedIn,
  selectToken,
  UserFlow,
} from "pages/web-client/AccountModals/loginSlice";
import cn from "classnames";
import { useEffect, useState, memo } from "react";
import * as api from "common/api";

import "./activate.scss";
import { ReactPinCodeInputComponent } from "react-pin-code-input";
import { LoadingIndicator } from "components";
import { useParams } from "react-router";

const Activate = memo(() => {
  const userLoginStatus = useClientSelector(selectCurrentStatus);
  const isLoggedIn = useClientSelector(selectIsLoggedIn);
  const token = useClientSelector(selectToken);
  const dispatch = useClientDispatch();
  useEffect(() => {
    if (!isLoggedIn && userLoginStatus === UserFlow.IDLE) {
      dispatch(initiateLogin());
    }
  }, [userLoginStatus, isLoggedIn, dispatch]);

  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const [isActivated, setIsActivated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { code: paramCode } = useParams<{ code: string }>();
  useEffect(() => {
    if (token && paramCode) {
      setIsLoading(true);
      api.activate({ code: paramCode, token }).then(() => {
        setIsLoading(false);
        setIsActivated(true);
      });
    }
  }, [paramCode, token]);

  // useEffect(() => {
  //   let code: string;
  //   (async () => {
  //     code = await api.getActivationCode();
  //     console.log({ code });
  //   })();

  //   const interval = setInterval(async () => {
  //     try {
  //       const data = await api.checkCodeValidation(code);
  //       console.log(data);
  //     } catch {}
  //   }, 3000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  return (
    <div className="content-wrapper">
      <div className="center">
        <div className="activate-wrapper">
          {isLoading ? (
            <LoadingIndicator />
          ) : isActivated ? (
            <div className="success-message">
              <div className="success-icon">
                <svg width="14.18" height="11.188" viewBox="0 0 14.18 11.188">
                  <path
                    className="stroke"
                    d="M2401,237l5,5,7-8"
                    transform="translate(-2399.94 -233)"
                  />
                </svg>
              </div>
              <h1>Your device is successfully activated</h1>
            </div>
          ) : (
            <>
              <h1>Activate Your Device</h1>
              <p>Enter the code displayed on your device</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const result = code.join("").toUpperCase();
                  if (result.length === 6) {
                    setIsLoading(true);
                    setCode(["", "", "", "", "", ""]);
                    api.activate({ code: result, token }).then(() => {
                      setIsLoading(false);
                      setIsActivated(true);
                    });
                  }
                }}
              >
                <ReactPinCodeInputComponent
                  onInputChange={setCode}
                  value={code}
                  type="text"
                  inputStyle={{
                    border: "1px solid",
                    borderRadius: "0.5rem",
                    fontSize: "2rem",
                    height: "6rem",
                    marginBottom: "3rem",
                    marginRight: "1rem",
                    maxWidth: "4.5rem",
                    outline: "0",
                    padding: "0.5rem 0",
                    transition: "all 0.3s",
                    width: "15%",
                  }}
                />
                <input
                  className={cn("btn btn-primary", {
                    active: code.join("").length !== 6,
                  })}
                  type="submit"
                  value="Activate"
                />
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default Activate;
