import { memo, FC } from "react";
import cn from "classnames";

import { PlayerData } from "common/types/player";
import { useClientSelector } from "webclient-store/hooks";
import { usePlayer } from "./usePlayer";
import { selectCurrentVideo, selectPlayerData } from "./slice";

import ChannelLockedIcon from "./ChannelLockedIcon";

import "video.js/dist/video-js.css";
import "videojs-contrib-ads/dist/videojs.ads.css";
import "videojs-ima/dist/videojs.ima.css";
import "./video.scss";

const VideoJS: FC = memo(() => {
  const playerData: PlayerData = useClientSelector(selectPlayerData);

  const { videoRef } = usePlayer(playerData);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin vjs-big-play-centered"
        width="100%"
      >
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to
          a web browser that
          <a
            href="https://videojs.com/html5-video-support/"
            target="_blank"
            rel="noreferrer"
          >
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>
  );
});

type VideoProps = {
  hasDescription: boolean;
};

const Video: FC<VideoProps> = memo(({ hasDescription }) => {
  const currentVideo = useClientSelector(selectCurrentVideo);

  if (!currentVideo) {
    return null;
  }

  const Lock =
    currentVideo &&
    "restrictions" in currentVideo &&
    currentVideo.restrictions.length ? (
      <>
        <img src={currentVideo.logoUrl} alt={currentVideo.name} />
        <div className="locked-wrapper">
          <ChannelLockedIcon />
          <p>
            {
              (
                currentVideo.restrictions.find(
                  (restriction) => restriction.code === 100
                ) || currentVideo.restrictions[0]
              ).title
            }
          </p>
        </div>
      </>
    ) : null;

  return (
    <div className={cn("preview-block", { locked: !!Lock, hasDescription })}>
      {Lock || <VideoJS />}
    </div>
  );
});

export default Video;
