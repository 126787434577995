import { OrgUnits } from "common/types";
import { ResourceType } from "./types";
import { EditorPage, FormGroup, SelectOrgUnit } from "components";
import { useCancellableSubmit } from "hooks";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  useMemo,
} from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  createItem,
  selectError,
  selectIsLoading,
  selectItemsList,
  updateItem,
} from "./slice";
import { selectCurrentUser } from "pages/admin/login/slice";

type PropTypes = {
  id: string;
  closePartsToSkip?: number;
  agentData?: ResourceType;
};

const AgentsEditor = ({ id, closePartsToSkip, agentData }: PropTypes) => {
  const dispatch = useAdminDispatch();

  const [data, setData] = useState<ResourceType | undefined>();

  const items = useAdminSelector(selectItemsList);

  const defaultOrgUnit = useAdminSelector(selectCurrentOrgUnit)!;

  const currentUser = useAdminSelector(selectCurrentUser)!;

  useEffect(() => {
    if (id === "new") {
      setData({
        id: -1,
        created: "",
        agentId: "",
        orgUnit: {
          name: /*defaultOrgUnit.name,*/ "",
          id: defaultOrgUnit,
        },
        createdBy: currentUser,
        allowedIpRange: "",
        rootStoragePath: "",
        token: "",
        channels: [],
      });
    } else {
      setData(agentData || items.find((s) => s.id === +id));
    }
  }, [id, dispatch, items, agentData, defaultOrgUnit, currentUser]);

  const isLoading = useAdminSelector(selectIsLoading);

  const onAgentIdChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, agentId: value });
    },
    []
  );

  const onAllowedIpRangeChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, allowedIpRange: value });
    }, []);

  const onRootStoragePathChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, rootStoragePath: value });
    }, []);

  const onTokenChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, token: value });
    },
    []
  );

  const onOrgUnitChange = useCallback((orgUnit?: OrgUnits.OrgUnitSimple) => {
    orgUnit && setData((item) => item && { ...item, orgUnitId: orgUnit.id });
  }, []);

  const invalid = useMemo(
    (): {
      [k in keyof ResourceType]?: boolean;
    } => ({
      agentId: data?.agentId === "",
      allowedIpRange: data?.allowedIpRange === "",
      rootStoragePath: data?.rootStoragePath === "",
    }),
    [data]
  );

  const isValid = useMemo(
    () => Object.values(invalid).every((x) => !x),
    [invalid]
  );

  const onSubmit = useCancellableSubmit({
    item: data,
    submittingAction: id === "new" ? createItem : updateItem,
    closePartsToSkip,
  });

  const error = useAdminSelector(selectError);

  return (
    <EditorPage
      closeIcon
      title={`${id === "new" ? "Create" : "Edit"} Remote Agent`}
      breadcrumbs={[{ title: "Agent", id }]}
      closePartsToSkip={closePartsToSkip}
      error={error}
      isLoading={isLoading}
      controls={{
        submitText: id === "new" ? "Create" : "Save",
        isValid,
        onSubmit,
      }}
    >
      {data && (
        <>
          <FormGroup label="Agent Id" invalid={invalid.agentId}>
            <input
              className="form-control"
              type="text"
              value={data.agentId}
              onChange={onAgentIdChange}
            />
          </FormGroup>
          <FormGroup label="Allowed IP Range" invalid={invalid.allowedIpRange}>
            <input
              className="form-control"
              type="text"
              value={data.allowedIpRange}
              onChange={onAllowedIpRangeChange}
            />
          </FormGroup>
          <FormGroup
            label="Root Storage Path"
            invalid={invalid.rootStoragePath}
          >
            <input
              className="form-control"
              type="text"
              value={data.rootStoragePath}
              onChange={onRootStoragePathChange}
            />
          </FormGroup>
          <FormGroup label="Token" invalid={invalid.token}>
            <textarea
              className="form-control"
              value={data.token}
              onChange={onTokenChange}
            />
          </FormGroup>
          <FormGroup label="Org Unit">
            <SelectOrgUnit value={data.orgUnit.id} onChange={onOrgUnitChange} />
          </FormGroup>
        </>
      )}
    </EditorPage>
  );
};

export default AgentsEditor;
