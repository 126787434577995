import { TableColumn } from "react-data-table-component";
import EditColumn, { PropTypes as EditColumnTypes } from "./EditColumn";
import DeleteColumn, { PropTypes as DeleteColumnTypes } from "./DeleteColumn";
import { produce } from "immer";

export type RequiredParametersType = { id: number };

type ColumnType<T> = {
  name?: string;
  selector?: (row: T) => string;
  sortable?: boolean;
};

type Types<T> = {
  nameProps?: ColumnType<T> | null;
  editProps?: EditColumnTypes;
  deleteProps?: DeleteColumnTypes;
  columns: TableColumn<T>[];
  idColumn?: boolean;
};

const Columns = <T extends RequiredParametersType>({
  nameProps,
  editProps,
  deleteProps,
  columns,
  idColumn = true,
}: Types<T>) => {
  return produce(columns, (d) => {
    if (nameProps !== null) {
      d.unshift(
        Object.assign(
          {},
          {
            name: "Name",
            sortable: true,
            selector: (row: T & { name: string }) => row.name,
          },
          nameProps
        )
      );
    }

    if (idColumn) {
      d.unshift({
        name: "Id",
        selector: (row: T) => row.id,
        sortable: true,
        width: "10rem",
      });
    }

    if (editProps) {
      d.push(EditColumn(editProps));
    }

    if (deleteProps) {
      d.push(DeleteColumn(deleteProps));
    }
  });
};

export default Columns;
