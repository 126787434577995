import { FC, memo } from "react";
import { Link, useLocation } from "react-router-dom";

import "./creation-link.scss";

const CreationLink: FC<{ path?: string }> = ({ path }) => {
  const location = useLocation();
  return (
    <Link
      className="btn btn-primary creation-link"
      to={`${location.pathname}${path ? path : ""}/new`}
    >
      <span className="btn-label">Add New</span>
      <span className="btn-icon">+</span>
    </Link>
  );
};

export default memo(CreationLink);
