const CONSTANTS = {
  labels: {
    singular: "Asset",
    plural: "Assets",
  },
  path: "assets",
  className: "assets-field",
};

export default CONSTANTS;
