import cn from "classnames";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

import "./editor-page.scss";

import {
  EditorForm,
  EditorTitle,
  EditorControls,
  ErrorMessage,
  LoadingIndicator,
} from "components";
import { useModalClosePath } from "hooks";
import { EditorControlsPropTypes } from "components/editor-controls/EditorControls";
import EditorBreadcrumbs, {
  EditorBreadcrumbsPropTypes,
} from "components/editor-breadcrumbs/EditorBreadcrumbs";

type PropTypes = {
  className?: string;
  title: ReactNode;
  breadcrumbs?: EditorBreadcrumbsPropTypes["data"];
  error?: string;
  closePartsToSkip?: number;
  closeIcon?: boolean;
  backLink?: boolean;
  isLoading?: boolean;
  controls: Omit<EditorControlsPropTypes, "modalClosePath">;
};

const EditorPage: FC<PropTypes> = ({
  className,
  title,
  breadcrumbs,
  error,
  closePartsToSkip,
  closeIcon,
  backLink,
  children,
  isLoading,
  controls,
}) => {
  const modalClosePath = useModalClosePath(closePartsToSkip);

  return (
    <div className={cn("editor-page", className)}>
      {closeIcon && (
        <Link to={modalClosePath}>
          <button className="close-btn">Close</button>
        </Link>
      )}
      {error && <ErrorMessage error={error} />}
      <EditorTitle>
        {backLink && <Link className="back-btn" to={modalClosePath}></Link>}
        {title}
      </EditorTitle>
      {breadcrumbs && <EditorBreadcrumbs data={breadcrumbs} />}
      {isLoading ? <LoadingIndicator /> : <EditorForm>{children}</EditorForm>}
      <EditorControls {...controls} modalClosePath={modalClosePath} />
    </div>
  );
};

export default EditorPage;
