import cn from "classnames";

import { ResourceField } from "components";
import CONSTANTS from "./constants";
import ImageBlock from "./ImageBlock";

type PropTypes = {
  className?: string;
  path?: string;
  label?: string;
  value?: string[];
};

const ImageField = ({
  className,
  path = CONSTANTS.path,
  label = CONSTANTS.labels.singular,
  value,
}: PropTypes) => {
  return (
    <ResourceField.FieldWrapper
      className={cn(CONSTANTS.className, "assets-image-field", className)}
    >
      {value && value.length ? (
        <ResourceField.ItemsListWrapper>
          {value.map((url) => {
            return (
              <li key={url}>
                <div className="assets-image-field-item">
                  <ImageBlock url={url} />
                </div>
              </li>
            );
          })}
        </ResourceField.ItemsListWrapper>
      ) : null}
      <ResourceField.ChangeButton
        path={path}
        label={label}
        valueLength={value ? value.length : 0}
      />
    </ResourceField.FieldWrapper>
  );
};

export default ImageField;
