type PropTypes = {
  url?: string;
  name?: string;
};

const ImageBlock = ({ url, name }: PropTypes) => {
  return (
    <>
      <span className="img-block">
        <img src={url || "/video-placeholder.png"} alt={name || ""} />
      </span>
      {name && <span className="asset-name">{name}</span>}
    </>
  );
};

export default ImageBlock;
