import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
// import { AdminRootState } from "admin-store";
import { ClientRootState } from "webclient-store";
import * as api from "common/api";
import { UserTypes } from "common/types";

export enum UserFlow {
  IDLE,
  LOGIN,
  CREATE_USER,
  FORGOT_PASSWORD,
  PASSWORD_RESTORE_SENT,
}

export type UserState = {
  currentStatus: UserFlow;
  isLoading: boolean;
  error?: string;
  token: string;
  user: UserTypes.UserComplete | null;
};

const initialState: UserState = {
  currentStatus: UserFlow.IDLE,
  token: "",
  user: null,
  isLoading: false,
  error: undefined,
};

export const login = createAsyncThunk(
  "adminUser/login",
  (params: UserTypes.LoginCredentials) => api.login(params)
);

export const register = createAsyncThunk(
  "adminUser/register",
  async (params: Omit<UserTypes.UserRequest, "role" | "orgUnitId">) => {
    await api.createUser({
      token: api.CLIENT_TOKEN,
      user: { ...params, role: UserTypes.Role.User },
    });
    return api.login({ username: params.email, password: params.password });
  }
);

export const requestPasswordRestore = createAsyncThunk(
  "asminUser/forgotPass",
  async (email: string) => {
    await api.forgotPasswordRequest({ email });
  }
);

export const userSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    logout(state) {
      Object.assign(state, initialState);
    },
    initiateLogin(state) {
      state.currentStatus = UserFlow.LOGIN;
    },
    initiateRegistration(state) {
      state.currentStatus = UserFlow.CREATE_USER;
    },
    initiateForgotPassword(state) {
      state.currentStatus = UserFlow.FORGOT_PASSWORD;
    },
    cancelInitiative(state) {
      state.currentStatus = UserFlow.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.token = payload.token;
        state.user = payload.user;
        state.isLoading = false;
        state.currentStatus = UserFlow.IDLE;
      })
      .addCase(login.rejected, (state, { error }) => {
        state.error = error.message;
        state.token = "";
        state.isLoading = false;
      })
      .addCase(register.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.token = payload.token;
        state.user = payload.user;
        state.currentStatus = UserFlow.IDLE;
      })
      .addCase(register.rejected, (state, { error }) => {
        state.error = error.message;
        state.token = "";
        state.isLoading = false;
      })
      .addCase(requestPasswordRestore.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(requestPasswordRestore.fulfilled, (state) => {
        state.isLoading = false;
        state.currentStatus = UserFlow.PASSWORD_RESTORE_SENT;
      })
      .addCase(requestPasswordRestore.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      });
  },
});

export const {
  logout,
  initiateLogin,
  initiateRegistration,
  cancelInitiative,
  initiateForgotPassword,
} = userSlice.actions;

export const userSliceSelector = (state: ClientRootState) => state.rootUser;
export const selectToken = createSelector(
  userSliceSelector,
  ({ token }) => token
);
export const selectIsLoggedIn = createSelector(
  selectToken,
  (token) => token !== ""
);
export const selectIsLoggingIn = createSelector(
  userSliceSelector,
  ({ isLoading }) => isLoading
);
export const selectError = createSelector(
  userSliceSelector,
  ({ error }) => error
);

export const selectCurrentUser = createSelector(
  userSliceSelector,
  ({ user }) => user
);
export const selectCurrentStatus = createSelector(
  userSliceSelector,
  (state) => state.currentStatus
);

export default userSlice.reducer;
