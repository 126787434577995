import { Categories } from "common/types";
import { ErrorMessage, LoadingIndicator } from "components";
import { memo, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  fetchCategories,
  selectCategories,
  watchVideo,
} from "../HomePage/slice";
import CategoryCard from "./CategoryCard";
import {
  getCategory,
  selectCategoryData,
  selectIsLoading,
  selectError,
  getNextPage,
} from "./slice";

import "./category-page.scss";

const CategoryPage = memo(() => {
  const { id } = useParams<{ id: string }>();
  const isLoading = useClientSelector(selectIsLoading);
  const categoryData = useClientSelector(selectCategoryData);
  const error = useClientSelector(selectError);
  const dispatch = useClientDispatch();
  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(getCategory(+id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!isLoading) {
      const f = () => {
        if (window.scrollY + window.innerHeight >= document.body.scrollHeight) {
          dispatch(getNextPage(+id));
        }
      };
      window.addEventListener("scroll", f);
      return () => window.removeEventListener("scroll", f);
    }
  }, [dispatch, isLoading, id]);

  const history = useHistory();
  const onItemClick = useCallback(
    (item: Categories.CategoryItem) => {
      dispatch(watchVideo(item));
      window.scrollTo({ top: 0 });
      history.push("/");
    },
    [dispatch, history]
  );

  const categories = useClientSelector(selectCategories);
  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
  }, [categories, dispatch]);
  const currentCategory = categories.find((item) => item.tag.id === +id);

  return (
    <div className="category-page">
      {error && <ErrorMessage error={error} />}
      <div className="content-wrapper">
        <h1>{currentCategory?.name}</h1>
        <div className="video-list">
          {categoryData.map((item) => (
            <CategoryCard item={item} key={item.id} onClick={onItemClick} />
          ))}
        </div>
      </div>
      {isLoading && (
        <div className="center-block">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
});

export default CategoryPage;
