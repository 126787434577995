import cn from "classnames";

export type PropTypes = {
  className?: string;
  label?: string;
  onSubmit: (id: number) => void;
};

const DeleteAction = ({
  className,
  id,
  label = "delete",
  onSubmit,
}: PropTypes & { id: number }) => {
  return (
    <span
      className={cn("delete-action", "center", className)}
      onClick={() => {
        if (window.confirm("Are you sure?")) {
          onSubmit(id);
        }
      }}
    >
      <button className="delete-icon">{label}</button>
    </span>
  );
};

const DeleteColumn = (props: PropTypes) => {
  return {
    compact: true,
    name: "",
    width: "8rem",
    cell: ({ id }: { id: number; [key: string]: any }) => (
      <DeleteAction id={id} {...props} />
    ),
  };
};

export default DeleteColumn;
