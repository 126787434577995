import cn from "classnames";

import { ResourceType } from "./types";
import { ResourceField } from "components";
import Item from "./Item";
import CONSTANTS from "./constants";

type PropTypes = {
  className?: string;
  path?: string;
  label?: string;
  value?: ResourceType[];
};

const Field = ({
  className,
  path = CONSTANTS.path,
  label = CONSTANTS.labels.singular,
  value,
}: PropTypes) => {
  return (
    <ResourceField.Field
      className={cn(CONSTANTS.className, className)}
      path={path}
      label={label}
      value={value}
      Item={Item}
    />
  );
};

export default Field;
