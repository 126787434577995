import { useHistory } from "react-router-dom";

import { RemoteTranscodeAudios } from "common/types";
import { EditorPage, FormGroup } from "components";
import { useCancellableSubmit, useModalClosePath } from "hooks";
import {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  useMemo,
} from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  getItem,
  createItem,
  selectError,
  selectIsLoading,
  selectItem,
  updateItem,
} from "./slice";
import { OnSubmitType } from "./types";

type PropTypes = {
  id: string;
  transcodeProfileId: number;
  onSubmit: OnSubmitType;
};

const TranscodeAudiosEditor = ({
  id,
  transcodeProfileId,
  onSubmit,
}: PropTypes) => {
  const history = useHistory();

  const dispatch = useAdminDispatch();

  const [data, setData] = useState<
    RemoteTranscodeAudios.RemoteTranscodeAudio | undefined
  >();

  const item = useAdminSelector(selectItem);

  useEffect(() => {
    if (id !== "new") {
      dispatch(getItem(parseInt(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id === "new") {
      setData({
        id: -1,
        name: "",
        codec: "",
        channels: 0,
        bitrate: "",
        extraArguments: "",
        transcodeProfileId,
      });
    } else if (item) {
      setData(item);
    }
  }, [id, dispatch, item, transcodeProfileId]);

  const isLoading = useAdminSelector(selectIsLoading);

  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, name: value });
    },
    []
  );

  const onCodecChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, codec: value });
    },
    []
  );

  const onBitrateChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, bitrate: value });
    },
    []
  );

  const onChannelsChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, channels: parseInt(value) });
    },
    []
  );

  const onExtraArgumentsChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, extraArguments: value });
    }, []);

  const invalid = useMemo(
    (): {
      [k in keyof RemoteTranscodeAudios.RemoteTranscodeAudio]?: boolean;
    } => ({
      name: !data?.name,
      codec: !data?.codec,
      bitrate: !data?.bitrate,
    }),
    [data]
  );

  const isValid = useMemo(
    () => Object.values(invalid).every((x) => !x),
    [invalid]
  );

  const backPath = useModalClosePath(2);

  const onSubmitHandler = useCancellableSubmit({
    item: data,
    submittingAction: id === "new" ? createItem : updateItem,
    onReady: (item) => {
      onSubmit(item, () => {
        history.push(backPath);
      });
    },
    closePartsToSkip: 2,
  });

  const error = useAdminSelector(selectError);

  return (
    <EditorPage
      backLink
      closePartsToSkip={2}
      title={`${id === "new" ? "Create" : "Edit"} Transcode Audio`}
      error={error}
      isLoading={isLoading}
      controls={{
        submitText: id === "new" ? "Create" : "Save",
        isValid,
        onSubmit: onSubmitHandler,
      }}
    >
      {data && (
        <>
          <FormGroup label="Name" invalid={invalid.name}>
            <input
              className="form-control"
              type="text"
              value={data.name}
              onChange={onNameChange}
            />
          </FormGroup>
          <div className="form-row half">
            <FormGroup label="Codec" invalid={invalid.codec}>
              <input
                className="form-control"
                type="text"
                value={data.codec}
                onChange={onCodecChange}
              />
            </FormGroup>
            <FormGroup label="Bitrate" invalid={invalid.bitrate}>
              <input
                type="text"
                value={data.bitrate}
                onChange={onBitrateChange}
              />
            </FormGroup>
          </div>
          <div className="form-row half">
            <FormGroup label="Channels" invalid={invalid.channels}>
              <input
                className="form-control"
                type="number"
                value={data.channels}
                onChange={onChannelsChange}
              />
            </FormGroup>
            <FormGroup label="Extra Arguments" invalid={invalid.extraArguments}>
              <input
                type="text"
                value={data.extraArguments}
                onChange={onExtraArgumentsChange}
              />
            </FormGroup>
          </div>
        </>
      )}
    </EditorPage>
  );
};

export default TranscodeAudiosEditor;
