import axios, { AxiosError } from "axios";
import {
  UserTypes,
  Tags,
  OrgUnits,
  Categories,
  Sources,
  LiveSources,
  Assets,
  Settings,
  Channel,
  RemoteAgents,
  RemoteChannels,
  RemoteSchedules,
  RemoteTranscodeProfiles,
  RemoteTranscodeAudios,
  RemoteTranscodeVideos,
  Analytics,
} from "common/types";

import { apiPath, apiToken } from "./constants";

axios.defaults.baseURL = apiPath;

export const PAGE_SIZE = 10;

export let cancel: () => void;

const cancelToken = () =>
  new axios.CancelToken((c) => {
    cancel = c;
  });

type Token = string;
type Bearer = { token: Token };
type PagedRequest = Bearer & {
  page?: number;
  size?: number;
  orgUnitId?: number;
  sort?: string;
};

type PagedResponse<T> = {
  data: T[];
  pageCount: number;
};

export const CLIENT_TOKEN = apiToken || "";

const defaultHeaders = (token: Token) => ({
  authorization: `Bearer ${token}`,
});

export const getLiveStreams = async (opts: {
  token: Token;
  latitude: number;
  longitude: number;
}): Promise<Channel.Channel[]> => {
  const { token, latitude, longitude } = opts;
  const { data } = await axios.get(`/client/live/${latitude}/${longitude}`, {
    headers: defaultHeaders(token),
  });
  return data;
};

export const getClientCategories = async (params: {
  token: Token;
  page?: number;
  size?: number;
}): Promise<PagedResponse<Categories.ClientCategory>> => {
  const { token, page = 0, size = PAGE_SIZE } = params;
  const { data, headers } = await axios.get<Categories.ClientCategory[]>(
    "/client/categories",
    {
      headers: defaultHeaders(token),
      params: { page, size },
    }
  );
  const pageCount = Number(headers.pagecount);
  return {
    data,
    pageCount,
  };
};

export const getClientCategory = async ({
  page,
  size,
  id,
  token,
}: PagedRequest & { id: number }) => {
  const { data, headers } = await axios.get<Categories.CategoryItem[]>(
    `/client/tag/${id}`,
    {
      headers: defaultHeaders(token),
      params: { page, size },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const searchCategoryItems = async ({
  page,
  size,
  search,
  token,
}: PagedRequest & { search: string }) => {
  const { data, headers } = await axios.get<Categories.CategoryItem[]>(
    `/client/search/${encodeURIComponent(search)}`,
    {
      headers: defaultHeaders(token),
      params: { page, size },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const login = async (
  params: UserTypes.LoginCredentials
): Promise<{ token: string; user: UserTypes.UserComplete }> => {
  try {
    const { data } = await axios.post("/user/login", {
      ...params,
      clientToken: CLIENT_TOKEN,
    });
    return data;
  } catch (e) {
    const error: AxiosError = e;
    if (error.response?.status === 401) {
      throw new Error("Wrong username or password");
    }
    throw e;
  }
};

export const searchUsers = async ({
  token,
  query,
}: PagedRequest & {
  query: string;
}): Promise<PagedResponse<UserTypes.UserComplete>> => {
  const { data, headers } = await axios.get<UserTypes.UserComplete[]>(
    `/user/search`,
    {
      params: { q: query },
      headers: defaultHeaders(token),
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const createUser = async ({
  token,
  user,
}: Bearer & {
  user: UserTypes.UserRequest;
}): Promise<UserTypes.UserComplete> => {
  const { data } = await axios.post("/user", user, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const getTags = async ({
  token,
  page = 0,
  orgUnitId,
  size = PAGE_SIZE,
}: PagedRequest): Promise<PagedResponse<Tags.Tag>> => {
  const { data, headers } = await axios.get<Tags.Tag[]>("/tag", {
    headers: defaultHeaders(token),
    params: { page, size, orgUnitId },
  });
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const getAnalyticsDashboard = async ({
  token,
  orgUnitId,
}: PagedRequest): Promise<Analytics.AnalyticsDashboardResponse> => {
  const { data } = await axios.get<Analytics.AnalyticsDashboardResponse>(
    "/analytics/dashboard/" + orgUnitId,
    {
      headers: defaultHeaders(token),
    }
  );
  return data;
};

export const updateTag = async ({
  token,
  tag,
}: Bearer & { tag: Tags.Tag }): Promise<Tags.Tag> => {
  const { id } = tag;
  const { data } = await axios.put(`/tag/${id}`, tag, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteTag = async ({ token, id }: Bearer & { id: number }) => {
  await axios.delete(`/tag/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

export const createTag = async ({
  token,
  tag,
}: Bearer & { tag: Tags.Tag }): Promise<Tags.Tag> => {
  const { data } = await axios.post("/tag", tag, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const getOrgUnits = async ({
  token,
  page = 0,
  size = PAGE_SIZE,
  orgUnitId,
}: PagedRequest): Promise<PagedResponse<OrgUnits.OrgUnit>> => {
  const { data, headers } = await axios.get<OrgUnits.OrgUnit[]>("/orgunit", {
    headers: defaultHeaders(token),
    params: { page, size, orgUnitId },
  });
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const getTagTypes = async ({
  token,
  page = 0,
  size = PAGE_SIZE,
  orgUnitId,
}: PagedRequest): Promise<PagedResponse<Tags.TagType>> => {
  const { data, headers } = await axios.get<Tags.TagType[]>("/tagtype", {
    headers: defaultHeaders(token),
    params: { page, size, orgUnitId },
  });
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const getCategories = async ({
  token,
  page = 0,
  size = PAGE_SIZE,
  orgUnitId,
}: PagedRequest): Promise<PagedResponse<Categories.Category>> => {
  const { data, headers } = await axios.get<Categories.Category[]>(
    "/displaycategory",
    {
      headers: defaultHeaders(token),
      params: { page, size, orgUnitId },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const updateCategory = async ({
  token,
  category,
}: Bearer & {
  category: Categories.Category;
}): Promise<Categories.Category> => {
  const { data } = await axios.put(
    `/displaycategory/${category.id}`,
    category,
    {
      headers: defaultHeaders(token),
      cancelToken: cancelToken(),
    }
  );
  return data;
};

export const createCategory = async ({
  token,
  category,
}: Bearer & {
  category: Categories.Category;
}): Promise<Categories.Category> => {
  const { data } = await axios.post(`/displaycategory`, category, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteCategory = async ({
  token,
  id,
}: Bearer & { id: number }): Promise<number> => {
  await axios.delete(`/displaycategory/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

export const getCategoryAssets = async ({
  id,
  token,
  page = 0,
  size = PAGE_SIZE,
  orgUnitId,
}: PagedRequest & { id: number }): Promise<Assets.CategoryItem[]> => {
  const { data } = await axios.get(`/client/tag/${id}`, {
    headers: defaultHeaders(token),
    params: { page, size, orgUnitId },
  });
  return data;
};

export const postAsset = async ({
  formData,
  token,
}: Bearer & { formData: FormData }): Promise<Assets.Asset> => {
  const { data } = await axios.post("/asset", formData, {
    headers: {
      ...defaultHeaders(token),
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const getAssets = async ({
  token,
  contentType,
  page = 0,
  size = PAGE_SIZE,
  orgUnitId,
  sort = "id desc",
}: PagedRequest & { contentType?: string }): Promise<
  PagedResponse<Assets.Asset>
> => {
  const { data, headers } = await axios.get<Assets.Asset[]>("/asset", {
    headers: defaultHeaders(token),
    params: { page, size, orgUnitId, contentType, sort },
  });
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_asset_search__orgUnit___search_
export const searchAssets = async ({
  token,
  contentType,
  page = 0,
  size = PAGE_SIZE,
  orgUnitId,
  searchQuery,
  sort = "id desc",
}: PagedRequest & { contentType?: string; searchQuery: string }): Promise<
  PagedResponse<Assets.Asset>
> => {
  const { data, headers } = await axios.get<Assets.Asset[]>(
    `/asset/search/${orgUnitId}/${encodeURIComponent(searchQuery)}`,
    {
      headers: defaultHeaders(token),
      params: { page, size, contentType, sort },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const getSingleAsset = async ({
  token,
  id,
}: Bearer & { id: number }): Promise<Assets.Asset> => {
  const { data } = await axios.get(`/asset/${id}`, {
    headers: defaultHeaders(token),
  });
  return data;
};

export const getAssetChildren = async ({
  token,
  id,
}: Bearer & { id: number }): Promise<Assets.Asset[]> => {
  const { data } = await axios.get(`/asset/children/${id}`, {
    headers: defaultHeaders(token),
  });
  return data;
};

export const updateAsset = async ({
  item,
  token,
}: Bearer & { item: Assets.Asset }): Promise<Assets.Asset> => {
  const { data } = await axios.put(`/asset/${item.id}`, item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteAsset = async ({
  id,
  token,
}: Bearer & { id: number }): Promise<number> => {
  await axios.delete(`/asset/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

export const getSources = async ({
  token,
  page = 0,
  size,
  orgUnitId,
}: PagedRequest): Promise<PagedResponse<Sources.InputSource>> => {
  const { data, headers } = await axios.get<Sources.InputSource[]>(
    "/inputsource",
    {
      headers: defaultHeaders(token),
      params: { page, size, orgUnitId },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const createSource = async ({
  token,
  source,
}: Bearer & { source: Sources.InputSource }): Promise<Sources.InputSource> => {
  const { data } = await axios.post("/inputsource", source, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateSource = async ({
  token,
  source,
}: Bearer & { source: Sources.InputSource }): Promise<Sources.InputSource> => {
  const { data } = await axios.put(`/inputsource/${source.id}`, source, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteSource = async ({ token, id }: Bearer & { id: number }) => {
  await axios.delete(`/inputsource/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_liveSource
export const getLiveSources = async ({
  token,
  orgUnitId,
  sort = "sequence",
}: PagedRequest): Promise<PagedResponse<LiveSources.LiveSource>> => {
  const { data, headers } = await axios.get<LiveSources.LiveSource[]>(
    "/liveSource",
    {
      headers: defaultHeaders(token),
      params: { orgUnitId, sort },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const createLiveSource = async ({
  token,
  source,
}: Bearer & {
  source: LiveSources.LiveSource;
}): Promise<LiveSources.LiveSource> => {
  const { data } = await axios.post("/liveSource", source, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateLiveSource = async ({
  token,
  source,
}: Bearer & {
  source: LiveSources.LiveSource;
}): Promise<LiveSources.LiveSource> => {
  const { data } = await axios.put(`/liveSource/${source.id}`, source, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteLiveSource = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/liveSource/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_remoteagent
export const getRemoteAgents = async ({
  token,
  orgUnitId,
  sort = "id desc",
}: PagedRequest): Promise<PagedResponse<RemoteAgents.RemoteAgent>> => {
  const { data, headers } = await axios.get<RemoteAgents.RemoteAgent[]>(
    "/remoteagent",
    {
      headers: defaultHeaders(token),
      params: { orgUnitId, sort },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const getRemoteAgentItem = async ({
  token,
  id,
}: Bearer & {
  id: number;
}): Promise<RemoteAgents.RemoteAgent> => {
  const { data } = await axios.get<RemoteAgents.RemoteAgent>(
    `/remoteagent/${id}`,
    {
      headers: defaultHeaders(token),
    }
  );
  return data;
};

export const createRemoteAgent = async ({
  token,
  item,
}: Bearer & {
  item: RemoteAgents.RemoteAgent;
}): Promise<RemoteAgents.RemoteAgent> => {
  const { data } = await axios.post("/remoteagent", item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateRemoteAgent = async ({
  token,
  item,
}: Bearer & {
  item: RemoteAgents.RemoteAgent;
}): Promise<RemoteAgents.RemoteAgent> => {
  const { data } = await axios.put(`/remoteagent/${item.id}`, item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteRemoteAgent = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/remoteagent/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_remoteagentchannel
export const getRemoteChannels = async ({
  token,
  orgUnitId,
  sort = "id desc",
}: PagedRequest): Promise<PagedResponse<RemoteChannels.RemoteChannel>> => {
  const { data, headers } = await axios.get<RemoteChannels.RemoteChannel[]>(
    "/remoteagentchannel",
    {
      headers: defaultHeaders(token),
      params: { orgUnitId, sort },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const createRemoteChannel = async ({
  token,
  item,
}: Bearer & {
  item: RemoteChannels.RemoteChannel;
}): Promise<RemoteChannels.RemoteChannel> => {
  const { data } = await axios.post("/remoteagentchannel", item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateRemoteChannel = async ({
  token,
  item,
}: Bearer & {
  item: RemoteChannels.RemoteChannel;
}): Promise<RemoteChannels.RemoteChannel> => {
  const { data } = await axios.put(`/remoteagentchannel/${item.id}`, item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteRemoteChannel = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/remoteagentchannel/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_remoteagentchannelschedule
export const getRemoteSchedules = async ({
  token,
  orgUnitId,
  sort = "id desc",
}: PagedRequest): Promise<PagedResponse<RemoteSchedules.RemoteSchedule>> => {
  const { data, headers } = await axios.get<RemoteSchedules.RemoteSchedule[]>(
    "/remoteagentchannelschedule",
    {
      headers: defaultHeaders(token),
      params: { orgUnitId, sort },
    }
  );
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const createRemoteSchedule = async ({
  token,
  item,
}: Bearer & {
  item: RemoteSchedules.RemoteSchedule;
}): Promise<RemoteSchedules.RemoteSchedule> => {
  const { data } = await axios.post("/remoteagentchannelschedule", item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateRemoteSchedule = async ({
  token,
  item,
}: Bearer & {
  item: RemoteSchedules.RemoteSchedule;
}): Promise<RemoteSchedules.RemoteSchedule> => {
  const { data } = await axios.put(
    `/remoteagentchannelschedule/${item.id}`,
    item,
    {
      headers: defaultHeaders(token),
      cancelToken: cancelToken(),
    }
  );
  return data;
};

export const deleteRemoteSchedule = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/remoteagentchannelschedule/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_transcodeaudio
export const getRemoteTranscodeAudios = async ({
  token,
  orgUnitId,
  sort = "id desc",
}: PagedRequest): Promise<
  PagedResponse<RemoteTranscodeAudios.RemoteTranscodeAudio>
> => {
  const { data, headers } = await axios.get<
    RemoteTranscodeAudios.RemoteTranscodeAudio[]
  >("/transcodeaudio", {
    headers: defaultHeaders(token),
    params: { orgUnitId, sort },
  });
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const getRemoteTranscodeAudioItem = async ({
  token,
  id,
}: Bearer & {
  id: number;
}): Promise<RemoteTranscodeAudios.RemoteTranscodeAudio> => {
  const { data } = await axios.get<RemoteTranscodeAudios.RemoteTranscodeAudio>(
    `/transcodeaudio/${id}`,
    {
      headers: defaultHeaders(token),
    }
  );
  return data;
};

export const createRemoteTranscodeAudio = async ({
  token,
  item,
}: Bearer & {
  item: RemoteTranscodeAudios.RemoteTranscodeAudio;
}): Promise<RemoteTranscodeAudios.RemoteTranscodeAudio> => {
  const { data } = await axios.post("/transcodeaudio", item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateRemoteTranscodeAudio = async ({
  token,
  item,
}: Bearer & {
  item: RemoteTranscodeAudios.RemoteTranscodeAudio;
}): Promise<RemoteTranscodeAudios.RemoteTranscodeAudio> => {
  const { data } = await axios.put(`/transcodeaudio/${item.id}`, item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteRemoteTranscodeAudio = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/transcodeaudio/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://api.jaybirdtv.com/q/swagger-ui/#/default/get_transcodevideo
export const getRemoteTranscodeVideoItem = async ({
  token,
  id,
}: Bearer & {
  id: number;
}): Promise<RemoteTranscodeVideos.RemoteTranscodeVideo> => {
  const { data } = await axios.get<RemoteTranscodeVideos.RemoteTranscodeVideo>(
    `/transcodevideo/${id}`,
    {
      headers: defaultHeaders(token),
    }
  );
  return data;
};

export const createRemoteTranscodeVideo = async ({
  token,
  item,
}: Bearer & {
  item: RemoteTranscodeVideos.RemoteTranscodeVideo;
}): Promise<RemoteTranscodeVideos.RemoteTranscodeVideo> => {
  const { data } = await axios.post("/transcodevideo", item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateRemoteTranscodeVideo = async ({
  token,
  item,
}: Bearer & {
  item: RemoteTranscodeVideos.RemoteTranscodeVideo;
}): Promise<RemoteTranscodeVideos.RemoteTranscodeVideo> => {
  const { data } = await axios.put(`/transcodevideo/${item.id}`, item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteRemoteTranscodeVideo = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/transcodevideo/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

// https://stgapi.jaybirdtv.com/q/swagger-ui/#/default/get_transcodeprofile
export const getRemoteTranscodeProfiles = async ({
  token,
  orgUnitId,
  sort = "id desc",
}: PagedRequest): Promise<
  PagedResponse<RemoteTranscodeProfiles.RemoteTranscodeProfile>
> => {
  const { data, headers } = await axios.get<
    RemoteTranscodeProfiles.RemoteTranscodeProfile[]
  >("/transcodeprofile", {
    headers: defaultHeaders(token),
    params: { orgUnitId, sort },
  });
  const pageCount = Number(headers.pagecount);
  return { data, pageCount };
};

export const createRemoteTranscodeProfile = async ({
  token,
  item,
}: Bearer & {
  item: RemoteTranscodeProfiles.RemoteTranscodeProfile;
}): Promise<RemoteTranscodeProfiles.RemoteTranscodeProfile> => {
  const { data } = await axios.post("/transcodeprofile", item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const updateRemoteTranscodeProfile = async ({
  token,
  item,
}: Bearer & {
  item: RemoteTranscodeProfiles.RemoteTranscodeProfile;
}): Promise<RemoteTranscodeProfiles.RemoteTranscodeProfile> => {
  const { data } = await axios.put(`/transcodeprofile/${item.id}`, item, {
    headers: defaultHeaders(token),
    cancelToken: cancelToken(),
  });
  return data;
};

export const deleteRemoteTranscodeProfile = async ({
  token,
  id,
}: Bearer & { id: number }) => {
  await axios.delete(`/transcodeprofile/${id}`, {
    headers: defaultHeaders(token),
  });
  return id;
};

export const getSettings = async ({
  token,
}: Bearer): Promise<Settings.Settings> => {
  const { data } = await axios.get("/orgunit/settings", {
    headers: defaultHeaders(token),
  });
  return data;
};

export const getOrgUnitSettings = async ({
  orgUnitId,
  token,
}: Bearer & { orgUnitId: number }): Promise<Settings.Settings> => {
  const { data } = await axios.get(`/orgunit/settings/${orgUnitId}`, {
    headers: defaultHeaders(token),
  });
  return data;
};

export const putOrgUnitSettings = async ({
  token,
  settings,
}: Bearer & {
  settings: Settings.SettingsRequest;
}): Promise<Settings.Settings> => {
  const { data } = await axios.put(
    `/orgunit/settings/${settings.orgUnitId}`,
    settings,
    {
      headers: defaultHeaders(token),
    }
  );
  return data;
};

export const forgotPasswordRequest = async ({ email }: { email: string }) => {
  await axios.get(`/user/reset/${email}`, {
    headers: defaultHeaders(CLIENT_TOKEN),
  });
};

export const changePassword = (body: { code: string; password: string }) => {
  return axios.post("/user/password", body, {
    headers: defaultHeaders(CLIENT_TOKEN),
  });
};

export const getActivationCode = async () => {
  const { data } = await axios.post<{ code: string }>(
    "/activate",
    {},
    { headers: defaultHeaders(CLIENT_TOKEN) }
  );
  return data.code;
};

export const checkCodeValidation = async (code: string) => {
  const { data } = await axios.get<{
    token: string;
    user: UserTypes.UserComplete;
  }>(`/activate/${code}`);
  return data;
};

export const activate = async ({ code, token }: Bearer & { code: string }) => {
  const { data } = await axios.get<{ validated: boolean }>(
    `/activate/validate/${code}`,
    {
      headers: defaultHeaders(token),
    }
  );
  return data.validated;
};
