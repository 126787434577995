import { ItemComponentType } from "./types";

const Item: ItemComponentType = ({ data }) => {
  return (
    <div className="resource-table-wrapper remote-channels-field-item">
      <div className="inline-wrapper">
        <span>Id</span>
        <p>{data.id}</p>
      </div>
      <div className="block-wrapper">
        <span>Name</span>
        <p>{data.name}</p>
      </div>
    </div>
  );
};

export default Item;
