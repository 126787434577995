import { FC } from "react";
import "./table-options.scss";

const PageWrapper: FC<any> = ({ children, ...props }) => (
  <div className="table-options" {...props}>
    {children}
  </div>
);

export default PageWrapper;
