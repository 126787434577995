const CONSTANTS = {
  labels: {
    singular: "Schedule",
    plural: "Schedules",
  },
  path: "schedules",
  className: "remote-schedules-field",
};

export default CONSTANTS;
