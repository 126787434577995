import { memo, FC } from "react";

const ChannelLockedIcon: FC = memo(() => (
  <svg width="13.06" height="15.68" viewBox="0 0 13.06 15.68">
    <path
      fill="#eddc87"
      d="M1.63 5.56h1.31a.29.29 0 0 0 .33-.3A3.29 3.29 0 0 1 6.76 2a3.36 3.36 0 0 1 3 3.4v-.1a.28.28 0 0 0 .32.3h1.31a.29.29 0 0 0 .33-.3A5.24 5.24 0 0 0 6.27 0a5.3 5.3 0 0 0-5 5.25.34.34 0 0 0 .36.31zm11.43 2.61a1.31 1.31 0 0 0-1.3-1.31H1.31A1.31 1.31 0 0 0 0 8.17v6.2a1.31 1.31 0 0 0 1.31 1.31h10.45a1.31 1.31 0 0 0 1.3-1.31zM8 13.29a.34.34 0 0 1-.32.43H5.32a.34.34 0 0 1-.32-.43l.58-1.95a1.58 1.58 0 0 1-.68-1.57 1.64 1.64 0 0 1 3.23.29 1.51 1.51 0 0 1-.69 1.28z"
    />
  </svg>
));

export default ChannelLockedIcon;
