import { useEffect, useMemo, FC } from "react";
import cn from "classnames";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import Select from "react-select";
import {
  getOrgUnitList,
  selectOrgUnitsList,
  selectIsLoading,
} from "pages/admin/settings/orgUnitSlice";
import { OrgUnits } from "common/types";

import "./select.scss";

type PropTypes = {
  className?: string;
  onChange: (orgUnit: OrgUnits.OrgUnitSimple | undefined) => void;
  value?: number; // OrgUnits.OrgUnitSimple;
};

const SelectOrgUnit: FC<PropTypes> = ({ onChange, value, className = "" }) => {
  const orgUnits = useAdminSelector(selectOrgUnitsList);
  const isLoading = useAdminSelector(selectIsLoading);
  const dispatch = useAdminDispatch();
  useEffect(() => {
    if (orgUnits.length === 0) {
      dispatch(getOrgUnitList());
    }
  }, [orgUnits, dispatch]);

  const options = useMemo(
    () => orgUnits.map(({ id, name }) => ({ value: id, label: name })),
    [orgUnits]
  );

  const selectorValue = useMemo(
    () => options.find((opt) => opt.value === value),
    [options, value]
  );

  return (
    <Select
      className={cn("select", className)}
      classNamePrefix="custom"
      options={options}
      value={selectorValue}
      isLoading={isLoading}
      onChange={(val) => {
        if (val) {
          const orgUnit = orgUnits.find(({ id }) => id === val.value);
          onChange(
            orgUnit && {
              id: orgUnit.id,
              name: orgUnit.name,
            }
          );
        }
      }}
    />
  );
};

export default SelectOrgUnit;
