const CONSTANTS = {
  labels: {
    singular: "Transcode Video",
    plural: "Transcode Videos",
  },
  path: "transcode-video",
  className: "remote-transcode-videos-field",
};

export default CONSTANTS;
