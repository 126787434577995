import Field from "./Field";
import Connector from "./Connector";
import Editor from "./Editor";
import * as slice from "./slice";
export * as Types from "./types";

const RemoteTranscodeProfilesField = {
  Field,
  Connector,
  Editor,
  slice,
};

export default RemoteTranscodeProfilesField;
