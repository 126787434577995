import cn from "classnames";
import { FC } from "react";

type PropTypes = {
  className?: string;
};

const ItemsListWrapper: FC<PropTypes> = ({ className, children }) => {
  return <ul className={cn("resource-items-list", className)}>{children}</ul>;
};

export default ItemsListWrapper;
