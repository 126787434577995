import styled from "styled-components";
import type { Settings } from "common/types";
import { darken, rgba } from "polished";

export const MainNavStyled = styled.div`
  &&&&:before {
    background: linear-gradient(
      0deg,
      ${({ theme }: { theme: Settings.Settings }) => theme.backgroundColor},
      ${({ theme }: { theme: Settings.Settings }) =>
        darken(0.05, theme.backgroundColor!)}
    );
  }

  &&& .nav-item .nav-item-container {
    color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.secondaryFontColor};
  }

  &&& .nav-item.active .nav-item-container {
    background-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentBackgroundColor};
    color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
  }

  &&& .nav-item.active .nav-item-container:hover {
    background-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentBackgroundColor};
  }

  &&& .nav-item .nav-item-container:hover {
    background-color: ${({ theme }: { theme: Settings.Settings }) =>
      rgba(theme.accentBackgroundColor!, 0.3)};
    //color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.mainFontColor};
  }

  &&& .nav-item.nav-item-dropdown .nav-item-container,
  &&& .nav-item.nav-item-dropdown.active .nav-item-container,
  &&& .nav-item.nav-item-dropdown.active:hover .nav-item-container {
    background: transparent;
    color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.secondaryFontColor};
  }

  &&& .nav-item.nav-item-dropdown .nav .nav-item-container {
    color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.secondaryFontColor};
  }

  &&& .nav-item.nav-item-dropdown.active .nav-item:hover .nav-item-container {
    background-color: ${({ theme }: { theme: Settings.Settings }) =>
      rgba(theme.accentBackgroundColor!, 0.3)};
  }

  &&& .nav-item.nav-item-dropdown.active .nav-item.active .nav-item-container,
  &&& .nav-item.nav-item-dropdown.active .nav-item.active:hover .nav-item-container {
    background-color: ${({ theme }: { theme: Settings.Settings }) =>
      theme.accentBackgroundColor};
    color: ${({ theme }: { theme: Settings.Settings }) => theme.mainFontColor};
  }

  &&& .main-nav-icon {
    &:before, &:after, div  {
      background-color: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryFontColor};
      );
    }
    &:hover  {
      &:before, &:after, div  {
        background-color: ${({ theme }: { theme: Settings.Settings }) =>
          darken(0.1, theme.secondaryFontColor!)};
      }
    }
  }

  &&& .nav-item .nav-item-container .nav-icon {
    .stroke {
      stroke: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryFontColor};
    }

    .fill {
      fill: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryFontColor};
    }
  }

  &&& .nav-item.active .nav-item-container .nav-icon,
  &&& .nav-item .nav-item-container:hover .nav-icon {
    .stroke {
      stroke: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
    }

    .fill {
      fill: ${({ theme }: { theme: Settings.Settings }) => theme.accentColor};
    }
  }

  &&& .nav-item.nav-item-dropdown.active .nav-item-container .nav-icon,
  &&& .nav-item.nav-item-dropdown .nav-item-container:hover .nav-icon {
    .stroke {
      stroke: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryFontColor};
    }

    .fill {
      fill: ${({ theme }: { theme: Settings.Settings }) =>
        theme.secondaryFontColor};
    }
  }

  &&&:not(.active) {
    @media (min-width: 481px) and (max-width: 1199px) {
      .nav-item.nav-item-dropdown.active .nav-item-container,
      .nav-item.nav-item-dropdown.active:hover .nav-item-container {
        background-color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.accentBackgroundColor};
        color: ${({ theme }: { theme: Settings.Settings }) =>
          theme.mainFontColor};
      }


      .nav-item.nav-item-dropdown.active .nav-item-container .nav-icon,
      .nav-item.nav-item-dropdown .nav-item-container:hover .nav-icon {
        .stroke {
          stroke: ${({ theme }: { theme: Settings.Settings }) =>
            theme.accentColor};
        }

        .fill {
          fill: ${({ theme }: { theme: Settings.Settings }) =>
            theme.accentColor};
        }
      }
    }
  }
`;

export const ModalWrapperStyled = styled.div`
  &&&&.visible:before {
    background-color: ${({ theme }: { theme: Settings.Settings }) =>
      darken(0.1, theme.backgroundColor!)};
  }

  &&& .modal {
    box-shadow: -0.5rem 0 1rem 1px
      ${({ theme }: { theme: Settings.Settings }) =>
        rgba(darken(0.4, theme.backgroundColor!), 0.3)};
  }
`;
