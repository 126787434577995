import { useEffect } from "react";
import cn from "classnames";

import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import { getItem, selectItem, selectIsLoading, selectError } from "./slice";
import { ResourceField, ErrorMessage, LoadingIndicator } from "components";
import Item from "./Item";
import CONSTANTS from "./constants";

type PropTypes = {
  className?: string;
  path?: string;
  label?: string;
  value?: number;
};

const Field = ({
  className,
  path = CONSTANTS.path,
  label = CONSTANTS.labels.singular,
  value,
}: PropTypes) => {
  const dispatch = useAdminDispatch();

  const item = useAdminSelector(selectItem);

  const isLoading = useAdminSelector(selectIsLoading);

  const error = useAdminSelector(selectError);

  useEffect(() => {
    if (value && value !== item?.id) {
      dispatch(getItem(value));
    }
  }, [dispatch, value, item]);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <ResourceField.Field
        className={cn(CONSTANTS.className, className)}
        path={path}
        label={label}
        value={value && item ? [item] : []}
        Item={Item}
      />
      {error && <ErrorMessage error={error} />}
    </>
  );
};

export default Field;
