import ImageBlock from "./ImageBlock";
import { ItemComponentType } from "./types";

const Item: ItemComponentType = ({ data }) => {
  return (
    <div className="assets-field-item">
      <ImageBlock url={data.thumbnailUrl} name={data.name} />
    </div>
  );
};

export default Item;
