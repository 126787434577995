import { FC } from "react";
import cn from "classnames";

import "./sub-rows-wrapper.scss";

type PropTypes = {
  className?: string;
};

const SubRowsWrapper: FC<PropTypes> = ({ children, className }) => (
  <div className={cn("sub-rows-wrapper", className)}>{children}</div>
);

export default SubRowsWrapper;
