import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { Sources } from "common/types";
import { selectToken, setUserData } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import { produce } from "immer";
import * as api from "common/api";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";

type SourcesState = SliceBase<Sources.InputSource>;

const initialState: SourcesState = {
  data: [],
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getSourcesList = createAsyncThunk(
  "sources/getList",
  async ({ page, size }: { page: number; size: number }, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    );
    return api.getSources({ token, orgUnitId, page, size });
  }
);

export const toggleSourceActive = createAsyncThunk(
  "source/toggleActive",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const source = selectSourcesList(getState() as AdminRootState).find(
      (s) => s.id === id
    )!;

    return api.updateSource({
      token,
      source: produce(source, (draft) => {
        draft.active = !draft.active;
      }),
    });
  }
);

export const createSource = createAsyncThunk(
  "sources/create",
  (source: Sources.InputSource, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createSource({ token, source });
  }
);

export const updateSource = createAsyncThunk(
  "sources/update",
  async (source: Sources.InputSource, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateSource({ token, source });
  }
);

export const deleteSource = createAsyncThunk(
  "sources/delete",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.deleteSource({ token, id });
  }
);

const sourcesSlice = createSlice({
  name: "sourcesReducer",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getSourcesList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSourcesList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getSourcesList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(createSource.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSource.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createSource.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(updateSource.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSource.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const idx = state.data.findIndex((src) => src.id === payload.id);
        if (idx >= 0) state.data[idx] = payload;
      })
      .addCase(updateSource.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(deleteSource.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSource.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter((source) => source.id !== payload);
      })
      .addCase(deleteSource.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(toggleSourceActive.fulfilled, (state, { payload }) => {
        const itemIdx = state.data.findIndex((item) => item.id === payload.id);
        if (itemIdx >= 0) {
          state.data[itemIdx] = payload;
        }
      })
      .addCase(toggleSourceActive.rejected, (state, { error }) => {
        state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { clearError } = sourcesSlice.actions;

const sliceSelector = (state: AdminRootState) => state.sources;

export const selectSourcesList = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);

export default sourcesSlice.reducer;
