import { FC, useCallback, useState } from "react";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import {
  UsersIcon,
  TagsIcon,
  CategoriesIcon,
  SourcesIcon,
  LiveSourcesIcon,
  AgentsIcon,
  SettingsIcon,
  BurgerIcon,
  AssetsIcon,
  AnalyticsIcon,
} from "./Icons";
import NavItem from "./NavItem";
import NavItemDropdown from "./NavItemDropdown";
import NavBar from "./NavBar";
import { MainNavStyled } from "components/styled-blocks";
import { useTheme } from "styled-components";
import { Settings } from "common/types";
import cn from "classnames";

import "./main-nav.scss";

const MainNav: FC = () => {
  const theme = useTheme() as Settings.Settings;

  const { url } = useRouteMatch();

  const { pathname } = useLocation();

  const [active, setActive] = useState(false);

  const toggleMenu = useCallback(() => {
    setActive((x) => !x);
  }, []);

  const closeMenu = useCallback(() => setActive(false), []);

  return (
    <MainNavStyled className={cn("main-nav", { active })}>
      <Link to="/" className="logo" onClick={closeMenu}>
        <img src={theme.logoAsset} alt="logo" />
      </Link>
      <BurgerIcon toggleMenu={toggleMenu} />
      <NavBar>
        <NavItem to={url + "/users"} icon={<UsersIcon />} onClick={closeMenu}>
          Users
        </NavItem>
        <NavItem to={url + "/tags"} icon={<TagsIcon />} onClick={closeMenu}>
          Tags
        </NavItem>
        <NavItem
          to={url + "/categories"}
          icon={<CategoriesIcon />}
          onClick={closeMenu}
        >
          Categories
        </NavItem>
        <NavItem
          to={url + "/sources"}
          icon={<SourcesIcon />}
          onClick={closeMenu}
        >
          Sources
        </NavItem>
        <NavItem
          to={url + "/live-sources"}
          icon={<LiveSourcesIcon />}
          onClick={closeMenu}
        >
          Live Sources
        </NavItem>
        <NavItemDropdown
          label="Remote Agents"
          defaultActive={pathname.startsWith("/admin/remote-agents")}
          icon={<AgentsIcon />}
        >
          <NavBar>
            <NavItem to={url + "/remote-agents/agents"} onClick={closeMenu}>
              Agents
            </NavItem>
            <NavItem
              to={url + "/remote-agents/transcode-profiles"}
              onClick={closeMenu}
            >
              Transcode Profiles
            </NavItem>
          </NavBar>
        </NavItemDropdown>
        <NavItem to={url + "/assets"} icon={<AssetsIcon />} onClick={closeMenu}>
          Assets
        </NavItem>
        <NavItem
          to={url + "/settings"}
          icon={<SettingsIcon />}
          onClick={closeMenu}
        >
          Settings
        </NavItem>
        <NavItem
          to={url + "/analytics"}
          icon={<AnalyticsIcon />}
          onClick={closeMenu}
        >
          Analytics
        </NavItem>
      </NavBar>
    </MainNavStyled>
  );
};
export default MainNav;
