import Select from "react-select";
import moment, { Moment } from "moment";
import Datetime from "react-datetime";

import { RemoteChannels } from "common/types";
import { ResourceType } from "./types";
import {
  EditorPage,
  FormGroup,
  Switcher,
  RemoteChannelsField,
  RemoteChannelsFieldTypes,
} from "components";
import { useCancellableSubmit } from "hooks";
import {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  useMemo,
} from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  createItem,
  selectError,
  selectIsLoading,
  selectItemsList,
  updateItem,
} from "./slice";

import "react-datetime/css/react-datetime.css";

const TIME_FORMAT = "HH:mm:SS";

const SOURCE_CHANNEL_CONFIG = {
  labels: {
    singular: "Source Channel",
    plural: "Source Channels",
  },
  path: "source-channel",
};

type PropTypes = {
  id: string;
  agentId: string;
  channelId: string;
  closePartsToSkip?: number;
  channelData?: RemoteChannels.RemoteChannel;
  scheduleData?: ResourceType;
  onSubmit?: (schedule: ResourceType) => void;
};

const Editor = ({
  id,
  agentId,
  channelId,
  closePartsToSkip,
  channelData,
  scheduleData,
  onSubmit,
}: PropTypes) => {
  const dispatch = useAdminDispatch();

  const [data, setData] = useState<ResourceType | undefined>();

  const items = useAdminSelector(selectItemsList);

  useEffect(() => {
    if (id === "new") {
      setData({
        id: -1,
        created: "",
        startTime: "00:00:00",
        endTime: "00:00:00",
        dayOfWeek: 1,
        live: false,
        recordingOffset: 0,
        sourceChannel: undefined,
        channel: channelData,
      });
    } else {
      setData(scheduleData || items.find((s) => s.id === +id));
    }
  }, [id, dispatch, items, scheduleData, channelData]);

  const isLoading = useAdminSelector(selectIsLoading);

  const toggleActive = useCallback(() => {
    setData((item) => item && { ...item, live: !item.live });
  }, []);

  const onStartTimeChange = useCallback((date: string | Moment) => {
    setData(
      (item) => item && { ...item, startTime: moment(date).format(TIME_FORMAT) }
    );
  }, []);

  const onEndTimeChange = useCallback((date: string | Moment) => {
    setData(
      (item) => item && { ...item, endTime: moment(date).format(TIME_FORMAT) }
    );
  }, []);

  const onDowChange = useCallback((option) => {
    setData((item) => item && { ...item, dayOfWeek: option.value });
  }, []);

  const onRecordingOffsetChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, recordingOffset: Number(value) });
    }, []);

  const onSourceChannelChange =
    useCallback<RemoteChannelsFieldTypes.OnSubmitType>((selected, next) => {
      setData((item) => item && { ...item, sourceChannel: selected[0] });

      next();
    }, []);

  const isSourceChannelDisabled =
    useCallback<RemoteChannelsFieldTypes.IsItemDisabledType>(
      (item) => {
        // source channel and channel must be different
        return `${item.id}` === channelId;
      },
      [channelId]
    );

  const invalid = useMemo(
    (): {
      [k in keyof ResourceType]?: boolean;
    } => ({
      startTime: !data?.startTime,
      endTime: !data?.endTime,
      dayOfWeek: !data?.dayOfWeek,
      sourceChannel: !data?.sourceChannel,
    }),
    [data]
  );

  const isValid = useMemo(
    () => Object.values(invalid).every((x) => !x),
    [invalid]
  );

  const onSubmitHandler = useCancellableSubmit({
    item: data,
    submittingAction: id === "new" ? createItem : updateItem,
    closePartsToSkip,
    onFinal: onSubmit,
  });

  const error = useAdminSelector(selectError);

  const dowOptions = useMemo(
    () =>
      Array.from({ length: 7 }).map((_, i) => ({
        value: i + 1,
        label: moment(i + 1, "E").format("dddd"),
      })),
    []
  );

  const selectorDowValue = useMemo(
    () => dowOptions.find((opt) => opt.value === data?.dayOfWeek),
    [dowOptions, data?.dayOfWeek]
  );

  const [showForm, setShowForm] = useState(true);

  return (
    <>
      <RemoteChannelsField.Connector
        agentId={agentId}
        onToggle={setShowForm}
        onSubmit={onSourceChannelChange}
        isItemDisabled={isSourceChannelDisabled}
        multiple={false}
        labels={SOURCE_CHANNEL_CONFIG.labels}
        path={SOURCE_CHANNEL_CONFIG.path}
      />
      {showForm && (
        <EditorPage
          closeIcon
          closePartsToSkip={closePartsToSkip}
          title={`${id === "new" ? "Create" : "Edit"} Remote Schedule`}
          breadcrumbs={[
            { title: "Agent", id: agentId },
            { title: "Channel", id: channelId },
            { title: "Schedule", id },
          ]}
          error={error}
          isLoading={isLoading}
          controls={{
            submitText: id === "new" ? "Create" : "Save",
            isValid,
            onSubmit: onSubmitHandler,
          }}
        >
          {data && (
            <>
              <Switcher
                value={!!data.live}
                onToggle={toggleActive}
                className="active-switcher"
              />
              <FormGroup label="Source Channel" invalid={invalid.sourceChannel}>
                <RemoteChannelsField.Field
                  path={SOURCE_CHANNEL_CONFIG.path}
                  label={SOURCE_CHANNEL_CONFIG.labels.singular}
                  value={data.sourceChannel && [data.sourceChannel]}
                />
              </FormGroup>
              <FormGroup label="Day of Week" invalid={invalid.dayOfWeek}>
                <Select
                  className="select"
                  classNamePrefix="custom"
                  options={dowOptions}
                  value={selectorDowValue}
                  onChange={onDowChange}
                />
              </FormGroup>
              <div className="form-row half">
                <FormGroup label="Start Time" invalid={invalid.startTime}>
                  <Datetime
                    value={moment(data.startTime, TIME_FORMAT)}
                    onChange={onStartTimeChange}
                    timeFormat={TIME_FORMAT}
                    dateFormat={false}
                  />
                </FormGroup>
                <FormGroup label="End Time" invalid={invalid.endTime}>
                  <Datetime
                    value={moment(data.endTime, TIME_FORMAT)}
                    onChange={onEndTimeChange}
                    timeFormat={TIME_FORMAT}
                    dateFormat={false}
                  />
                </FormGroup>
              </div>
              <FormGroup label="Recording Offset">
                <input
                  type="number"
                  value={data.recordingOffset}
                  onChange={onRecordingOffsetChange}
                />
              </FormGroup>
            </>
          )}
        </EditorPage>
      )}
    </>
  );
};

export default Editor;
