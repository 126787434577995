import { memo, FC, ReactNode, useState } from "react";
import cn from "classnames";

type PropTypes = {
  label?: ReactNode;
  defaultActive?: boolean;
  icon?: ReactNode;
};

const NavItemDropdown: FC<PropTypes> = memo(
  ({ label, defaultActive, icon, children }) => {
    const [active, setActive] = useState(defaultActive || false);

    const onClick = () => {
      setActive((c) => !c);
    };

    return (
      <li
        className={cn("nav-item", "nav-item-dropdown", {
          active,
        })}
      >
        <div onClick={onClick} className="nav-item-container">
          <span className="nav-icon">{icon}</span>
          <span className="label">{label}</span>
        </div>
        {active && children}
      </li>
    );
  }
);

export default NavItemDropdown;
