import { Link } from "react-router-dom";
import cn from "classnames";

type CallablePath = (id: number) => string;

export type PropTypes = {
  className?: string;
  label?: string;
  path: string | CallablePath;
};

const EditAction = ({
  className,
  id,
  label = "edit",
  path,
}: PropTypes & { id: number }) => {
  return (
    <span className={cn("edit-action", "center", className)}>
      <Link
        className="edit-btn"
        to={`${typeof path === "function" ? path(id) : path}/${id}`}
      >
        {label}
      </Link>
    </span>
  );
};

const EditColumn = (props: PropTypes) => {
  return {
    compact: true,
    name: "",
    width: "14rem",
    cell: ({ id }: { id: number; [key: string]: any }) => (
      <EditAction id={id} {...props} />
    ),
  };
};

export default EditColumn;
