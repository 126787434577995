import { useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import cn from "classnames";

import ItemsListWrapper from "./ItemsListWrapper";
import {
  ItemComponentType,
  ResourceRequiredFieldsType,
  OnDeleteType,
} from "./types";

type ActionType = {
  id: number;
  url: string;
  path: string;
};

const EditAction = ({ id, url, path }: ActionType) => {
  return (
    <Link className="edit-action" to={`${url}/${path}/${id}`}>
      Edit
    </Link>
  );
};

const DeleteAction = ({
  id,
  url,
  path,
  onClick,
}: ActionType & { onClick?: OnDeleteType }) => {
  const onClickCallback = useCallback(
    (e) => {
      e.preventDefault();

      onClick && onClick(id);
    },
    [id, onClick]
  );

  return (
    <Link
      onClick={onClickCallback}
      className="delete-action"
      to={`${url}/${path}/${id}/delete`}
    >
      Delete
    </Link>
  );
};

type ActionsRowType = {
  onDelete?: OnDeleteType;
  editable?: boolean;
} & ActionType;

const ActionsRow = ({ id, url, path, editable, onDelete }: ActionsRowType) => {
  return (
    <div className="actions-row">
      {editable && <EditAction id={id} url={url} path={path} />}
      {onDelete && (
        <DeleteAction id={id} url={url} path={path} onClick={onDelete} />
      )}
    </div>
  );
};

type ItemsListPropTypes<RT> = {
  value?: RT[];
  Item: ItemComponentType<RT>;
} & Omit<ActionsRowType, "id" | "url">;

const ItemsList = <RT extends ResourceRequiredFieldsType>({
  path,
  value,
  Item,
  editable,
  onDelete,
}: ItemsListPropTypes<RT>) => {
  const { url } = useRouteMatch();

  return value && value.length ? (
    <ItemsListWrapper
      className={cn("resource-items-list", { editable, deletable: onDelete })}
    >
      {value.map((item) => (
        <li key={item.id}>
          <Item data={item} />
          {editable || onDelete ? (
            <ActionsRow
              id={item.id}
              url={url}
              path={path}
              editable={editable}
              onDelete={onDelete}
            />
          ) : null}
        </li>
      ))}
    </ItemsListWrapper>
  ) : null;
};

export default ItemsList;
