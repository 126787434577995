import { FC, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import { getWebAppTheme, selectTheme, selectIsLoading } from "./slice";
import GlobalStyle from "./GlobalStyle";
import { LoadingIndicator } from "components";

const Theme: FC = ({ children }) => {
  const isLoading = useClientSelector(selectIsLoading);

  const dispatch = useClientDispatch();

  useEffect(() => {
    dispatch(getWebAppTheme());
  }, [dispatch]);

  const theme = useClientSelector(selectTheme);

  return isLoading ? (
    <div>
      <div className="center-wrapper">
        <LoadingIndicator />
      </div>
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
