import { FC } from "react";
import "./table-title.scss";

const TableTitle: FC<{ title: string; total?: number }> = ({
  title,
  total,
}) => (
  <div className="table-title">
    <h2>{title}</h2>
    {total !== undefined && <span className="total">{total} total</span>}
  </div>
);

export default TableTitle;
