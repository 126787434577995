import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import produce from "immer";
import { Tags } from "common/types";
import { ResourceType } from "./types";
import { selectToken, setUserData } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";

type AssetsState = SliceBase<ResourceType>;

const initialState: AssetsState & { isLoadingAttachPage: boolean } = {
  isLoading: false,
  isLoadingAttachPage: false,
  error: undefined,
  data: [],
  pageCount: 0,
};

export const getItemsList = createAsyncThunk(
  "assets/getList",
  (
    {
      page,
      size,
      contentType,
    }: { page: number; size: number; contentType?: string },
    { getState }
  ) => {
    const state = getState() as AdminRootState;
    const token = selectToken(state)!;
    const orgUnitId = selectCurrentOrgUnit(state);
    return api.getAssets({ token, orgUnitId, page, size, contentType });
  }
);

export const searchItemsList = createAsyncThunk(
  "assets/searchList",
  (
    {
      page,
      size,
      searchQuery,
      contentType,
    }: {
      page: number;
      size: number;
      searchQuery: string;
      contentType?: string;
    },
    { getState }
  ) => {
    const state = getState() as AdminRootState;
    const token = selectToken(state)!;
    const orgUnitId = selectCurrentOrgUnit(state);
    return api.searchAssets({
      token,
      orgUnitId,
      page,
      size,
      searchQuery,
      contentType,
    });
  }
);

export const attachPage = createAsyncThunk(
  "assets/attachPage",
  (
    {
      size,
      contentType,
      searchQuery,
    }: { size: number; contentType?: string; searchQuery?: string },
    { getState }
  ) => {
    const state = getState() as AdminRootState;
    const token = selectToken(state)!;
    const orgUnitId = selectCurrentOrgUnit(state);

    const itemsList = selectItemsList(state);
    const pageCount = selectPageCount(state);
    const page = Math.ceil(itemsList.length / size);
    if (page < pageCount) {
      const params = {
        token,
        orgUnitId,
        page,
        size,
        contentType,
      };

      if (searchQuery) {
        return api.searchAssets({ ...params, searchQuery });
      } else {
        return api.getAssets(params);
      }
    }
    return { data: [], pageCount };
  }
);

export const addTagToItem = createAsyncThunk(
  "assets/addTag",
  (
    { items, tag }: { items: ResourceType[]; tag: Tags.TagSimple },
    { getState }
  ) => {
    const token = selectToken(getState() as AdminRootState)!;

    return Promise.all(
      items.map((item) =>
        api.updateAsset({
          token,
          item: produce(item, (d) => {
            if (!d.tags) {
              d.tags = [];
            }
            d.tags.push(tag);
          }),
        })
      )
    );
  }
);

export const updateItem = createAsyncThunk(
  "assets/update",
  (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateAsset({
      token,
      item,
    });
  }
);

export const createItem = createAsyncThunk(
  "assets/createAsset",
  ({ file, orgUnitId }: { file: File; orgUnitId: number }, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    formData.append("orgUnitId", `${orgUnitId}`);
    formData.append("contentType", file.type);
    return api.postAsset({ formData, token });
  }
);

export const deleteItem = createAsyncThunk(
  "assets/delete",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.deleteAsset({ token, id });
  }
);

export const getItemChildren = createAsyncThunk(
  "assets/getChildren",
  async (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const children = await api.getAssetChildren({ token, id });
    return {
      id,
      children,
    };
  }
);

const slice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(getItemsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItemsList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getItemsList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(searchItemsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchItemsList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(searchItemsList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(attachPage.pending, (state) => {
        state.isLoadingAttachPage = true;
      })
      .addCase(attachPage.fulfilled, (state, { payload }) => {
        payload.data.forEach((item) => {
          state.data.push(item);
        });
        state.isLoadingAttachPage = false;
        state.pageCount = payload.pageCount;
      })
      .addCase(attachPage.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoadingAttachPage = false;
      })
      .addCase(updateItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const itemIdx = state.data.findIndex((item) => item.id === payload.id);
        if (itemIdx >= 0) {
          state.data[itemIdx] = payload;
        }
      })
      .addCase(updateItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(({ id }) => id !== payload);
      })
      .addCase(deleteItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(getItemChildren.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItemChildren.fulfilled, (state, { payload }) => {
        const idx = state.data.findIndex(({ id }) => id === payload.id);
        state.data[idx].children = payload.children;
        state.isLoading = false;
      })
      .addCase(getItemChildren.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(createItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(addTagToItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTagToItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload.forEach((p) => {
          const itemIdx = state.data.findIndex((item) => item.id === p.id);
          if (itemIdx >= 0) {
            state.data[itemIdx] = p;
          }
        });
      })
      .addCase(addTagToItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      });
  },
});

export default slice.reducer;

export const { clearError } = slice.actions;

const sliceSelector = (state: AdminRootState) => state.assets;

export const selectItemsList = createSelector(
  sliceSelector,
  (state) => state.data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);
export const selectError = createSelector(
  sliceSelector,
  (state) => state.error
);

export const selectIsLoadingAttachPage = createSelector(
  sliceSelector,
  (state) => state.isLoadingAttachPage
);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);
