import classNames from "classnames";
import {
  SearchBar,
  CreationLink,
  EditorPage,
  LoadingIndicator,
} from "components";
import { useModalClosePath } from "hooks";
import { useMemo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  ResourceRequiredFieldsType,
  OnSubmitType,
  OnSearchType,
  IsItemDisabledType,
  OnLoadMoreType,
  ItemComponentType,
  LabelsType,
} from "./types";

type PropTypes<RT> = {
  items: RT[];
  allowNew?: boolean;
  multiple?: boolean;
  onSubmit: OnSubmitType<RT>;
  onSearch: OnSearchType;
  isItemDisabled?: IsItemDisabledType<RT>;
  isLoading: boolean;
  error?: string;
  labels: LabelsType;
  Item: ItemComponentType<RT>;
  onLoadMore?: OnLoadMoreType;
  showLoadMoreButton?: boolean;
  isLoadMoreLoading?: boolean;
  closeIcon?: boolean;
  backLink?: boolean;
};

const Select = <RT extends ResourceRequiredFieldsType>({
  items,
  allowNew,
  multiple,
  onSubmit,
  onSearch,
  isItemDisabled,
  isLoading,
  error,
  labels,
  Item,
  onLoadMore,
  showLoadMoreButton,
  isLoadMoreLoading,
  closeIcon = false,
  backLink = true,
}: PropTypes<RT>) => {
  const history = useHistory();

  const [selectedItems, setSelectedItems] = useState<RT[]>([]);

  const toggleItemSelected = useCallback(
    (item: RT) => {
      setSelectedItems((currentSelectedItems) => {
        if (multiple) {
          if (!currentSelectedItems.find(({ id }) => id === item.id)) {
            return [...currentSelectedItems, item];
          } else {
            return currentSelectedItems.filter(({ id }) => id !== item.id);
          }
        } else {
          if (item.id === currentSelectedItems[0]?.id) {
            return [];
          } else {
            return [item];
          }
        }
      });
    },
    [multiple]
  );

  const selectedIds = useMemo(() => {
    return selectedItems.map(({ id }) => id);
  }, [selectedItems]);

  const backPath = useModalClosePath();

  const onSubmitHandler = useCallback(() => {
    onSubmit(selectedItems, () => {
      history.push(backPath);
    });
  }, [onSubmit, selectedItems, history, backPath]);

  const onLoadMoreHandler = () => {
    onLoadMore && onLoadMore();
  };

  return (
    <EditorPage
      backLink={backLink}
      closeIcon={closeIcon}
      title={`Add ${labels.singular}`}
      error={error}
      controls={{
        submitText:
          selectedItems.length > 1
            ? `Add ${labels.plural}`
            : `Add ${labels.singular}`,
        isValid: selectedItems.length !== 0,
        onSubmit: onSubmitHandler,
      }}
    >
      <>
        <div className="search-bar">
          <SearchBar onChange={onSearch} />
          {allowNew && <CreationLink />}
        </div>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ul className="resource-items-wrapper gallery">
              {items.length ? (
                <>
                  {items.map((item) => {
                    const selected = selectedIds.includes(item.id);

                    const disabled = isItemDisabled
                      ? isItemDisabled(item)
                      : false;

                    return (
                      <li
                        key={item.id}
                        className={classNames({ selected, disabled })}
                      >
                        <input
                          type="checkbox"
                          checked={selected}
                          onChange={() => !disabled && toggleItemSelected(item)}
                        />
                        <span
                          className="asset-block"
                          onClick={() => !disabled && toggleItemSelected(item)}
                        >
                          <Item data={item} />
                        </span>
                      </li>
                    );
                  })}

                  {showLoadMoreButton && (
                    <li className="load-more-item">
                      <span
                        className="load-btn"
                        onClick={() =>
                          !isLoadMoreLoading && onLoadMoreHandler()
                        }
                      >
                        {isLoadMoreLoading ? "Loading..." : "Load More..."}
                      </span>
                    </li>
                  )}
                </>
              ) : (
                <li>Nothing found</li>
              )}
            </ul>
          </>
        )}
      </>
    </EditorPage>
  );
};

export default Select;
