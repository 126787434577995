import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { UserTypes } from "common/types";
import { setCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";
import { selectToken, setUserData } from "pages/admin/login/slice";

type UsersState = SliceBase<UserTypes.UserComplete>;

const initialState: UsersState = {
  data: [],
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getUserList = createAsyncThunk(
  "users/getList",
  async (
    { query, page, size }: { query: string; page: number; size: number },
    thunkApi
  ) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    if (query.length >= 3) return api.searchUsers({ token, query, page, size });
    return { data: [], pageCount: 0 };
  }
);

export const createUser = createAsyncThunk(
  "users/create",
  async (user: UserTypes.UserRequest, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createUser({ token, user });
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getUserList.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getUserList.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.pageCount = payload.pageCount;
        state.isLoading = false;
      })
      .addCase(getUserList.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload && state.data.push(payload);
      })
      .addCase(createUser.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(setCurrentOrgUnit, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { clearError } = userSlice.actions;

const sliceSelector = (state: AdminRootState) => state.users;

export const selectUserList = createSelector(sliceSelector, ({ data }) => data);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export default userSlice.reducer;
