import Field from "./Field";
import Connector from "./Connector";
import Editor from "./Editor";
import Item from "./Item";
import * as slice from "./slice";
export * as Types from "./types";

const RemoteChannelsField = {
  Field,
  Connector,
  Editor,
  Item,
  slice,
};

export default RemoteChannelsField;
