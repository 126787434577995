import cn from "classnames";

import AddNewButton from "./AddNewButton";
import ItemsList from "./ItemsList";
import {
  ItemComponentType,
  ResourceRequiredFieldsType,
  OnDeleteType,
} from "./types";

type PropTypes<RT> = {
  className?: string;
  path: string;
  label: string;
  value?: RT[];
  onDelete?: OnDeleteType;
  Item: ItemComponentType<RT>;
};

const Field = <RT extends ResourceRequiredFieldsType>({
  className,
  path,
  label,
  value,
  onDelete,
  Item,
}: PropTypes<RT>) => {
  return (
    <div className={cn("resource-field resource-field-editable", className)}>
      <ItemsList
        path={path}
        value={value}
        Item={Item}
        editable
        onDelete={onDelete}
      />
      <AddNewButton path={path} label={label} />
    </div>
  );
};

export default Field;
