import { useEffect, useState } from "react";
import { Channel } from "common/types";
import moment from "moment";

export const useMedia = (maxWidth: number) => {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    function listener(arg: MediaQueryListEvent) {
      setMatches(arg.matches);
    }
    const mql = window.matchMedia(`(max-width: ${maxWidth}px)`);
    mql.addEventListener("change", listener);
    listener(mql as any as MediaQueryListEvent);
    return () => mql.removeEventListener("change", listener);
  }, [maxWidth]);
  return matches;
};

export const useCurrentProgram = (channel: Channel.Channel) => {
  const [currentProgram, setCurrentProgram] = useState<Channel.Program>();

  const [nextProgram, setNextProrgam] = useState<Channel.Program>();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    function updateProgram() {
      let program, next;

      if (channel.listings) {
        const t = moment().utc();

        const programIdx = channel.listings.findIndex((l) => {
          const start = moment.utc(l.startTime);

          const end = moment.utc(l.endTime);

          return t.isBetween(start, end, undefined, "[)");
        });

        if (programIdx !== -1) {
          program = channel.listings[programIdx];

          next = channel.listings[programIdx + 1];

          timeout = setTimeout(
            updateProgram,
            moment.utc(program.endTime).diff(t)
          );
        }
      }

      setCurrentProgram(program);

      setNextProrgam(next);
    }

    updateProgram();

    return () => {
      clearTimeout(timeout);
    };
  }, [channel]);

  return [currentProgram, nextProgram];
};
