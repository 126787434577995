const CONSTANTS = {
  labels: {
    singular: "Remote Agent",
    plural: "Remote Agents",
  },
  path: "agent",
  className: "remote-agents-field",
};

export default CONSTANTS;
