import { useHistory } from "react-router-dom";

import { RemoteTranscodeVideos } from "common/types";
import { EditorPage, FormGroup } from "components";
import { useCancellableSubmit, useModalClosePath } from "hooks";
import {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  useMemo,
} from "react";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import {
  getItem,
  createItem,
  selectError,
  selectIsLoading,
  selectItem,
  updateItem,
} from "./slice";
import { OnSubmitType } from "./types";

type PropTypes = {
  id: string;
  transcodeProfileId: number;
  onSubmit: OnSubmitType;
};

const TranscodeVideosEditor = ({
  id,
  transcodeProfileId,
  onSubmit,
}: PropTypes) => {
  const history = useHistory();

  const dispatch = useAdminDispatch();

  const [data, setData] = useState<
    RemoteTranscodeVideos.RemoteTranscodeVideo | undefined
  >();

  const item = useAdminSelector(selectItem);

  useEffect(() => {
    if (id !== "new") {
      dispatch(getItem(parseInt(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id === "new") {
      setData({
        id: -1,
        name: "",
        width: 0,
        height: 0,
        preset: "",
        codec: "",
        extraArguments: "",
        transcodeProfileId,
      });
    } else {
      setData(item);
    }
  }, [id, dispatch, item, transcodeProfileId]);

  const isLoading = useAdminSelector(selectIsLoading);

  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, name: value });
    },
    []
  );

  const onWidthChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, width: parseInt(value) });
    },
    []
  );

  const onHeightChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, height: parseInt(value) });
    },
    []
  );

  const onPresetChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, preset: value });
    },
    []
  );

  const onCodecChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setData((item) => item && { ...item, codec: value });
    },
    []
  );

  const onExtraArgumentsChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target: { value } }) => {
      setData((item) => item && { ...item, extraArguments: value });
    }, []);

  const invalid = useMemo(
    (): {
      [k in keyof RemoteTranscodeVideos.RemoteTranscodeVideo]?: boolean;
    } => ({
      name: !data?.name,
      width: data?.width !== 0 && !data?.width,
      height: data?.height !== 0 && !data?.height,
      preset: !data?.codec,
      codec: !data?.codec,
    }),
    [data]
  );

  const isValid = useMemo(
    () => Object.values(invalid).every((x) => !x),
    [invalid]
  );

  const backPath = useModalClosePath(2);

  const onSubmitHandler = useCancellableSubmit({
    item: data,
    submittingAction: id === "new" ? createItem : updateItem,
    onReady: (item) => {
      onSubmit(item, () => {
        history.push(backPath);
      });
    },
    closePartsToSkip: 2,
  });

  const error = useAdminSelector(selectError);

  return (
    <EditorPage
      backLink
      closePartsToSkip={2}
      title={`${id === "new" ? "Create" : "Edit"} Transcode Video`}
      error={error}
      isLoading={isLoading}
      controls={{
        submitText: id === "new" ? "Create" : "Save",
        isValid,
        onSubmit: onSubmitHandler,
      }}
    >
      {data && (
        <>
          <FormGroup label="Name" invalid={invalid.name}>
            <input
              className="form-control"
              type="text"
              value={data.name}
              onChange={onNameChange}
            />
          </FormGroup>
          <div className="form-row half">
            <FormGroup label="Width" invalid={invalid.width}>
              <input
                className="form-control"
                type="number"
                value={data.width}
                onChange={onWidthChange}
              />
            </FormGroup>
            <FormGroup label="Heigth" invalid={invalid.height}>
              <input
                type="number"
                value={data.height}
                onChange={onHeightChange}
              />
            </FormGroup>
          </div>
          <div className="form-row half">
            <FormGroup label="Preset" invalid={invalid.preset}>
              <input
                className="form-control"
                type="text"
                value={data.preset}
                onChange={onPresetChange}
              />
            </FormGroup>
            <FormGroup label="Codec" invalid={invalid.codec}>
              <input type="text" value={data.codec} onChange={onCodecChange} />
            </FormGroup>
          </div>
          <FormGroup label="Extra Arguments" invalid={invalid.extraArguments}>
            <input
              type="text"
              value={data.extraArguments}
              onChange={onExtraArgumentsChange}
            />
          </FormGroup>
        </>
      )}
    </EditorPage>
  );
};

export default TranscodeVideosEditor;
