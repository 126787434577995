import { FC } from "react";

import "./editor-title.scss";

const EditorFormTitle: FC = ({ children, ...rest }) => {
  return (
    <h4 className="modal-title" {...rest}>
      {children}
    </h4>
  );
};

export default EditorFormTitle;
