import { useCallback } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import cn from "classnames";

import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";
import { deleteItem, selectIsLoading, selectError } from "./slice";
import { ResourceType } from "./types";
import { ErrorMessage, ResourceField, ResourceFieldTypes } from "components";
import Item from "./Item";
import CONSTANTS from "./constants";

type PropTypes = {
  className?: string;
  path?: string;
  label?: string;
  value?: ResourceType[];
  onDelete?: ResourceFieldTypes.OnDeleteType;
};

const Field = ({
  className,
  path = CONSTANTS.path,
  label = CONSTANTS.labels.singular,
  value,
  onDelete,
}: PropTypes) => {
  const dispatch = useAdminDispatch();

  const isLoading = useAdminSelector(selectIsLoading);

  const error = useAdminSelector(selectError);

  const onDeleteHandler = useCallback(
    (id) => {
      if (window.confirm("Are you sure?")) {
        const promise = dispatch(deleteItem(id));

        promise.then(unwrapResult).then(() => {
          onDelete && onDelete(id);
        });
      }
    },
    [dispatch, onDelete]
  );

  return (
    <>
      <ResourceField.EditableField
        className={cn(CONSTANTS.className, className, {
          "disabled-container": isLoading,
        })}
        path={path}
        label={label}
        value={value}
        onDelete={onDeleteHandler}
        Item={Item}
      />
      {error && <ErrorMessage error={error} />}
    </>
  );
};

export default Field;
