import cn from "classnames";

import { ResourceField, ResourceFieldTypes } from "components";
import CONSTANTS from "./constants";
import { IsItemDisabledType, OnSubmitType } from "./types";
import Select from "./Select";

type PropTypes = {
  className?: string;
  path?: string;
  labels?: ResourceFieldTypes.LabelsType;
  onToggle: ResourceFieldTypes.OnToggleType;
  onSubmit: OnSubmitType;
  isItemDisabled?: IsItemDisabledType;
};

const Connector = ({
  className,
  path = CONSTANTS.path,
  labels,
  onToggle,
  onSubmit,
  isItemDisabled,
}: PropTypes) => {
  return (
    <ResourceField.Connector
      className={cn(CONSTANTS.className, className)}
      path={path}
      onToggle={onToggle}
    >
      {() => {
        return (
          <Select
            onSubmit={onSubmit}
            isItemDisabled={isItemDisabled}
            labels={labels}
            allowNew={false}
            multiple={false}
          />
        );
      }}
    </ResourceField.Connector>
  );
};

export default Connector;
