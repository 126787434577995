import { FC, useCallback, useState } from "react";
import cn from "classnames";

import "./form-group.scss";

const FormGroup: FC<{ label?: string; invalid?: boolean }> = ({
  children,
  label,
  invalid,
}) => {
  const [hasBeenVisited, setHasBeenVisited] = useState(false);
  const onBlur = useCallback(() => setHasBeenVisited(true), []);
  return (
    <div
      className={cn("form-group", { invalid: invalid && hasBeenVisited })}
      onBlur={onBlur}
    >
      {label && <label>{label}</label>}
      {children}
    </div>
  );
};

export default FormGroup;
