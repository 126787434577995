import cn from "classnames";

import { ResourceField, ResourceFieldTypes } from "components";
import CONSTANTS from "./constants";
import { OnSubmitType } from "./types";
import Editor from "./Editor";

type PropTypes = {
  className?: string;
  path?: string;
  transcodeProfileId: number;
  onToggle: ResourceFieldTypes.OnToggleType;
  onSubmit: OnSubmitType;
};

const Connector = ({
  className,
  path = CONSTANTS.path,
  transcodeProfileId,
  onToggle,
  onSubmit,
}: PropTypes) => {
  return (
    <ResourceField.Connector
      className={cn(CONSTANTS.className, className)}
      path={path}
      onToggle={onToggle}
    >
      {(id) => {
        return (
          <Editor
            id={id}
            transcodeProfileId={transcodeProfileId}
            onSubmit={onSubmit}
          />
        );
      }}
    </ResourceField.Connector>
  );
};

export default Connector;
