import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ForgotPasswordSent from "./ForgotPasswordSent";

const AccountModals = {
  LoginModal,
  RegisterModal,
  ForgotPasswordModal,
  ForgotPasswordSent,
};

export default AccountModals;
