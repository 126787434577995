import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { Tags } from "common/types";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";
import { selectToken, setUserData } from "pages/admin/login/slice";

type TagsState = SliceBase<Tags.Tag>;

const initialState: TagsState = {
  data: [],
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getTagsList = createAsyncThunk(
  "tags/getList",
  async ({ page, size }: { page: number; size: number }, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    );
    return api.getTags({ token, orgUnitId, page, size });
  }
);

export const attachPage = createAsyncThunk(
  "tags/attachPage",
  async ({ size }: { size: number }, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(getState() as AdminRootState);

    const tagsList = selectTagsList(getState() as AdminRootState);
    const pageCount = selectPageCount(getState() as AdminRootState);
    const page = Math.ceil(tagsList.length / size);
    if (page < pageCount) {
      return api.getTags({ token, orgUnitId, page, size });
    }
    return { data: [], pageCount };
  }
);

export const deleteTag = createAsyncThunk(
  "tags/delete",
  async (id: number, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    return api.deleteTag({ token, id });
  }
);

export const createTag = createAsyncThunk(
  "tags/create",
  async (tag: Tags.Tag, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createTag({ token, tag });
  }
);

export const updateTag = createAsyncThunk(
  "tags/update",
  async (tag: Tags.Tag, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateTag({ token, tag });
  }
);

const tagsSlice = createSlice({
  name: "tagsReducer",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getTagsList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTagsList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getTagsList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(attachPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(attachPage.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload.data.forEach((item) => {
          state.data.push(item);
        });
        state.pageCount = payload.pageCount;
      })
      .addCase(attachPage.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(deleteTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTag.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(({ id }) => id !== payload);
      })
      .addCase(deleteTag.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(updateTag.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateTag.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const idx = state.data.findIndex(({ id }) => id === payload.id);
        if (idx >= 0) state.data[idx] = payload;
      })
      .addCase(updateTag.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(createTag.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(createTag.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createTag.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { clearError } = tagsSlice.actions;

const sliceSelector = (state: AdminRootState) => state.tagsReducer;

export const selectTagsList = createSelector(sliceSelector, ({ data }) => data);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);

export default tagsSlice.reducer;
