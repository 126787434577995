import { ErrorMessage, LoadingIndicator } from "components";
import { memo, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  fetchStations,
  selectChannels,
  watchVideo,
  selectIsLoading,
  selectError,
} from "../HomePage/slice";

import "../CategoryPage/category-page.scss";
import Station from "../HomePage/Station";
import { Channel } from "common/types";

const LivePage = memo(() => {
  const channels = useClientSelector(selectChannels);
  const isLoading = useClientSelector(selectIsLoading);
  const error = useClientSelector(selectError);
  const dispatch = useClientDispatch();
  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (channels.length === 0) dispatch(fetchStations());
  }, [dispatch, channels]);

  const history = useHistory();
  const onItemClick = useCallback(
    (item: Channel.Channel) => {
      dispatch(watchVideo(item));
      window.scrollTo({ top: 0 });
      history.push("/");
    },
    [dispatch, history]
  );

  return (
    <div className="category-page">
      {error && <ErrorMessage error={error} />}
      <div className="content-wrapper">
        <h1>Live</h1>
        <div className="video-list">
          {channels.map((item) => (
            <Station key={item.id} channel={item} onClick={onItemClick} />
          ))}
        </div>
      </div>
      {isLoading && (
        <div className="center">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
});

export default LivePage;
