import { useCallback, useEffect, useState, useMemo } from "react";

import { ResourceFieldTypes } from "components";
import { useAdminDispatch, useAdminSelector } from "admin-store/hooks";

import {
  getItemsList,
  selectItemsList,
  selectError,
  selectIsLoadingAttachPage,
  selectIsLoading,
  attachPage,
  searchItemsList,
  selectPageCount,
} from "./slice";

export function useItems({
  itemsPerPage,
  currentPage = 0,
  contentType,
}: {
  itemsPerPage: number;
  currentPage?: number;
  contentType?: string;
}) {
  const dispatch = useAdminDispatch();

  const itemsList = useAdminSelector(selectItemsList);

  const isLoading = useAdminSelector(selectIsLoading);

  const error = useAdminSelector(selectError);

  const isLoadMoreLoading = useAdminSelector(selectIsLoadingAttachPage);

  const pageCount = useAdminSelector(selectPageCount);

  const [searchQuery, setSearchQuery] = useState("");

  const onSearch = useCallback<ResourceFieldTypes.OnSearchType>(
    (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
    },
    []
  );

  const onLoadMore = useCallback(() => {
    dispatch(
      attachPage({
        size: itemsPerPage,
        searchQuery,
        contentType,
      })
    );
  }, [searchQuery, itemsPerPage, dispatch, contentType]);

  const showLoadMoreButton = useMemo(() => {
    const page = Math.ceil(itemsList.length / itemsPerPage);

    return page < pageCount;
  }, [pageCount, itemsList.length, itemsPerPage]);

  useEffect(() => {
    const params = {
      page: currentPage,
      size: itemsPerPage,
      contentType,
    };

    if (searchQuery) {
      dispatch(searchItemsList({ ...params, searchQuery }));
    } else {
      dispatch(getItemsList(params));
    }
  }, [searchQuery, itemsPerPage, currentPage, contentType, dispatch]);

  return {
    itemsList,
    error,
    isLoading,
    onSearch,
    onLoadMore,
    showLoadMoreButton,
    isLoadMoreLoading,
    pageCount,
  };
}
