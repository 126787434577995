import { Link, useRouteMatch } from "react-router-dom";

type PropTypes = {
  path: string;
  label: string;
  valueLength: number;
};

const ChangeButton = ({ path, label, valueLength }: PropTypes) => {
  const { url } = useRouteMatch();

  return (
    <Link className="add-btn" to={`${url}/${path}`}>
      {`${valueLength ? "Change" : "Add"} ${label}`}
    </Link>
  );
};

export default ChangeButton;
