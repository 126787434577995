import { LoadingIndicator } from "components";
import cn from "classnames";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
  memo,
  FC,
  useMemo,
} from "react";
import { MouseEventHandler } from "react-select";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  login,
  cancelInitiative,
  initiateRegistration,
  selectIsLoggingIn,
  initiateForgotPassword,
  selectError,
} from "./loginSlice";

import "./login.scss";
import { useHistory } from "react-router";
import isValidEmail from "./emailTest";

const LoginModal: FC = memo(() => {
  const storeError = useClientSelector(selectError);
  const isLoggingIn = useClientSelector(selectIsLoggingIn);
  const [username, setUsername] = useState("");
  const [showError, setShowError] = useState(0b00);
  const onUsernameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError((x) => x | 0b10);
      setUsername(value);
    },
    []
  );

  const [password, setPassword] = useState("");
  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setShowError((x) => x | 0b01);
      setPassword(value);
    },
    []
  );

  const dispatch = useClientDispatch();
  const onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    setShowError(0b11);
    if (username && password)
      dispatch(
        login({
          username,
          password,
        })
      );
  };

  const {
    location: { pathname },
    push,
  } = useHistory();
  const closeModal = useCallback(() => {
    if (pathname === "/activate") {
      push("/");
    }
    dispatch(cancelInitiative());
  }, [dispatch, pathname, push]);

  const register: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch(initiateRegistration());
    },
    [dispatch]
  );

  const forgotPassword: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch(initiateForgotPassword());
    },
    [dispatch]
  );

  const error: string = useMemo(() => {
    if (!isValidEmail(username)) {
      return "Invalid Email";
    }
    if (password === "") {
      return "Empty password";
    }
    return "";
  }, [username, password]);

  return (
    <div className="login-block">
      {isLoggingIn ? (
        <LoadingIndicator />
      ) : (
        <>
          <button className="close-btn" onClick={closeModal}></button>
          <h3>Account Login</h3>
          {((showError === 0b1111 && error !== "") || storeError) && (
            <p className="error-message">
              <span className="error-icon">&#10006;</span> {error || storeError}
            </p>
          )}
          <form className="form" onSubmit={onSubmit}>
            <input
              type="text"
              value={username}
              onChange={onUsernameChange}
              placeholder="Email"
            />
            <input
              type="password"
              value={password}
              onChange={onPasswordChange}
              placeholder="Password"
            />
            <input
              className={cn("btn btn-primary", {
                active: error,
              })}
              type="submit"
              value="Login"
            />
          </form>

          <a className="link" href="/" onClick={forgotPassword}>
            Forgot password?
          </a>

          <div className="login-footer">
            <p>Don't have WFMZ account?</p>
            <a className="link" href="/" onClick={register}>
              Register now
            </a>
          </div>
        </>
      )}
    </div>
  );
});

export default LoginModal;
