import "./assets/styles/main.scss";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import WebClient from "WebClient";
import { Suspense, lazy } from "react";
import Theme from "./theme/Theme";

const AdminApp = lazy(() => import("./AdminApp"));

function App() {
  return (
    <Theme>
      <Router>
        <Switch>
          <Route path="/admin">
            <Suspense fallback={null}>
              <AdminApp />
            </Suspense>
          </Route>
          <Route path="/">
            <WebClient />
          </Route>
        </Switch>
      </Router>
    </Theme>
  );
}

export default App;
