import {
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ResourceType } from "./types";
import { selectToken } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";

type PageState = SliceBase<ResourceType>;

const initialState: PageState & { item: ResourceType | undefined } = {
  data: [],
  item: undefined,
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getItemsList = createAsyncThunk(
  "remoteAgents/getList",
  async (_, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    );
    return api.getRemoteAgents({ token, orgUnitId });
  }
);

export const getItem = createAsyncThunk(
  "remoteAgents/get",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.getRemoteAgentItem({ token, id });
  }
);

export const createItem = createAsyncThunk(
  "remoteAgents/create",
  (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createRemoteAgent({ token, item });
  }
);

export const updateItem = createAsyncThunk(
  "remoteAgents/update",
  async (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateRemoteAgent({ token, item });
  }
);

export const deleteItem = createAsyncThunk(
  "remoteAgents/delete",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.deleteRemoteAgent({ token, id });
  }
);

const slice = createSlice({
  name: "remoteAgentsReducer",
  initialState,
  reducers: {
    updateData(state, { payload }: PayloadAction<ResourceType[]>) {
      state.data = payload;
    },
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getItemsList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getItemsList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getItemsList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(getItem.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.item = payload;
      })
      .addCase(getItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(createItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(updateItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const idx = state.data.findIndex((src) => src.id === payload.id);
        if (idx >= 0) state.data[idx] = payload;
      })
      .addCase(updateItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter((source) => source.id !== payload);
      })
      .addCase(deleteItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      });
  },
});

export const { updateData, clearError } = slice.actions;

const sliceSelector = (state: AdminRootState) => state.remoteAgents;

export const selectItemsList = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectItem = createSelector(sliceSelector, ({ item }) => item);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export default slice.reducer;
