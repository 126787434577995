import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
  useEffect,
  memo,
} from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import * as api from "common/api";
import { LoadingIndicator } from "components";
import { MouseEventHandler } from "react-select";
import { useClientDispatch } from "webclient-store/hooks";
import { cancelInitiative, initiateLogin } from "../AccountModals/loginSlice";

enum ResetStages {
  IDLE,
  LOADING,
  DONE,
}

const ResetPassword = memo(() => {
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const email = query.get("email");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const onPassword1Change: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setPassword1(value);
    },
    []
  );

  const onPassword2Change: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setPassword2(value);
    },
    []
  );

  const [error, setError] = useState("");
  useEffect(() => {
    if (password1 !== "" && password2 !== "" && password1 !== password2) {
      setError("Passwords don't match");
    } else {
      setError("");
    }
  }, [password1, password2]);

  const [resetStage, setResetStage] = useState<ResetStages>(ResetStages.IDLE);

  const onSubmit: FormEventHandler<HTMLFormElement> = async (evt) => {
    evt.preventDefault();
    if (password1 !== "" && password1 === password2 && code) {
      try {
        setResetStage(ResetStages.LOADING);
        await api.changePassword({ code, password: password1 });
        setResetStage(ResetStages.DONE);
      } catch {
        setError("Unable to update your password");
        setResetStage(ResetStages.IDLE);
      }
    }
  };

  const dispatch = useClientDispatch();
  const history = useHistory();
  const goToLogin: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      history.push("/");
      dispatch(initiateLogin());
    },
    [dispatch, history]
  );

  const goToHomePage = useCallback(() => {
    history.push("/");
    dispatch(cancelInitiative());
  }, [dispatch, history]);

  if (!(code && email)) return <Redirect to={"/"} />;

  return (
    <div className="login-modal">
      <div className="login-wrapper">
        <div className="login-block">
          {resetStage === ResetStages.LOADING ? (
            <LoadingIndicator />
          ) : resetStage === ResetStages.IDLE ? (
            <>
              <button className="close-btn" onClick={goToHomePage}></button>
              <h3>Enter new password for {email}</h3>
              {error !== "" && (
                <p className="error-message">
                  <span className="error-icon">&#10006;</span> {error}
                </p>
              )}
              <form className="form" onSubmit={onSubmit}>
                <input
                  type="password"
                  value={password1}
                  onChange={onPassword1Change}
                  placeholder="New Password"
                />
                <input
                  type="password"
                  value={password2}
                  onChange={onPassword2Change}
                  placeholder="Repeat Password"
                />
                <input
                  className={cn("btn btn-primary", {
                    active: password1 !== "" && password1 === password2,
                  })}
                  type="submit"
                  value="Submit"
                />
              </form>
            </>
          ) : (
            <form className="form">
              <h3>You can Login now</h3>
              <a href="/" onClick={goToLogin} className="btn btn-primary">
                Login
              </a>
            </form>
          )}
        </div>
      </div>
    </div>
  );
});

export default ResetPassword;
