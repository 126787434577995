import moment from "moment";
import { ItemComponentType } from "./types";

const Item: ItemComponentType = ({ data }) => {
  return (
    <div className="resource-table-wrapper remote-schedules-field-item">
      <div className="inline-wrapper">
        <span>Id</span>
        <p>{data.id}</p>
      </div>
      <div className="block-wrapper">
        <span>Start Time</span>
        <p>{data.startTime}</p>
      </div>
      <div className="block-wrapper">
        <span>End Time</span>
        <p>{data.endTime}</p>
      </div>
      <div className="block-wrapper">
        <span>Day of Week</span>
        <p>{moment(Number(data.dayOfWeek), "E").format("dd")}</p>
      </div>
      <div className="inline-wrapper">
        <span>Live</span>
        <p>{data.live ? "+" : ""}</p>
      </div>
      <div className="block-wrapper">
        <span>Recording Offset</span>
        <p>{data.recordingOffset}</p>
      </div>
      <div className="block-wrapper">
        <span>Source Channel Id</span>
        <p>{data.sourceChannel?.id}</p>
      </div>
    </div>
  );
};

export default Item;
