import ChangeButton from "./ChangeButton";
import FieldWrapper from "./FieldWrapper";
import ItemsList from "./ItemsList";
import { ItemComponentType, ResourceRequiredFieldsType } from "./types";

type PropTypes<RT> = {
  className?: string;
  path: string;
  label: string;
  value?: RT[];
  Item: ItemComponentType<RT>;
};

const Field = <RT extends ResourceRequiredFieldsType>({
  className,
  path,
  label,
  value,
  Item,
}: PropTypes<RT>) => {
  return (
    <FieldWrapper className={className}>
      <ItemsList path={path} value={value} Item={Item} />
      <ChangeButton
        path={path}
        label={label}
        valueLength={value ? value.length : 0}
      />
    </FieldWrapper>
  );
};

export default Field;
