import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { selectToken } from "pages/admin/login/slice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";
import { produce } from "immer";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import { ResourceType } from "./types";

type PageState = SliceBase<ResourceType>;

const initialState: PageState = {
  data: [],
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getItemsList = createAsyncThunk(
  "remoteTranscodeProfiles/getList",
  async (_, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    );
    return api.getRemoteTranscodeProfiles({ token, orgUnitId });
  }
);

export const toggleActive = createAsyncThunk(
  "remoteTranscodeProfiles/toggleActive",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    const item = selectItemsList(getState() as AdminRootState).find(
      (s) => s.id === id
    )!;

    return api.updateRemoteTranscodeProfile({
      token,
      item: produce(item, (draft) => {
        draft.isActive = !draft.isActive;
      }),
    });
  }
);

export const createItem = createAsyncThunk(
  "remoteTranscodeProfiles/create",
  (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createRemoteTranscodeProfile({ token, item });
  }
);

export const updateItem = createAsyncThunk(
  "remoteTranscodeProfiles/update",
  async (item: ResourceType, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateRemoteTranscodeProfile({ token, item });
  }
);

export const deleteItem = createAsyncThunk(
  "remoteTranscodeProfiles/delete",
  (id: number, { getState }) => {
    const token = selectToken(getState() as AdminRootState)!;
    return api.deleteRemoteTranscodeProfile({ token, id });
  }
);

const slice = createSlice({
  name: "remoteTranscodeProfilesReducer",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getItemsList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getItemsList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getItemsList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(createItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(updateItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const idx = state.data.findIndex((src) => src.id === payload.id);
        if (idx >= 0) state.data[idx] = payload;
      })
      .addCase(updateItem.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(toggleActive.fulfilled, (state, { payload }) => {
        const itemIdx = state.data.findIndex((item) => item.id === payload.id);
        if (itemIdx >= 0) {
          state.data[itemIdx] = payload;
        }
      })
      .addCase(toggleActive.rejected, (state, { error }) => {
        state.error = error.message;
      })
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter((source) => source.id !== payload);
      })
      .addCase(deleteItem.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      });
  },
});

export const { clearError } = slice.actions;

const sliceSelector = (state: AdminRootState) => state.remoteTranscodeProfiles;

export const selectItemsList = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export default slice.reducer;
