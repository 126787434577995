import { FC } from "react";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./carousel.scss";

const responsiveConfig = {
  desktopXL: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 3,
    partialVisibilityGutter: 17,
  },
  desktopL: {
    breakpoint: { max: 3000, min: 1500 },
    items: 5,
    partialVisibilityGutter: 14,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1200 },
    items: 5,
    partialVisibilityGutter: 10,
  },
  desktopS: {
    breakpoint: { max: 1200, min: 1024 },
    items: 5,
    partialVisibilityGutter: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 900 },
    items: 4,
    partialVisibilityGutter: 8,
  },
  tabletS: {
    breakpoint: { max: 900, min: 700 },
    items: 4,
    partialVisibilityGutter: 7,
  },
  mobileL: {
    breakpoint: { max: 700, min: 480 },
    items: 3,
    partialVisibilityGutter: 6,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 2,
    partialVisibilityGutter: 4,
  },
};

const Carousel: FC<{ slidesToShow?: number }> = ({ children }) => {
  return (
    <Slider
      infinite
      responsive={responsiveConfig}
      transitionDuration={500}
      swipeable={false}
      draggable={false}
      partialVisible={true}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
